import React, { useEffect } from "react";
// import { ButtonCreate } from "../ButtonCreate";
import { getAllCategories } from "../../redux/categories/categoriesActions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./styles.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { colors } from "@material-ui/core";

export const Navbar = ({ links, subLinks, isOpen, title }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const rootCategories = categories.filter((c) => !Boolean(c.parent));
  const hasSubCategories = (id) =>
    categories.some((c) => c.parent && c.parent === id);
  const getSubCategories = (id) =>
    categories.filter((c) => c.parent && c.parent === id);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <div className={`navbar ${isOpen ? "navbar--is-open" : ""}`}>
      {title && <p>{title}</p>}
      {rootCategories.map((category, index) => {
        return (
          <div className="navbar__item" key={index}>
            <Button
              style={{ color: green[50], fontSize: "16px", padding: "0 3px" }}
              fullWidth={true}
              onClick={() => {
                if (hasSubCategories(category._id)) return;

                history.push(`/category/${category._id}`);
              }}
            >
              {category.name}
              {hasSubCategories(category._id) && <NavigateNextIcon />}
            </Button>
            {hasSubCategories(category._id) && (
              <div className="simple-menu">
                <SimpleBar>
                  {getSubCategories(category._id).map((subLink, index) => {
                    return (
                      <div className="simple-menu__item" key={index}>
                        <NavLink to={`/category/${subLink._id}`}>
                          {subLink.name}
                        </NavLink>
                      </div>
                    );
                  })}
                </SimpleBar>
              </div>
            )}
          </div>
        );
      })}
      <div className="navbar-timeless-item">
        <NavLink to="/cd-details">Create CD</NavLink>
      </div>
      {/*<div className="navbar-timeless-item">*/}
      {/*  <NavLink to="/cassette-details">Create Cassette</NavLink>*/}
      {/*</div>{" "}*/}
      <div className="navbar-timeless-item">
        <NavLink to="/vinyl-details">Create Vinyl</NavLink>
      </div>
    </div>
  );
};
