import React, { useEffect } from "react";
import { getTemplatesByCategory } from "../../redux/templates/templatesActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import "./styles.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: 220,
    padding: 10,
    margin: 10,
    cursor: "pointer",
  },
  media: {
    height: 235,
    width: 220,
  },
});

export const CategoryPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { match } = props;

  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.commonReducer.isFetching);
  const templates = useSelector((state) => state.templatesReducer.templates);
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const getCategoryName = () => {
    const foundCategory = categories.find((c) => c._id === match.params.id);
    return foundCategory ? foundCategory.name : "";
  };
  const reverseTemplate = templates?.concat().reverse();

  useEffect(() => {
    dispatch(getTemplatesByCategory(match.params.id));
  }, [dispatch, match.params.id]);
  console.log(templates);

  return (
    <div className="category-page">
      <h2>{getCategoryName()}</h2>
      {isFetching ? (
        <CircularProgress color="secondary" />
      ) : templates?.length ? (
        <div className="products-list">
          {reverseTemplate?.map((template, index) => {
            console.log("template", template);
            return (
              <Card
                className={classes.root}
                key={index}
                onClick={() => history.push(`/template/${template._id}`)}
              >
                <CardMedia
                  className={classes.media}
                  image={template.images[0]?.path}
                />
                <CardContent>
                  <div className="card__title">{template.name}</div>
                  <div className="card__description">
                    {template.description}
                  </div>
                  <div className="card__price">
                    {template.manufacturing_cost}$
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>
      ) : (
        <div style={{ margin: "0 30px 0 30px" }}>
          <Alert severity="error">The list of the templates is empty!</Alert>
        </div>
      )}
    </div>
  );
};
