export const superAdminLinks = [
  {
    name: "Categories",
    to: "/sa-add-new-category",
  },
  {
    name: "All templates",
    to: "/sa-all-templates",
  },
  {
    name: "Products",
    to: "/sa-moderate-new-product",
  },
  // {
  //   name: "Manage Admin Users",
  //   to: "/sa-manage",
  // },
  {
    name: "Vendors",
    to: "/sa-vendors",
  },
];
