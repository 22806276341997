import {
  GET_ALL_TEMPLATES,
  ADD_NEW_TEMPLATE,
  DELETE_TEMPLATE_BY_ID,
  GET_TEMPLATE_BY_ID,
  IS_TEMPLATE_CREATED,
  IS_TEMPLATE_REJECTED,
  IS_EDIT_SUCCESS,
  IS_EDIT_CANCEL,
} from "./templatesTypes";

const initialState = {
  templates: [],
  template: {},
  isTemplateCreated: false,
  isTemplateRejected: false,
  isEditSuccess: false,
  isEditCancel: false,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES:
      return { ...state, templates: action.data };
    case ADD_NEW_TEMPLATE:
      return { ...state, templates: [...state.templates, action.data] };
    case GET_TEMPLATE_BY_ID:
      return { template: action.data };
    case DELETE_TEMPLATE_BY_ID:
      return {
        ...state,
        templates: state.templates.filter(
          (template) => template._id !== action.id
        ),
      };
    case IS_TEMPLATE_CREATED:
      return { ...state, isCreatedAdmin: action.data };
    case IS_TEMPLATE_REJECTED:
      return { ...state, isTemplateRejected: action.data };

    case IS_EDIT_SUCCESS:
      return { ...state, isEditSuccess: action.data };
    case IS_EDIT_CANCEL:
      return { ...state, isEditCancel: action.data };

    default:
      return state;
  }
};

export default templatesReducer;
