import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, IconButton } from "@material-ui/core";
import { ButtonCreate } from "../../components/ButtonCreate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../CustomizeProductPage/components/FileUploader/FileUploader";
import DiskSide from "./images/cd-template.png";
import BoxSide from "./images/box-template.png";
import { Layer, Stage } from "react-konva";
import { Img } from "../CustomizeProductPage/components/Img/Img";
import domtoimage from "dom-to-image";
import DialogContent from "@material-ui/core/DialogContent";
import { ModerationSlider } from "../CustomizeProductPage/ModerationSlider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {
  addNewProduct,
  ProductType,
} from "../../redux/products/productsActions";
import { dataURLtoFile, getRandomString, srcToFile } from "../../utils";
import { encodeImageFileAsURL } from "../../utils/converteImageToBase64";
import { getTemplateById } from "../../redux/templates/templatesActions";
import { Preloader } from "../../components/Preloader";
import CrossIcon from "../../assets/images/close.svg";
import "./styles.scss";
import { Alert, AlertTitle } from "@material-ui/lab";

const initialRectangles = [];

const stageWidth = 600;
const stageHeight = 600;
const imageDiskWidth = 418;
// const imageDiskWidth = 100;
const imageDiskHeight = 415;
// const imageDiskHeight = 100;
const imageBoxWidth = 399;
const imageBoxHeight = 399;
let timer;
const sidesConfig = [
  {
    name: "side A-disk",
    id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: DiskSide,
    disk: true,
    side: "a_side",
  },
];

const boxesConfig = [
  {
    name: "side A-box",
    id: "3160ba8d-1c3b-4ced-ba85-f19948cb76d7",
    path: BoxSide,
    disk: false,
    side: "c_side",
  },
  {
    name: "side B-box",
    id: "5521c296-55a2-11eb-ae93-0242ac130002",
    path: BoxSide,
    disk: false,
    side: "d_side",
  },
];

const requiredFields = ["a_side", "price", "name", "audio"];

const productDescription =
  "Please enter all information below to create your custom CD. Once submitted our Admins review each product before setting it live to your store.  Max recording time for CDs is 80 mins. ";

export const CdCreate = () => {
  const [activeImage, setActiveImage] = useState(null);
  const [activeSideName, setActiveImagesSide] = useState("a_side");
  const [selectedId, selectShape] = useState(null);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [idCount, setIdCount] = useState(0);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [screenshotSave, setScreenshotSave] = useState(false);
  const [isScreenshotFetching, setIsScreenshotFetching] = useState(false);

  // console.log("values", values);
  const history = useHistory();
  const canvasRef = React.useRef();

  const styleProps = {
    position: "relative",
    backgroundColor: "transparent",
    top: activeImage?.disk ? "50%" : "48%",
    left: "50%",
    width: activeImage?.disk ? imageDiskWidth : imageBoxWidth,
    height: activeImage?.disk ? imageDiskHeight : imageBoxHeight,
    borderRadius: activeImage?.disk ? "50%" : 0,
    transform: "translate(-50%, -50%)",
  };

  const [files, setFiles] = useState({});
  const [completeImages, setCompleteImages] = useState({});
  const [detailInfoFiles, setDetailInfoFiles] = useState({});
  const [isSendData, setIsSendData] = useState(false);
  const template = useSelector((state) => state.templatesReducer.template);
  // console.log("template", template);
  const isLoading = useSelector((state) => state.commonReducer.isFetching);
  const dispatch = useDispatch();
  const [totalCheckFields, setTotalCheckFields] = useState(false);

  const [uploadFirstImage, setUploadNewImage] = useState(0);
  const [secondRender, setSecondRender] = useState(0);
  const [thirdRender, setThirdRender] = useState(0);

  const lowPriceCheck = values?.price <= template?.manufacturing_cost;
  console.log("completeImages", completeImages);
  console.log("values", values);
  useEffect(() => {
    if (!totalCheckFields) {
      clearTimeout(timer);
    }
  }, [totalCheckFields]);

  console.log("rectangles", rectangles);
  useEffect(() => {
    dispatch(getTemplateById("vinyl"));
  }, [dispatch]);

  useEffect(() => {
    setFiles({
      a_side: "",
      c_side: "",
      d_side: "",
    });
  }, [setFiles]);

  useEffect(() => {
    setActiveImage(sidesConfig[0]);
  }, []);

  const handleImageLoaded = (imgUrl) => {
    const checkFiles = Object.values(completeImages).length >= 2;
    if (checkFiles) {
      setErrors((prevState) => ({ ...prevState, files: "" }));
    }
    const newImg = buildImgProperty(imgUrl);
    if (rectangles.find((r) => r.side === activeSideName)) {
      const index = rectangles.findIndex((r) => r.side === activeSideName);
      setRectangles((prevState) => {
        const newR = [...prevState];
        newR[index] = newImg;
        return [...newR];
      });
    } else {
      setRectangles([...rectangles, newImg]);
      setUploadNewImage((prevState) => ++prevState);
    }
    setIsScreenshotFetching(true);
    // setTimeout(() => {
    //   screenshot();
    // }, 100);
  };

  useEffect(() => {
    setUploadNewImage((prevState) => ++prevState);
  }, [rectangles]);

  useEffect(() => {
    setTimeout(() => {
      setSecondRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [uploadFirstImage]);

  useEffect(() => {
    setTimeout(() => {
      setThirdRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [secondRender]);

  useEffect(() => {
    setTimeout(() => {
      screenshot();
    }, 200);
  }, [thirdRender]);
  // useEffect(() => {
  //   if (rectangles.length !== 0) {
  // setTimeout(() => {
  //   setRectangles((prevState) => {
  //     const rec = prevState.find((rec) => rec.side === activeSideName);
  //     rec.x = 20;
  //     rec.y = 20;
  //     console.log("rec", rec);
  //     return [...prevState, rec];
  //   });
  // }, 1000);
  // const content = document.getElementsByClassName("konvajs-content");
  //
  // if (content.length) {
  // const evt = new MouseEvent("click", {
  //   bubbles: true,
  //   cancelable: true,
  //   view: window,
  // });
  //   console.log("TRY TO CLICK", content[0]);
  //   content[0].click();
  // }
  // }
  // const content = document.getElementsByClassName("konvajs-content");
  // console.log("TRY TO CLICK", content[0]);
  //
  // if (content.length) {
  //   if (document.all) {
  //     console.log("!!!!!");
  //     content[0].click();
  //   } else {
  //     console.log("??????");
  //     var evObj = document.createEvent("MouseEvents");
  //     evObj.initMouseEvent(
  //       "click",
  //       true,
  //       true,
  //       window,
  //       1,
  //       12,
  //       12,
  //       7,
  //       21,
  //       false,
  //       false,
  //       true,
  //       false,
  //       0,
  //       null
  //     );
  //     content[0].dispatchEvent(evObj);
  //   }
  // }
  // }, [rectangles]);

  console.log("rectangles", rectangles);
  const buildImgProperty = (imgUrl) => {
    const props = {
      side: activeSideName,
      x: 20,
      y: 20,
      width: imageDiskWidth,
      height: imageDiskHeight,
      id: `rect${idCount}`,
      imgUrl,
      parentId: activeImage.id,
    };
    setIdCount((prevCountValue) => prevCountValue + 1);
    return props;
  };

  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === activeImage.id
  );

  // console.log("rectanglesByImage", rectanglesByImage);
  // const rectanglesByImage = rectangles[activeSideName];
  const changeActiveImage = (image, sideName) => {
    if (!isScreenshotFetching) {
      setTimeout(() => {
        setActiveImagesSide(sideName);
        setActiveImage(image);
      }, 200);
    }
  };
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const onChangeInputValues = (name) => (e) => {
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    if (value) setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const checkRequired = () => {
    let approved = [];
    requiredFields.forEach((fieldName, index) => {
      if (fieldName === "audio") {
        if (values.audio?.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            audio: "The field is required",
          }));
          return;
        }
      }
      if (!values[fieldName]) {
        setErrors((prevState) => ({
          ...prevState,
          [fieldName]: "The field is required",
        }));
        approved.push(false);
      } else if (values[fieldName]) {
        setErrors((prevState) => ({ ...prevState, [fieldName]: null }));
        approved.push(true);
      }
    });

    return approved;
  };
  const generateTracks = (side, data) => {
    return { side, data };
  };

  const generateFormData = async () => {
    const formData = new FormData();
    let ACompleteImageDisk, ACompleteImageBox, BCompleteImageBox;
    const AStringUrlImgDisk = completeImages["a_side"];
    const AStringUrlImgBox = completeImages["c_side"];
    const BStringUrlImgBox = completeImages["d_side"];
    if (AStringUrlImgDisk) {
      ACompleteImageDisk = await dataURLtoFile(
        AStringUrlImgDisk,
        `${getRandomString(20)}.png`
      );
      formData.append("a_side", ACompleteImageDisk);
    }
    if (AStringUrlImgBox) {
      ACompleteImageBox = await dataURLtoFile(
        AStringUrlImgBox,
        `${getRandomString(20)}.png`
      );
      formData.append("front", ACompleteImageBox);
    }
    if (BStringUrlImgBox) {
      BCompleteImageBox = await dataURLtoFile(
        BStringUrlImgBox,
        `${getRandomString(20)}.png`
      );
      formData.append("back", BCompleteImageBox);
    }
    if (values["a_side"]) {
      formData.append(
        "tracks",
        JSON.stringify([generateTracks("a_side", values["a_side"])])
      );
    }

    formData.append("name", values["name"]);
    formData.append("template", template._id);
    formData.append("price", values["price"]);

    if (values["comment"]) {
      formData.append("description", values["comment"]);
    }

    if (detailInfoFiles["a_side"]) {
      formData.append("originals_a_side", detailInfoFiles["a_side"]);
    }
    if (detailInfoFiles["c_side"]) {
      formData.append("originals_front", detailInfoFiles["c_side"]);
    }
    if (detailInfoFiles["d_side"]) {
      formData.append("originals_back", detailInfoFiles["d_side"]);
    }
    values["audio"].forEach((file) => {
      formData.append("audio", file);
    });
    formData.append("collection_id", template.webkul_collection_id);
    return formData;
  };

  const onSubmit = async () => {
    const data = await generateFormData();
    // for (let pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1].toString());
    // }
    dispatch(addNewProduct(data, ProductType.CD));
    setIsSendData(true);
  };

  const setOriginalImage = (side) => (image) => {
    setCompleteImages((prevState) => ({ ...prevState, [side]: image }));
  };
  const checkAvailableImages = () => {
    const sides = ["a_side", "c_side", "d_side"];
    sides.forEach((side) => {
      const isBox = side === "c_side" || side === "d_side";
      if (!completeImages[side]) {
        srcToFile(isBox ? BoxSide : DiskSide, getRandomString(20)).then(
          (file) => {
            const imgInBase64 = encodeImageFileAsURL(
              file,
              setOriginalImage(side)
            );
            setCompleteImages((prevState) => ({
              ...prevState,
              [side]: imgInBase64,
            }));
          }
        );
      }
    });
  };

  const onOpenModal = () => {
    const checkFiles = Object.values(detailInfoFiles).length >= 3;
    if (lowPriceCheck) return;
    if (checkFiles) {
      const approved = checkRequired();
      const allApproved = approved.every((element) => element);
      checkAvailableImages();
      if (
        !Object.values(errors).filter((value) => value).length &&
        allApproved
      ) {
        setTotalCheckFields(false);
        selectShape(null);
        setOpenModal(true);
      } else {
        setTotalCheckFields(true);
        timer = setTimeout(setTotalCheckFields, 4000, false);
      }
      setErrors((prevState) => ({ ...prevState, files: "" }));
    } else {
      setTotalCheckFields(true);
      timer = setTimeout(setTotalCheckFields, 4000, false);
      setErrors((prevState) => ({
        ...prevState,
        files: "Please upload image(s)",
      }));
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  function simulateClick(elem) {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    elem && elem.dispatchEvent(evt);
  }

  const screenshot = () => {
    if (
      rectangles?.length &&
      rectangles?.find((r) => r.side === activeSideName)
    ) {
      setScreenshotSave(false);
      setIsScreenshotFetching(true);

      const node = document.getElementById("stage-node");
      const content = document.querySelector("canvas");
      if (content) simulateClick(content);
      if (node) {
        domtoimage
          .toPng(node)
          .then(function (dataUrl) {
            console.log("dataUrl", dataUrl);
            setCompleteImages((prevState) => ({
              ...prevState,
              [activeImage?.side]: dataUrl,
            }));
            setScreenshotSave(true);
          })
          .catch(function (error) {
            setIsScreenshotFetching(false);
            console.error("oops, something went wrong!", error);
          });
      }
    }
  };

  useEffect(() => {
    if (screenshotSave) setIsScreenshotFetching(false);
  }, [screenshotSave]);
  // console.log("files", files);

  const onSetFiles = (file) => {
    // setFiles((prevState) => [...prevState, file]);
    setFiles((prevState) => ({ ...prevState, [activeImage.side]: file }));
    if (file)
      setDetailInfoFiles((prevState) => ({
        ...prevState,
        [activeImage.side]: file,
      }));
  };
  console.log("detailInfoFiles", detailInfoFiles);

  const onSetAudioFiles = (files) => {
    const isInValues = Array.isArray(files)
      ? values.audio?.some((a) => files.some((file) => file.name === a.name))
      : values.audio?.some((a) => a.name === files.name);
    if (isInValues) {
      setErrors((prevState) => {
        const error = {
          ...prevState,
          audio: "You can`t upload same tracks",
        };
        return error;
      });
      return;
    }

    const checkFiles = files.some(
      (file) =>
        !(
          (file.type.includes("mpeg") || file.type.includes("mp4"))
          // ||
          // file.type.includes("wav") ||
          // file.type.includes("x-flac")
        )
    );

    if (!checkFiles) {
      setValues((prevState) => ({
        ...prevState,
        audio: [...(prevState.audio || []), ...files],
      }));
      setErrors((prevState) => ({ ...prevState, audio: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        audio: "All audio files must be mp3 or mp4 formats",
      }));
    }
    // setTimeout(
    //   () =>
    //     setErrors((prevState) => {
    //       const error = {
    //         ...prevState,
    //         audio: "",
    //       };
    //       return error;
    //     }),
    //   4000
    // );
  };

  const onDeleteAudio = (id) => {
    setValues((prevState) => ({
      ...prevState,
      audio: prevState.audio.filter((item) => item.lastModified !== id),
    }));
    if (values.audio?.length === 1) {
      setErrors((prevState) => ({
        ...prevState,
        audio: "The field is required",
      }));
    }
  };

  if (isSendData && !isLoading) return <Redirect to={"/"} />;
  return activeImage && !isLoading ? (
    <div
      className={`product product__wrapper ${
        activeImage.disk ? "disk" : "box"
      }`}
    >
      <div className="back-btn">
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" style={{ color: "black" }} /> Back to
          Dashboard
        </IconButton>
      </div>
      <div className="product__content">
        <div>
          <div className="product__images images">
            <div className="customize__stage stage">
              <div
                id="stage-node"
                className="stage__stage-block"
                style={{
                  backgroundImage: `url("${activeImage.path}")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: stageWidth,
                  height: stageHeight,
                  position: "relative",
                }}
              >
                <div className="print-area" style={styleProps}>
                  <Stage
                    id="canvas-id"
                    width={styleProps.width}
                    height={styleProps.height}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    ref={canvasRef}
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                    onMouseLeave={() => {
                      setTimeout(() => {
                        screenshot();
                      }, 600);
                    }}
                    // onDragEnd={screenshot}
                    // onTransformEnd={screenshot}
                    // onTouchEnd={screenshot}
                    // onMouseUp={() => {
                    //   screenshot();
                    // }}
                  >
                    {rectanglesByImage.map((rect, i) => {
                      return (
                        <Layer key={i + "layer"}>
                          <Img
                            imageWidth={imageDiskWidth}
                            imageHeight={imageDiskHeight}
                            canvasRef={canvasRef}
                            shapeProps={rect}
                            isSelected={rect.id === selectedId}
                            onSelect={() => {
                              selectShape(rect.id);
                            }}
                            width={stageWidth}
                            height={stageHeight}
                            onChange={(newAttrs) => {
                              const rects = rectangles.slice();
                              const rectIndex = rects.findIndex(
                                (e) => newAttrs.id === e.id
                              );
                              rects[rectIndex] = newAttrs;
                              setRectangles(rects);
                            }}
                          />
                        </Layer>
                      );
                    })}
                  </Stage>
                  {activeImage.disk && <div className="circle-background" />}
                </div>
              </div>
            </div>
            <div className="image-options">
              <div className="sides-wrapper">
                {sidesConfig.map((side) => (
                  <div className={`image-container`} key={side.id}>
                    <img
                      src={side.path}
                      alt=""
                      className={`image_container ${
                        activeSideName === side.side
                          ? "image_container_active"
                          : ""
                      }`}
                      data-index={side.id}
                      onClick={() => changeActiveImage(side, side.side)}
                    />
                    <p>{side.name}*</p>
                  </div>
                ))}
              </div>
              <div className="sides-wrapper">
                {boxesConfig.map((side) => (
                  <div className={`image-container`} key={side.id}>
                    <img
                      src={side.path}
                      alt=""
                      className={`image_container ${
                        activeSideName === side.side
                          ? "image_container_active"
                          : ""
                      }`}
                      data-index={side.id}
                      onClick={() => changeActiveImage(side, side.side)}
                    />
                    <p>{side.name}*</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="upload__files">
            {errors.files && <div className="error">{errors.files}</div>}
            <div style={{ height: "35px" }}>
              {isScreenshotFetching && (
                <div className="error">
                  Please wait, your product is processing
                </div>
              )}
            </div>
            <FileUploader
              isDisabled={isScreenshotFetching}
              onSetFiles={onSetFiles}
              onImageLoaded={handleImageLoaded}
            />
            <div className="explanation__block">* - required</div>
          </div>
        </div>
        <div className="product__description description">
          <div className="description__main-info">
            <p>Description:</p>
            <div className="description__info">{productDescription}</div>
          </div>
          <div className="items">
            <div className="price-block__price block_positions">
              <p>CD name</p>
              <TextField
                required
                type="text"
                id="outlined-basic"
                variant="outlined"
                name={"name"}
                value={values.name}
                onChange={onChangeInputValues("name")}
                error={errors?.name}
                helperText={errors?.name && "Field is required"}
                placeholder={"сd name*"}
              />
            </div>
            <div className="price-block__price block_positions">
              Track order
              <TextField
                multiline
                rows={4}
                required
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"price"}
                value={values.a_side}
                onChange={onChangeInputValues("a_side")}
                error={errors?.a_side}
                helperText={errors?.a_side && "Field is required"}
              />
            </div>
            <div className="linear__block block_positions">
              <p>Comment</p>
              <TextField
                multiline
                rows={4}
                type="text"
                id="outlined-basic"
                label="comment"
                variant="outlined"
                name={"comment"}
                value={values.comment}
                onChange={onChangeInputValues("comment")}
              />
            </div>
            <div className="price-block__price block_positions">
              Set Price Of product
              <TextField
                required
                type="number"
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name={"price"}
                value={values.price}
                onChange={onChangeInputValues("price")}
                InputProps={{
                  inputProps: { min: template.manufacturing_cost || 10 },
                }}
                error={errors?.price}
                helperText={errors?.price && "Field is required"}
              />
            </div>
            {lowPriceCheck && (
              <Alert severity="error">
                <AlertTitle>
                  You cannot set price that is lover than manufacturing price.
                </AlertTitle>
                Price depends on the chosen quantity of sides for printing and
                can be changed due customizing.
              </Alert>
            )}
            <div className="description__cost-text">
              Manufacturing Cost:{" "}
              <span style={{ fontWeight: "bold" }}>
                ${template.manufacturing_cost}
              </span>
            </div>
          </div>
          <div className="upload__files">
            {values?.audio?.map((audioFile) => {
              return (
                <div className="upload__files-list">
                  <div className={"audio_item"}>
                    {audioFile.name}
                    <img
                      src={CrossIcon}
                      alt=""
                      className={"remove_audio_icon"}
                      onClick={() => onDeleteAudio(audioFile.lastModified)}
                    />
                  </div>
                </div>
              );
            })}

            {errors?.audio && <div className="error">{errors.audio}</div>}
            <FileUploader
              title={"Upload audio files"}
              onSetFiles={onSetAudioFiles}
              multiple={true}
            />
          </div>
          <div className="description__button-block marginTop">
            <Dialog
              open={openModal}
              onClose={closeModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Check your design before sending to moderation"}
              </DialogTitle>
              <DialogContent style={{ minHeight: 500, minWidth: 500 }}>
                <ModerationSlider
                  completeImages={Object.values(completeImages)}
                />
                {isScreenshotFetching && (
                  <div className="error">
                    Please wait, your product is processing
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <ButtonCreate
                  text={"Back to Designing"}
                  handleClick={closeModal}
                />

                <ButtonCreate
                  isDisabled={isScreenshotFetching}
                  text={"Send to Moderation"}
                  handleClick={onSubmit}
                />
              </DialogActions>
            </Dialog>
            <Collapse in={totalCheckFields}>
              <Alert color="error">Please, check required fields</Alert>
            </Collapse>
            <ButtonCreate
              className="create-design"
              text={"Save"}
              size={"large"}
              fullWidth={true}
              handleClick={onOpenModal}
            />
          </div>
        </div>
      </div>
    </div>
  ) : isLoading ? (
    <Preloader />
  ) : (
    <></>
  );
};
