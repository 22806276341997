import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
// import { FileUploader } from "../components/FileUploader/FileUploader";
import { FileUploader } from "../../CustomizeProductPage/components/FileUploader/FileUploader";
import {
  FormControlLabel,
  IconButton,
  Snackbar,
  Switch,
} from "@material-ui/core";
import { Layer, Stage } from "react-konva";
import { Img } from "../../CustomizeProductPage/components/Img/Img";
import { ButtonCreate } from "../../../components/ButtonCreate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./styles.scss";
/////////////////INPUTS
import TextField from "@material-ui/core/TextField";

import { Alert, AlertTitle } from "@material-ui/lab";
//////////////MODAL
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
//////////////////new
import { getTemplateById } from "../../../redux/templates/templatesActions";
import { useDispatch, useSelector } from "react-redux";
// import { Preloader } from "../../CustomizeProductPage/co";
import { ModerationSlider } from "../../CustomizeProductPage/ModerationSlider";

import { Preloader } from "../../../components/Preloader";
import {
  buildImgProperty,
  dataURLtoFile,
  getRandomString,
  getTemplateImages,
  srcToFile,
} from "../../../utils";
import { encodeImageFileAsURL } from "../../../utils/converteImageToBase64";
import { screenshot } from "../../../utils";
import {
  addNewProduct,
  editRejected,
  getInactiveProducts,
  getRejectedProducts,
  ProductType,
} from "../../../redux/products/productsActions";
const initialRectangles = [];
const coefficientHeight = 6;
const coefficientWidth = 6;
const stageWidth = 600;
const stageHeight = 600;
const imageWidth = 300;
const imageHeight = 300;

export const EditRejectedApparel = () => {
  const history = useHistory();
  const canvasRef = React.useRef();
  const [idCount, setIdCount] = useState(0);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [selectedId, selectShape] = useState(null);
  const [files, setFiles] = useState([]);
  const [hidePrintArea, setHidePrintArea] = useState(false);
  const [screenshotSave, setScreenshotSave] = useState(false);
  const [isScreenshotFetching, setIsScreenshotFetching] = useState(false);
  const [mainImage, setMainImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [completeImages, setCompleteImages] = useState({});
  const [errors, setErrors] = useState({});
  const [detailInfoFiles, setDetailInfoFiles] = useState({});
  const [formValues, setFormValues] = useState({
    name: "",
    price: "",
    description: "",
    images: [],
  });
  const [globalError, setGlobalError] = useState(
    "You need to upload at least one image"
  );
  const [isSendData, setIsSendData] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);

  const templateOfProduct = useSelector(
    (state) => state.templatesReducer.template
  );
  const isLoading = useSelector((state) => state.commonReducer.isFetching);
  const dispatch = useDispatch();
  const params = useParams();
  const rejectedProducts = useSelector(
    (state) => state.productsReducer.rejectedProducts
    // (state) => state.productsReducer.inactiveProducts
  );
  const product = rejectedProducts?.find(
    (product) => product._id === params.id
  );
  console.log("product", product);
  useEffect(() => {
    setFormValues({
      name: product?.name,
      price: product?.price,
      description: product?.description,
      images: [],
    });
  }, [product]);

  useEffect(() => {
    if (product) {
      dispatch(getTemplateById(product.template._id));
    }
  }, [product]);

  const handleImageLoaded = (imgUrl) => {
    const newImg = buildImgProperty(
      imgUrl,
      mainImage,
      imageWidth,
      imageHeight,
      idCount,
      setIdCount
    );
    if (rectangles.find((r) => r.side === mainImage.side)) {
      const index = rectangles.findIndex((r) => r.side === mainImage.side);
      setRectangles((prevState) => {
        const newR = [...prevState];
        newR[index] = newImg;
        return [...newR];
      });
    } else {
      setRectangles([...rectangles, newImg]);
    }
    setTimeout(() => {
      setIsScreenshotFetching(true);
      screenshot(mainImage, setCompleteImages);
    }, 100);
    setGlobalError("");
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const setOriginalImage = (side) => (image) => {
    setCompleteImages((prevState) => ({ ...prevState, [side]: image }));
  };

  const checkAvailableImages = () => {
    product.images.forEach((image) => {
      if (!completeImages[image.side]) {
        srcToFile(image.path, getRandomString(20)).then((file) => {
          const imgInBase64 = encodeImageFileAsURL(
            file,
            setOriginalImage(image.side)
          );
          if (imgInBase64)
            setCompleteImages((prevState) => ({
              ...prevState,
              [image]: imgInBase64,
            }));
        });
      }
    });
  };

  const openModal = () => {
    selectShape(null);
    setHidePrintArea(true);
    checkAvailableImages();
    setOpen(true);
  };
  const closeModal = () => {
    setHidePrintArea(false);
    setOpen(false);
  };

  console.log("rejectedProducts", rejectedProducts);
  useEffect(() => {
    dispatch(getRejectedProducts());
    // dispatch(getInactiveProducts());
  }, [dispatch]);

  useEffect(() => {
    setMainImage(templateOfProduct?.images && templateOfProduct?.images[0]);
  }, [templateOfProduct]);

  const { front, back } = getTemplateImages(templateOfProduct);
  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === mainImage._id
  );
  useEffect(() => {
    if (screenshotSave) setIsScreenshotFetching(false);
  }, [screenshotSave]);
  const resetFieldError = (field) => {
    setErrors({ ...errors, [field]: false });
  };

  const isFormValid = () => {
    const errors = {};
    for (const field in formValues) {
      errors[field] = validateField(field);
    }

    setErrors(errors);
    return Object.values(errors).every((e) => !e);
  };

  const validateField = (field) => {
    switch (field) {
      default:
        return !Boolean(formValues[field]);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    const values = { ...formValues, [name]: value };

    if (errors[name]) resetFieldError(name);

    setFormValues(values);
  };

  useEffect(() => {
    const latestUpdatedFile = files[files.length - 1];
    if (latestUpdatedFile) {
      setDetailInfoFiles((prevState) => ({
        ...prevState,
        [mainImage.side]: latestUpdatedFile,
      }));
      setGlobalError("");
    }
  }, [mainImage, files]);

  const createFormData = async () => {
    const formData = new FormData();
    let completeImageFront, completeImageBack;
    const { name, price, description } = formValues;
    if (name !== product.name) {
      formData.append("name", name);
    }
    if (price !== product.price) {
      formData.append("price", price);
    }
    // formData.append("template", product._id);
    // formData.append("size", product.sizes);
    if (description !== product.description) {
      formData.append("description", description);
    }
    if (isEditImage) {
      if (detailInfoFiles["front"]) {
        formData.append("originals_front", detailInfoFiles["front"]);
      }
      if (detailInfoFiles["back"]) {
        formData.append("originals_back", detailInfoFiles["back"]);
      }
      const toStringUrlImgFront = completeImages["front"];
      const toStringUrlImgBack = completeImages["back"];
      if (toStringUrlImgFront) {
        completeImageFront = await dataURLtoFile(
          toStringUrlImgFront,
          `${getRandomString(20)}.png`
        );
        formData.append("front", completeImageFront);
      }
      if (toStringUrlImgBack) {
        completeImageBack = await dataURLtoFile(
          toStringUrlImgBack,
          `${getRandomString(20)}.png`
        );

        formData.append("back", completeImageBack);
      }
    }

    return formData;
  };

  const submitEditProduct = async () => {
    closeModal();
    const data = await createFormData();
    console.log("edit rejected product!");
    dispatch(editRejected(data, product._id, ProductType.APPAREL));
    setIsSendData(true);
  };

  const handleFormSubmit = () => {
    if (!isFormValid()) {
      console.log("form is not valid");
      return;
    }
    submitEditProduct();
  };

  const handleFormReset = () => {
    setFormValues({
      name: "",
      price: "",
      description: "",
      images: [],
    });
    setRectangles([]);
    setDetailInfoFiles({});
    setScreenshotSave(false);
  };

  const { name, price, description } = formValues;

  const onChangeMainImage = (image) => {
    setTimeout(() => {
      setMainImage(image);
    }, 200);
  };

  const styleProps =
    mainImage && mainImage.side === "back"
      ? {
          position: "relative",
          backgroundColor: hidePrintArea ? "transparent" : "rgba(0,0,0)",
          top: (back.y || 0) * coefficientHeight,
          left: (back.x || 0) * coefficientWidth,
          width: (back.width || 0) * coefficientWidth,
          height: (back.height || 0) * coefficientHeight,
        }
      : {
          position: "relative",
          backgroundColor: hidePrintArea ? "transparent" : "rgba(0,0,0)",
          top: (front.y || 0) * coefficientHeight,
          left: (front.x || 0) * coefficientWidth,
          width: (front.width || 0) * coefficientWidth,
          height: (front.height || 0) * coefficientHeight,
        };

  const lowPriceCheck = price <= product?.manufacturing_cost;
  const isDisabled =
    Object.values(detailInfoFiles).length >= 1 ||
    (!isEditImage &&
      !lowPriceCheck &&
      name &&
      description &&
      price &&
      !isEditImage);

  const isEditImagesChange = () => {
    setIsEditImage(!isEditImage);
    setErrors((prevState) => ({
      ...prevState,
      files: "",
    }));
  };

  if (isSendData && !isLoading) return <Redirect to={"/"} />;
  return !isLoading ? (
    <div className="customize" style={{ paddingTop: "90px" }}>
      <div className="back-btn">
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" style={{ color: "black" }} />
        </IconButton>
      </div>
      <div className="customize__title">Customize Your Product</div>
      <div className="customize__content">
        <div className="customize__menu">
          <div className="menu">
            <div className="menu__images">
              {product?.images?.length &&
                product?.images.map((image, index) => {
                  console.log("image", image);
                  return (
                    <div key={index}>
                      <div
                        className={`images__secondary-image ${
                          mainImage?.side === image.side &&
                          "images__secondary-image--selected"
                        }`}
                      >
                        <img
                          data-index={image._id}
                          src={image.path}
                          alt="secondary"
                          onClick={() => onChangeMainImage(image)}
                        />
                      </div>
                      <p style={{ textTransform: "uppercase" }}>{image.side}</p>
                    </div>
                  );
                })}
            </div>
            <div className="menu__options">
              <p>Technique of Print :</p>
              <p>CMYK</p>

              <FormControlLabel
                control={
                  <Switch
                    checked={isEditImage}
                    onChange={isEditImagesChange}
                    name="checkedImages"
                    color="primary"
                    // inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Need to upload new image?"
              />
              {isEditImage && (
                <div>
                  <h2>Add design</h2>
                  {globalError && <div className={"error"}>{globalError}</div>}
                  <FileUploader
                    files={files}
                    setFiles={setFiles}
                    onImageLoaded={handleImageLoaded}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="customize__stage stage">
          {isEditImage && (
            <div
              id="stage-node"
              className="stage__stage-block"
              style={{
                backgroundImage: mainImage
                  ? `url("${mainImage.path}")`
                  : `url("${mainImage}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: stageWidth,
                height: stageHeight,
                position: "relative",
              }}
            >
              <div className="print-area" style={styleProps}>
                <Stage
                  width={styleProps.width}
                  height={styleProps.height}
                  onMouseDown={checkDeselect}
                  onTouchStart={checkDeselect}
                  ref={canvasRef}
                  style={{
                    borderRadius: "5px",
                    display: "flex",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onMouseLeave={() => {
                    setTimeout(() => {
                      setScreenshotSave(false);
                      setIsScreenshotFetching(true);
                      screenshot(
                        mainImage,
                        setCompleteImages,
                        setScreenshotSave
                      );
                    }, 600);
                  }}
                  onDragEnd={() => {
                    setTimeout(() => {
                      setScreenshotSave(false);
                      setIsScreenshotFetching(true);
                      screenshot(
                        mainImage,
                        setCompleteImages,
                        setScreenshotSave
                      );
                    }, 600);
                  }}
                  onTransformEnd={() => {
                    setTimeout(() => {
                      setScreenshotSave(false);
                      setIsScreenshotFetching(true);
                      screenshot(
                        mainImage,
                        setCompleteImages,
                        setScreenshotSave
                      );
                    }, 600);
                  }}
                  onTouchEnd={() => {
                    setTimeout(() => {
                      setScreenshotSave(false);
                      setIsScreenshotFetching(true);
                      screenshot(
                        mainImage,
                        setCompleteImages,
                        setScreenshotSave
                      );
                    }, 600);
                  }}
                  onMouseUp={() => {
                    setTimeout(() => {
                      setScreenshotSave(false);
                      setIsScreenshotFetching(true);
                      screenshot(
                        mainImage,
                        setCompleteImages,
                        setScreenshotSave
                      );
                    }, 600);
                  }}
                >
                  {rectanglesByImage.map((rect, i) => {
                    return (
                      <Layer key={i + "layer"}>
                        <Img
                          imageWidth={imageWidth}
                          imageHeight={imageHeight}
                          canvasRef={canvasRef}
                          shapeProps={rect}
                          isSelected={rect.id === selectedId}
                          onSelect={() => {
                            selectShape(rect.id);
                          }}
                          width={stageWidth}
                          height={stageHeight}
                          onChange={(newAttrs) => {
                            const rects = rectangles.slice();
                            const rectIndex = rects.findIndex(
                              (e) => newAttrs.id === e.id
                            );
                            rects[rectIndex] = newAttrs;
                            setRectangles(rects);
                          }}
                        />
                      </Layer>
                    );
                  })}
                </Stage>
              </div>
            </div>
          )}
          <div className="stage__sub-menu">
            <div className="stage__price-block price-block">
              <div className="price-block__manuf-cost">
                Manufacturing Cost {product?.template.manufacturing_cost}$
              </div>
              <div className="price-block__name">
                Set name Of product
                <TextField
                  required
                  type="text"
                  label="Name"
                  variant="outlined"
                  name={"name"}
                  value={name}
                  onChange={handleChangeInput}
                  error={errors?.name}
                  helperText={errors?.name && "Field is required"}
                />
              </div>
              <div className="price-block__price">
                Set Price Of product
                <TextField
                  required
                  type="number"
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  name={"price"}
                  value={price}
                  onChange={handleChangeInput}
                  error={errors?.price}
                  helperText={errors?.price && "Field is required"}
                />
              </div>
            </div>
          </div>
          {lowPriceCheck && (
            <Alert severity="error">
              <AlertTitle>
                You cannot set price that is lover than manufacturing price.
              </AlertTitle>
              Price depends on the chosen quantity of sides for printing and can
              be changed due customizing.
            </Alert>
          )}
          <div className="price-block__desc">
            <p>Add description here:</p>
            <TextField
              fullWidth={true}
              required={true}
              id="outlined-basic"
              label="Product Description"
              variant="outlined"
              name={"description"}
              value={description}
              onChange={handleChangeInput}
              error={errors?.description}
              helperText={errors?.description && "Field is required"}
            />
          </div>
          <div className="price-block__mature-content"></div>
          <div className="price-block__buttons">
            <ButtonCreate
              className="with-margin"
              text={"reset"}
              handleClick={handleFormReset}
            />
            <ButtonCreate
              isDisabled={!isDisabled}
              text={"send to moderation"}
              handleClick={openModal}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isEditImage
            ? "Check your design before sending to moderation"
            : "Send to moderation"}
        </DialogTitle>
        <DialogContent
          style={{ minHeight: isEditImage ? 500 : 70, minWidth: 500 }}
        >
          {isEditImage && (
            <ModerationSlider completeImages={Object.values(completeImages)} />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonCreate text={"Back to Designing"} handleClick={closeModal} />
          <ButtonCreate
            isDisabled={false}
            text={"Send to Moderation"}
            handleClick={handleFormSubmit}
          />
        </DialogActions>
      </Dialog>
      {isScreenshotFetching && (
        <Snackbar
          open={isScreenshotFetching}
          // onClose={() => setIsScreenshotFetching(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity="warning">
            Please wait, your product is processing
          </Alert>
        </Snackbar>
      )}
    </div>
  ) : (
    <Preloader />
  );
};

// import React, { useEffect, useState } from "react";
// import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllCollections } from "../../../redux/collections/collectionsActions";
// import { getAllCategories } from "../../../redux/categories/categoriesActions";
// import {
//   editApparelTemplate,
//   getTemplateById,
// } from "../../../redux/templates/templatesActions";
// import { DropzoneDialog } from "material-ui-dropzone";
// import "./style.scss";
// import { ButtonCreate } from "../../../components/ButtonCreate";
// import { useHistory, useParams } from "react-router-dom";
// import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import Alert from "@material-ui/lab/Alert";
// import { ImagesConfig } from "../../../superAdminPages/CreateTemplateClothing/ImagesConfig";
// import { TemplateGroup } from "../../../utils/templateGroup";
// import {
//   getInactiveProducts,
//   getRejectedProducts,
// } from "../../../redux/products/productsActions";
// import { ImagePreview } from "../../../components/ImagePreview";
// import { Layer, Stage } from "react-konva";
// import { Img } from "../../CustomizeProductPage/components/Img/Img";
// import { FileUploader } from "../../CustomizeProductPage/components/FileUploader/FileUploader";
// import { VinylImagesUpload } from "../VinylImageUpload";
//
// const uuid = () => "_" + Math.random().toString(36).substr(2, 9);
//
// export const TYPES = {
//   FRONT: "front",
//   BACK: "back",
// };
//
// const CHECKBOX_OPTIONS = [
//   {
//     label: "front side",
//     value: TYPES.FRONT,
//   },
//   {
//     label: "back side",
//     value: TYPES.BACK,
//   },
// ];
//
// export const EditRejectedApparel = () => {
//   const history = useHistory();
//   const params = useParams();
//   const rejectedProducts = useSelector(
//     // (state) => state.productsReducer.rejectedProducts
//     (state) => state.productsReducer.inactiveProducts
//   );
//   const dispatch = useDispatch();
//   useEffect(() => {
//     // dispatch(getRejectedProducts());
//     dispatch(getInactiveProducts());
//   }, [dispatch]);
//
//   const [formValues, setFormValues] = useState({
//     webkul_collection_id: "",
//     productName: "",
//     productDescription: "",
//     category: "",
//     subCategory: "",
//     price: "",
//     sizes: {
//       XS: false,
//       S: false,
//       M: false,
//       L: false,
//       XL: false,
//       XXL: false,
//     },
//     images: [],
//   });
//
//   // useEffect(() => {
//   //
//   //   dispatch(getTemplateById(params.id));
//   // }, [dispatch, params.id]);
//
//   const template = rejectedProducts?.find(
//     (product) => product._id === params.id
//   );
//
//   // useSelector((state) => state.templatesReducer.template);
//   // const template = templates.filter((t) => t._id === templateId);
//
//   // console.log("template!!!!!!!!", template);
//
//   useEffect(() => {
//     // console.log("template!!!!!!!!", template);
//     console.log("templateEEEEEEEEEEEEEEEEEEEE", template);
//     setFormValues({
//       webkul_collection_id: template?.template.webkul_collection_id,
//       productName: template?.name,
//       productDescription: template?.description,
//       category: "",
//       subCategory: "",
//       price: template?.price,
//       sizes: transformSizes(template?.template.sizes),
//       images: [],
//     });
//   }, [template]);
//
//   // console.log("template", template);
//   // console.log("template name", template.name);
//
//   const formData = new FormData();
//   ////modal
//   const [open, setOpen] = useState(false);
//   ////// Previews
//   const [previews, setPreviews] = useState([]);
//   //checkboxes
//   const [chosenTypes, setChosenTypes] = useState({});
//   const [globalError, setGlobalError] = useState("");
//
//   const transformSizes = (sizes = []) => {
//     let tmpSizes = {
//       XS: false,
//       S: false,
//       M: false,
//       L: false,
//       XL: false,
//       XXL: false,
//     };
//     sizes.forEach((s) => {
//       tmpSizes[s] = true;
//     });
//     return tmpSizes;
//   };
//   const collectionToNumber = (collectionId) => {
//     console.log("collectionId", collectionId);
//     return collectionId.toNumber();
//   };
//
//   console.log("formValues", formValues);
//   const [sidesError, setSidesError] = useState("");
//   const openModal = () => {
//     setPreviews([]);
//     setOpen(true);
//   };
//   const closeModal = () => {
//     setOpen(false);
//   };
//
//   const handleSave = (images) => {
//     const allImages = [...formValues.images, ...images];
//     if (allImages.length <= 2) {
//       allImages.forEach((image) => {
//         previewFile(image);
//       });
//       setFormValues((prevState) => ({
//         ...prevState,
//         images: [...prevState.images, ...images],
//       }));
//       if (images.length) resetFieldError("images");
//       closeModal();
//       setGlobalError("");
//       setChosenTypes((prevState) => ({}));
//       if (allImages.length === 2) {
//         setGlobalError("");
//       } else setGlobalError("You can upload one more image");
//     } else {
//       formValues.images.forEach((image) => {
//         previewFile(image);
//       });
//       setFormValues((prevState) => ({
//         ...prevState,
//         images: [...prevState.images],
//       }));
//       if (images.length) resetFieldError("images");
//       closeModal();
//       setGlobalError("");
//       setChosenTypes((prevState) => ({}));
//     }
//   };
//
//   function previewFile(file) {
//     const reader = new FileReader();
//     reader.addEventListener(
//       "load",
//       function () {
//         setPreviews((previews) => {
//           return [
//             ...previews,
//             {
//               imgSrc: reader.result,
//               name: file.name,
//               type: null,
//               key: uuid(),
//             },
//           ];
//         });
//       },
//       false
//     );
//     reader.readAsDataURL(file);
//   }
//
//   ////// CATEGORIES
//
//   const categories = useSelector((state) => state.categoriesReducer.categories);
//   const rootCategories = categories.filter((c) => !Boolean(c.parent));
//   const getCategoryByName = (name) => categories.find((c) => c.name === name);
//   const hasSubCategories = (name) => {
//     const foundCategory = getCategoryByName(name);
//     if (foundCategory) {
//       return categories.some((c) => c.parent && c.parent === foundCategory._id);
//     }
//     return false;
//   };
//
//   const getSubCategories = (name) => {
//     const foundCategory = getCategoryByName(name);
//     if (foundCategory) {
//       return categories.filter(
//         (c) => c.parent && c.parent === foundCategory._id
//       );
//     }
//     return [];
//   };
//
//   const collections = useSelector(
//     (state) => state.collectionsReducer.collections
//   );
//
//   useEffect(() => {
//     dispatch(getAllCategories());
//   }, [dispatch]);
//
//   useEffect(() => {
//     const templateGroupId =
//       Object.values(TemplateGroup).includes(template?.template_group) &&
//       template?.template_group;
//     dispatch(getAllCollections(templateGroupId));
//   }, [dispatch, template]);
//
//   const [errors, setErrors] = useState({});
//
//   const handleChangeInput = (e) => {
//     const { name, value } = e.target;
//
//     const values = { ...formValues, [name]: value };
//
//     if (errors[name]) resetFieldError(name);
//
//     setFormValues(values);
//   };
//
//   const handleChangeSizeCheckbox = (e) => {
//     const { name, checked } = e.target;
//     if (errors?.sizes) resetFieldError("sizes");
//     setFormValues({
//       ...formValues,
//       sizes: { ...formValues.sizes, [name]: checked },
//     });
//   };
//
//   const handleDeletePreview = (preview) => {
//     setPreviews((prevState) => {
//       if (prevState.length === 2) {
//         setGlobalError("You can upload one more image");
//       } else setGlobalError("");
//       return prevState.filter((p) => p.key !== preview.key);
//     });
//   };
//
//   const onUpdatePreview = (preview) => {
//     const idx = previews.findIndex(({ key }) => key === preview.key);
//
//     const np = [...previews];
//     np[idx] = preview;
//
//     setPreviews(np);
//   };
//
//   const validateField = (field) => {
//     switch (field) {
//       case "price":
//         return formValues[field] < 0;
//       case "sizes":
//         return !Object.values(formValues[field]).some((size) => size);
//       case "images":
//         return !formValues[field].length;
//       case "subCategory":
//         return;
//       default:
//         return !Boolean(formValues[field]);
//     }
//   };
//
//   const resetFieldError = (field) => {
//     setErrors({ ...errors, [field]: false });
//   };
//
//   const isFormValid = () => {
//     const errors = {};
//     for (const field in formValues) {
//       errors[field] = validateField(field);
//     }
//
//     setErrors(errors);
//     return Object.values(errors).every((e) => !e);
//   };
//
//   const submitNewTemplate = () => {
//     const {
//       webkul_collection_id,
//       productName,
//       productDescription,
//       category,
//       subCategory,
//       price,
//       sizes,
//     } = formValues;
//
//     formData.append("webkul_collection_id", webkul_collection_id);
//     formData.append("name", productName);
//     formData.append("description", productDescription);
//     formData.append(
//       "category",
//       subCategory
//         ? getCategoryByName(subCategory)?._id
//         : getCategoryByName(category)?._id
//     );
//     formData.append(
//       "template_group",
//       template?.template_group || TemplateGroup.APPAREL
//     );
//     formData.append("manufacturing_cost", price);
//
//     for (const { type, coords, name } of previews) {
//       formData.append(`${type}_data`, JSON.stringify(coords));
//
//       formData.append(
//         type,
//         formValues.images.find((img) => img.name === name)
//       );
//     }
//
//     Object.entries(sizes).forEach(
//       ([s, isChecked]) => isChecked && formData.append("sizes[]", s)
//     );
//     // for (var value of formData.values()) {
//     //   console.log(value);
//     // }
//
//     // dispatch(editApparelTemplate(params.id, formData, history));
//   };
//
//   const handleFormSubmit = () => {
//     const checkTypesOfPreviews = previews.filter((preview) => preview.type)
//       .length;
//     const checkCoordsOfPreviews = previews.filter((preview) => preview.coords)
//       .length;
//     if (!isFormValid()) {
//       return;
//     }
//     if (checkTypesOfPreviews !== previews.length) {
//       setSidesError(
//         "Each uploaded image should have either Front or Back side” selected."
//       );
//       return;
//     } else if (checkCoordsOfPreviews !== previews.length) {
//       setSidesError("Each uploaded image should have selected area.");
//       return;
//     }
//     setSidesError("");
//     submitNewTemplate();
//   };
//
//   const {
//     webkul_collection_id,
//     productName,
//     productDescription,
//     category,
//     subCategory,
//     price,
//     sizes,
//   } = formValues;
//
//   const isDisabled = previews.length === 2;
//
//   return (
//     <div className="create-template">
//       <h2>Edit Apparel Template</h2>
//
//       <form autoComplete="off">
//         <p>Webkul Categories</p>
//         <TextField
//           required
//           select
//           name={"webkul_collection_id"}
//           value={+webkul_collection_id}
//           onChange={handleChangeInput}
//           id="outlined-basic"
//           label="Choose Category"
//           variant="outlined"
//           type="text"
//           error={errors?.webkul_collection_id}
//           helperText={errors?.webkul_collection_id && "Field is required"}
//         >
//           {collections.map((collection, index) => (
//             <MenuItem key={index} value={collection.id}>
//               {collection.category_name}
//             </MenuItem>
//           ))}
//         </TextField>
//         <p>Product Name</p>
//         <div className="create-template__input-wrapper">
//           <TextField
//             required
//             fullWidth
//             name={"productName"}
//             value={productName}
//             onChange={handleChangeInput}
//             id="outlined-basic"
//             label="Product Name"
//             variant="outlined"
//             type="text"
//             error={errors?.productName}
//             helperText={errors?.productName && "Field is required"}
//           />
//         </div>
//         <p>Product description</p>
//         <div className="create-template__input-wrapper">
//           <TextField
//             fullWidth
//             name={"productDescription"}
//             value={productDescription}
//             onChange={handleChangeInput}
//             required
//             id="outlined-basic"
//             label="Product description"
//             variant="outlined"
//             type="text"
//             multiline
//             rows={4}
//             error={errors?.productDescription}
//             helperText={errors?.productDescription && "Field is required"}
//           />
//         </div>
//         <p>Categories</p>
//         <TextField
//           required
//           select
//           id="outlined-basic"
//           label="Choose category"
//           variant="filled"
//           type="text"
//           name={"category"}
//           value={category}
//           onChange={handleChangeInput}
//           className="select-category"
//           error={errors?.category}
//           helperText={errors?.category && "Field is required"}
//         >
//           {rootCategories.map((category, index) => (
//             <MenuItem key={index} value={category.name}>
//               {category.name}
//             </MenuItem>
//           ))}
//         </TextField>
//         {hasSubCategories(category) ? (
//           <TextField
//             required={hasSubCategories(category)}
//             select
//             id="outlined-basic"
//             label="Choose subcategory"
//             variant="filled"
//             type="text"
//             name={"subCategory"}
//             value={subCategory}
//             onChange={handleChangeInput}
//             className="select-category"
//             error={errors?.subCategory}
//             helperText={errors?.subCategory && "Field is required"}
//           >
//             {getSubCategories(category).map((category, index) => (
//               <MenuItem key={index} value={category.name}>
//                 {category.name}
//               </MenuItem>
//             ))}
//           </TextField>
//         ) : (
//           category && (
//             <div style={{ margin: "0 30px 0 30px" }}>
//               <Alert severity="warning">
//                 This category doesn't have subcategories!
//               </Alert>
//             </div>
//           )
//         )}
//         {/*{template?.template.sizes && (*/}
//         {/*  <>*/}
//         <p>Size</p>
//         <div>
//           <FormGroup row>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.XS}
//                   onChange={handleChangeSizeCheckbox}
//                   name="XS"
//                 />
//               }
//               label="XS"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.S}
//                   onChange={handleChangeSizeCheckbox}
//                   name="S"
//                 />
//               }
//               label="S"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.M}
//                   onChange={handleChangeSizeCheckbox}
//                   name="M"
//                 />
//               }
//               label="M"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.L}
//                   onChange={handleChangeSizeCheckbox}
//                   name="L"
//                 />
//               }
//               label="L"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.XL}
//                   onChange={handleChangeSizeCheckbox}
//                   name="XL"
//                 />
//               }
//               label="XL"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={sizes.XXL}
//                   onChange={handleChangeSizeCheckbox}
//                   name="XXL"
//                 />
//               }
//               label="XXL"
//             />
//           </FormGroup>
//           {errors?.sizes && <Alert severity="error">Please check size</Alert>}
//         </div>
//         {/*  </>*/}
//         {/*)}*/}
//         <p>Product images with design</p>
//         <div className="moderate-product__images">
//           {template?.images.map((image, index) => (
//             <ImagePreview
//               key={index}
//               withBorder
//               src={image.path}
//               // handleClick={() => openImage(image.path)}
//             />
//           ))}
//         </div>
//         {/*<p>Product Images</p>*/}
//
//         <Button
//           variant="contained"
//           color="secondary"
//           style={isDisabled ? {} : { backgroundColor: "black" }}
//           disabled={!!isDisabled}
//           startIcon={<CloudUploadIcon />}
//           onClick={openModal}
//         >
//           Upload Images
//         </Button>
//
//         {globalError && <div className={"error"}>{globalError}</div>}
//         <div className="create-template__img-container">
//           <div className="create-template__images">
//             {previews.map((preview, index) => (
//               <div key={`${index}-previewsItem`} className="mapped-images">
//                 <ImagesConfig
//                   preview={preview}
//                   onUpdatePreview={onUpdatePreview}
//                   handleDelete={handleDeletePreview}
//                   checkboxOptions={CHECKBOX_OPTIONS}
//                   chosenTypes={chosenTypes}
//                   setChosenTypes={setChosenTypes}
//                 />
//                 {!preview?.coords && (
//                   <Alert severity="error">
//                     Please select the region for print!
//                   </Alert>
//                 )}
//               </div>
//             ))}
//           </div>
//           <DropzoneDialog
//             filesLimit={2}
//             open={open}
//             onSave={handleSave}
//             onDrop={() => {
//               setPreviews([]);
//             }}
//             acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
//             showPreviews={true}
//             maxFileSize={5000000}
//             onClose={closeModal}
//           />
//           {errors?.images && (
//             <Alert severity="error">Please select at least one picture!</Alert>
//           )}
//         </div>
//         {sidesError && <div className={"error"}>{sidesError}</div>}
//         {/*===========================================================================================*/}
//         <div className="create-template__price">
//           <p>Manufacturing cost:</p>
//           <TextField
//             required
//             name={"price"}
//             value={+price}
//             onChange={handleChangeInput}
//             inputProps={{ min: 0 }}
//             id="outlined-basic"
//             label="MANUFACTURING COST"
//             variant="outlined"
//             type="number"
//             error={errors?.price}
//             helperText={
//               errors?.price && "Field is required , price can't be negative"
//             }
//           />
//         </div>
//       </form>
//       <div className="create-template__buttons">
//         {/*<Button*/}
//         {/*  variant="contained"*/}
//         {/*  color="secondary"*/}
//         {/*  style={{ backgroundColor: "#26c4ff", width: 300 }}*/}
//         {/*  onClick={handleFormSubmit}*/}
//         {/*>*/}
//         {/*  Create Template*/}
//         {/*</Button>*/}
//         <ButtonCreate
//           text="save changes"
//           handleClick={handleFormSubmit}
//           width={300}
//         />
//         <ButtonCreate
//           text="cancel"
//           handleClick={() => history.push("/")}
//           width={300}
//         />
//       </div>
//     </div>
//   );
// };
