import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

let Promise = window.Promise;
if (!Promise) {
  Promise = JSZip.external.Promise;
}

const urlToPromise = (url) => {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const getZipFile = async (urls) => {
  const zip = new JSZip();

  urls.forEach((url) => {
    const filename = url.replace(/.*\//g, "");
    zip.file(filename, urlToPromise(url), { binary: true });
  });

  zip
    .generateAsync({ type: "blob" }, function updateCallback(metadata) {
      let msg = "progression : " + metadata.percent.toFixed(2) + " %";
      if (metadata.currentFile) {
        msg += ", current file = " + metadata.currentFile;
      }
      // showMessage(msg);
      // updatePercent(metadata.percent|0);
    })
    .then(
      function callback(blob) {
        // see FileSaver.js
        saveAs(blob, "playList.zip");

        // showMessage("done !");
      },
      function (e) {
        // showError(e);
      }
    );

  return false;
};
