import React from "react";
import "./styles.scss";

export const FileUploader = ({
  isDisabled = false,
  title,
  setFiles,
  onImageLoaded,
  onSetFiles,
  multiple,
}) => {
  const generatedId = title ? `image-file-${title}` : "image-file";
  const handleInputChange = (e) => {
    if (e.target.files.length > 0) {
      if (!multiple) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        if (setFiles) {
          setFiles((files) => [...files, file]);
        } else if (onSetFiles) {
          onSetFiles(file);
        }
        if (onImageLoaded) onImageLoaded(url);
      } else {
        const files = Object.values(e.target.files);
        if (files.length) {
          if (onSetFiles) onSetFiles(files);
        }
      }
      document.getElementById(generatedId).value = "";
    }
  };

  return (
    <div className={isDisabled ? "uploader__disabled" : "uploader"}>
      <label htmlFor={generatedId}>{title || "Upload File"}</label>
      <input
        disabled={isDisabled}
        type="file"
        id={generatedId}
        onChange={handleInputChange}
        multiple={multiple}
      />
    </div>
  );
};
