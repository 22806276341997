import React, { useEffect, useState } from "react";
import { ManageTitle } from "../../components/ManageTitle";
import "./style.scss";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUsersInfo } from "../../redux/users/usersActions";
import { VendorTab } from "./components/VendorTab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    flexGrow: 1,
    backgroundColor: "#f8f9fa",
  },
}));

export const VendorsPage = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const users = useSelector((state) => state.usersReducer.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersInfo());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={"vendors_page"}>
      <ManageTitle title="Vendors" />
      <div className="vendors_page_content">
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="vendors tabs"
            >
              <Tab label="Just Registered" {...a11yProps(0)} />
              <Tab label="Integrated with Webkul" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <VendorTab
              users={users.filter((user) => !user.webkul_seller_id)}
              showIntegratedButton
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VendorTab users={users.filter((user) => user.webkul_seller_id)} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
