import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollections } from "../../../redux/collections/collectionsActions";
import { getAllCategories } from "../../../redux/categories/categoriesActions";
import {
  editApparelTemplate,
  getTemplateById,
} from "../../../redux/templates/templatesActions";
import { DropzoneDialog } from "material-ui-dropzone";
import "./style.scss";
import { ButtonCreate } from "../../../components/ButtonCreate";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Alert from "@material-ui/lab/Alert";
import { ImagesConfig } from "../../../superAdminPages/CreateTemplateClothing/ImagesConfig";
import { TemplateGroup } from "../../../utils/templateGroup";
import {
  editRejected,
  getRejectedProducts,
  ProductType,
} from "../../../redux/products/productsActions";
import { ImagePreview } from "../../../components/ImagePreview";

const uuid = () => "_" + Math.random().toString(36).substr(2, 9);

export const TYPES = {
  FRONT: "front",
  BACK: "back",
};

const CHECKBOX_OPTIONS = [
  {
    label: "front side",
    value: TYPES.FRONT,
  },
  {
    label: "back side",
    value: TYPES.BACK,
  },
];

export const EditRejectedAccessories = () => {
  const history = useHistory();
  const params = useParams();
  const rejectedProducts = useSelector(
    (state) => state.productsReducer.rejectedProducts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRejectedProducts());
  }, [dispatch]);

  const [formValues, setFormValues] = useState({
    webkul_collection_id: "",
    productName: "",
    productDescription: "",
    category: "",
    subCategory: "",
    price: "",
    sizes: {
      XS: false,
      S: false,
      M: false,
      L: false,
      XL: false,
      XXL: false,
    },
    images: [],
  });

  // useEffect(() => {
  //
  //   dispatch(getTemplateById(params.id));
  // }, [dispatch, params.id]);

  const product = rejectedProducts?.find(
    (product) => product._id === params.id
  );

  // useSelector((state) => state.templatesReducer.template);
  // const template = templates.filter((t) => t._id === templateId);

  // console.log("template!!!!!!!!", template);

  useEffect(() => {
    // console.log("template!!!!!!!!", template);
    console.log("templateEEEEEEEEEEEEEEEEEEEE", product);
    setFormValues({
      webkul_collection_id: product?.template.webkul_collection_id,
      productName: product?.name,
      productDescription: product?.description,
      category: "",
      subCategory: "",
      price: product?.price,
      sizes: transformSizes(product?.sizes),
      images: [],
    });
  }, [product]);

  // console.log("template", template);
  // console.log("template name", template.name);

  const formData = new FormData();
  ////modal
  const [open, setOpen] = useState(false);
  ////// Previews
  const [previews, setPreviews] = useState([]);
  //checkboxes
  const [chosenTypes, setChosenTypes] = useState({});
  const [globalError, setGlobalError] = useState("");

  const transformSizes = (sizes = []) => {
    let tmpSizes = {
      XS: false,
      S: false,
      M: false,
      L: false,
      XL: false,
      XXL: false,
    };
    sizes.forEach((s) => {
      tmpSizes[s] = true;
    });
    return tmpSizes;
  };
  const collectionToNumber = (collectionId) => {
    console.log("collectionId", collectionId);
    return collectionId.toNumber();
  };

  console.log("formValues", formValues);
  const [sidesError, setSidesError] = useState("");
  const openModal = () => {
    setPreviews([]);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const handleSave = (images) => {
    const allImages = [...formValues.images, ...images];
    if (allImages.length <= 2) {
      allImages.forEach((image) => {
        previewFile(image);
      });
      setFormValues((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...images],
      }));
      if (images.length) resetFieldError("images");
      closeModal();
      setGlobalError("");
      setChosenTypes((prevState) => ({}));
      if (allImages.length === 2) {
        setGlobalError("");
      } else setGlobalError("You can upload one more image");
    } else {
      formValues.images.forEach((image) => {
        previewFile(image);
      });
      setFormValues((prevState) => ({
        ...prevState,
        images: [...prevState.images],
      }));
      if (images.length) resetFieldError("images");
      closeModal();
      setGlobalError("");
      setChosenTypes((prevState) => ({}));
    }
  };

  function previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setPreviews((previews) => {
          return [
            ...previews,
            {
              imgSrc: reader.result,
              name: file.name,
              type: null,
              key: uuid(),
            },
          ];
        });
      },
      false
    );
    reader.readAsDataURL(file);
  }

  ////// CATEGORIES

  const categories = useSelector((state) => state.categoriesReducer.categories);
  const rootCategories = categories.filter((c) => !Boolean(c.parent));
  const getCategoryByName = (name) => categories.find((c) => c.name === name);
  const hasSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.some((c) => c.parent && c.parent === foundCategory._id);
    }
    return false;
  };

  const getSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.filter(
        (c) => c.parent && c.parent === foundCategory._id
      );
    }
    return [];
  };

  const collections = useSelector(
    (state) => state.collectionsReducer.collections
  );

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    const templateGroupId =
      Object.values(TemplateGroup).includes(product?.template_group) &&
      product?.template_group;
    dispatch(getAllCollections(templateGroupId));
  }, [dispatch, product]);

  const [errors, setErrors] = useState({});

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    const values = { ...formValues, [name]: value };

    if (errors[name]) resetFieldError(name);

    setFormValues(values);
  };

  const handleChangeSizeCheckbox = (e) => {
    const { name, checked } = e.target;
    if (errors?.sizes) resetFieldError("sizes");
    setFormValues({
      ...formValues,
      sizes: { ...formValues.sizes, [name]: checked },
    });
  };

  const handleDeletePreview = (preview) => {
    setPreviews((prevState) => {
      if (prevState.length === 2) {
        setGlobalError("You can upload one more image");
      } else setGlobalError("");
      return prevState.filter((p) => p.key !== preview.key);
    });
  };

  const onUpdatePreview = (preview) => {
    const idx = previews.findIndex(({ key }) => key === preview.key);

    const np = [...previews];
    np[idx] = preview;

    setPreviews(np);
  };

  const validateField = (field) => {
    switch (field) {
      case "price":
        return formValues[field] < 0;
      case "sizes":
        return !Object.values(formValues[field]).some((size) => size);
      case "images":
        return !formValues[field].length;
      case "subCategory":
        return;
      default:
        return !Boolean(formValues[field]);
    }
  };

  const resetFieldError = (field) => {
    setErrors({ ...errors, [field]: false });
  };

  const isFormValid = () => {
    const errors = {};
    for (const field in formValues) {
      errors[field] = validateField(field);
    }

    setErrors(errors);
    return Object.values(errors).every((e) => !e);
  };

  const submitNewTemplate = () => {
    const {
      webkul_collection_id,
      productName,
      productDescription,
      category,
      subCategory,
      price,
      sizes,
    } = formValues;

    formData.append("webkul_collection_id", webkul_collection_id);
    formData.append("name", productName);
    formData.append("description", productDescription);
    formData.append(
      "category",
      subCategory
        ? getCategoryByName(subCategory)?._id
        : getCategoryByName(category)?._id
    );
    formData.append(
      "template_group",
      product?.template_group || TemplateGroup.ACCESSORIES
    );
    formData.append("manufacturing_cost", price);

    for (const { type, coords, name } of previews) {
      formData.append(`${type}_data`, JSON.stringify(coords));

      formData.append(
        type,
        formValues.images.find((img) => img.name === name)
      );
    }

    Object.entries(sizes).forEach(
      ([s, isChecked]) => isChecked && formData.append("sizes[]", s)
    );
    dispatch(editRejected(formData, product._id, ProductType.ACCESSORIES));
  };

  const handleFormSubmit = () => {
    const checkTypesOfPreviews = previews.filter((preview) => preview.type)
      .length;
    const checkCoordsOfPreviews = previews.filter((preview) => preview.coords)
      .length;
    if (!isFormValid()) {
      return;
    }
    if (checkTypesOfPreviews !== previews.length) {
      setSidesError(
        "Each uploaded image should have either Front or Back side” selected."
      );
      return;
    } else if (checkCoordsOfPreviews !== previews.length) {
      setSidesError("Each uploaded image should have selected area.");
      return;
    }
    setSidesError("");
    submitNewTemplate();
  };

  const {
    webkul_collection_id,
    productName,
    productDescription,
    category,
    subCategory,
    price,
    sizes,
  } = formValues;

  const isDisabled = previews.length === 2;

  return (
    <div className="create-template">
      <h2>Edit Apparel Template</h2>

      <form autoComplete="off">
        <p>Webkul Categories</p>
        <TextField
          required
          select
          name={"webkul_collection_id"}
          value={+webkul_collection_id}
          onChange={handleChangeInput}
          id="outlined-basic"
          label="Choose Category"
          variant="outlined"
          type="text"
          error={errors?.webkul_collection_id}
          helperText={errors?.webkul_collection_id && "Field is required"}
        >
          {collections.map((collection, index) => (
            <MenuItem key={index} value={collection.id}>
              {collection.category_name}
            </MenuItem>
          ))}
        </TextField>
        <p>Product Name</p>
        <div className="create-template__input-wrapper">
          <TextField
            required
            fullWidth
            name={"productName"}
            value={productName}
            onChange={handleChangeInput}
            id="outlined-basic"
            label="Product Name"
            variant="outlined"
            type="text"
            error={errors?.productName}
            helperText={errors?.productName && "Field is required"}
          />
        </div>
        <p>Product description</p>
        <div className="create-template__input-wrapper">
          <TextField
            fullWidth
            name={"productDescription"}
            value={productDescription}
            onChange={handleChangeInput}
            required
            id="outlined-basic"
            label="Product description"
            variant="outlined"
            type="text"
            multiline
            rows={4}
            error={errors?.productDescription}
            helperText={errors?.productDescription && "Field is required"}
          />
        </div>
        <p>Categories</p>
        <TextField
          required
          select
          id="outlined-basic"
          label="Choose category"
          variant="filled"
          type="text"
          name={"category"}
          value={category}
          onChange={handleChangeInput}
          className="select-category"
          error={errors?.category}
          helperText={errors?.category && "Field is required"}
        >
          {rootCategories.map((category, index) => (
            <MenuItem key={index} value={category.name}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        {hasSubCategories(category) ? (
          <TextField
            required={hasSubCategories(category)}
            select
            id="outlined-basic"
            label="Choose subcategory"
            variant="filled"
            type="text"
            name={"subCategory"}
            value={subCategory}
            onChange={handleChangeInput}
            className="select-category"
            error={errors?.subCategory}
            helperText={errors?.subCategory && "Field is required"}
          >
            {getSubCategories(category).map((category, index) => (
              <MenuItem key={index} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          category && (
            <div style={{ margin: "0 30px 0 30px" }}>
              <Alert severity="warning">
                This category doesn't have subcategories!
              </Alert>
            </div>
          )
        )}
        {product?.sizes && (
          <>
            <p>Size</p>
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.XS}
                      onChange={handleChangeSizeCheckbox}
                      name="XS"
                    />
                  }
                  label="XS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.S}
                      onChange={handleChangeSizeCheckbox}
                      name="S"
                    />
                  }
                  label="S"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.M}
                      onChange={handleChangeSizeCheckbox}
                      name="M"
                    />
                  }
                  label="M"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.L}
                      onChange={handleChangeSizeCheckbox}
                      name="L"
                    />
                  }
                  label="L"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.XL}
                      onChange={handleChangeSizeCheckbox}
                      name="XL"
                    />
                  }
                  label="XL"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sizes.XXL}
                      onChange={handleChangeSizeCheckbox}
                      name="XXL"
                    />
                  }
                  label="XXL"
                />
              </FormGroup>
              {errors?.sizes && (
                <Alert severity="error">Please check size</Alert>
              )}
            </div>
          </>
        )}
        <p>Product images with design</p>
        <div className="moderate-product__images">
          {product?.images.map((image, index) => (
            <ImagePreview
              key={index}
              withBorder
              src={image.path}
              // handleClick={() => openImage(image.path)}
            />
          ))}
        </div>
        {/*<p>Product Images</p>*/}

        {/*{product?.__t === "vinyl" && <VinylImagesUpload />}*/}

        {product?.__t === "apparel" && (
          <Button
            variant="contained"
            color="secondary"
            style={isDisabled ? {} : { backgroundColor: "black" }}
            disabled={!!isDisabled}
            startIcon={<CloudUploadIcon />}
            onClick={openModal}
          >
            Upload Images
          </Button>
        )}

        {globalError && <div className={"error"}>{globalError}</div>}
        <div className="create-template__img-container">
          <div className="create-template__images">
            {previews.map((preview, index) => (
              <div key={`${index}-previewsItem`} className="mapped-images">
                <ImagesConfig
                  preview={preview}
                  onUpdatePreview={onUpdatePreview}
                  handleDelete={handleDeletePreview}
                  checkboxOptions={CHECKBOX_OPTIONS}
                  chosenTypes={chosenTypes}
                  setChosenTypes={setChosenTypes}
                />
                {!preview?.coords && (
                  <Alert severity="error">
                    Please select the region for print!
                  </Alert>
                )}
              </div>
            ))}
          </div>
          <DropzoneDialog
            filesLimit={2}
            open={open}
            onSave={handleSave}
            onDrop={() => {
              setPreviews([]);
            }}
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            showPreviews={true}
            maxFileSize={5000000}
            onClose={closeModal}
          />
          {errors?.images && (
            <Alert severity="error">Please select at least one picture!</Alert>
          )}
        </div>
        {sidesError && <div className={"error"}>{sidesError}</div>}
        {/*===========================================================================================*/}
        <div className="create-template__price">
          <p>Manufacturing cost:</p>
          <TextField
            required
            name={"price"}
            value={+price}
            onChange={handleChangeInput}
            inputProps={{ min: 0 }}
            id="outlined-basic"
            label="MANUFACTURING COST"
            variant="outlined"
            type="number"
            error={errors?.price}
            helperText={
              errors?.price && "Field is required , price can't be negative"
            }
          />
        </div>
      </form>
      <div className="create-template__buttons">
        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  color="secondary"*/}
        {/*  style={{ backgroundColor: "#26c4ff", width: 300 }}*/}
        {/*  onClick={handleFormSubmit}*/}
        {/*>*/}
        {/*  Create Template*/}
        {/*</Button>*/}
        <ButtonCreate
          text="save changes"
          handleClick={handleFormSubmit}
          width={300}
        />
        <ButtonCreate
          text="cancel"
          handleClick={() => history.push("/")}
          width={300}
        />
      </div>
    </div>
  );
};
