export const getTemplateImages = (template) => {
    const images = template && template.images ? template.images : [];
    let front = images.find((e) => e.side === "front") || {};
    let back = images.find((e) => e.side === "back") || {};
    if (front.data) {
        let data = JSON.parse(front.data);
        front = {
            ...front,
            ...data,
        };
    }
    if (back.data) {
        let data = JSON.parse(back.data);
        back = {
            ...back,
            ...data,
        };
    }
    return {
        front,
        back,
    };
}