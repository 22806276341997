import domtoimage from "dom-to-image";

function simulateClick(elem) {
  const evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  elem && elem.dispatchEvent(evt);
}

export const screenshot = (mainImage, setImages, setScreenshotSave) => {
  const node = document.getElementById("stage-node");
  const content = document.querySelector("canvas");
  simulateClick(content);
  domtoimage
    .toPng(node)
    .then(function (dataUrl) {
      setImages((prevState) => ({ ...prevState, [mainImage.side]: dataUrl }));
      setScreenshotSave(true);
    })
    .catch(function (error) {
      console.error("oops, something went wrong!", error);
    });
};
