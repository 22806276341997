import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Drawer, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { logoutMutation } from "../../redux/auth/authMutations";

import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Avatar from "@material-ui/core/Avatar";
import "./styles.scss";

export const AdminNavbar = ({ links, title, setNavbarOpen, isNavbarOpen }) => {
  const history = useHistory();
  const theme = useTheme();
  const email = localStorage.getItem("email");

  const handleDrawerOpen = () => {
    setNavbarOpen(true);
  };
  const handleDrawerClose = () => {
    setNavbarOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    history.push("/login");
    logoutMutation();
  };

  return (
    <div className="sa-navbar">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        style={{
          marginLeft: -1,
          position: "fixed",
          backgroundColor: "#f8f9fa",
          zIndex: 1000,
        }}
        className={isNavbarOpen ? "drawer-btn--open" : "drawer-btn"}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className="drawer"
        variant="persistent"
        anchor="left"
        open={isNavbarOpen}
      >
        {title && <h2>{title}</h2>}
        <div className="drawer__header">
          <Tooltip
            interactive
            title={
              <div className={"tooltip"}>
                <p>{email}</p>
                <p onClick={() => handleLogout()} className={"logoutButton"}>
                  Log out
                </p>
              </div>
            }
          >
            <Avatar
              style={{ cursor: "pointer" }}
              alt="A"
              onClick={handlePopover}
            />
          </Tooltip>
          {/*<Avatar*/}
          {/*  style={{ cursor: "pointer" }}*/}
          {/*  alt="A"*/}
          {/*  onClick={handlePopover}*/}
          {/*/>*/}
          {/*<Popover*/}
          {/*  id={id}*/}
          {/*  open={open}*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  onClose={handleClose}*/}
          {/*  anchorOrigin={{*/}
          {/*    vertical: "bottom",*/}
          {/*    horizontal: "center",*/}
          {/*  }}*/}
          {/*  transformOrigin={{*/}
          {/*    vertical: "top",*/}
          {/*    horizontal: "center",*/}
          {/*  }}*/}
          {/*  style={{ margin: 0, width: 180, top: -78, left: 50 }}*/}
          {/*>*/}
          {/*  <p>{email}</p>*/}
          {/*  <p onClick={() => handleLogout()} className={"logoutButton"}>*/}
          {/*    Log out*/}
          {/*  </p>*/}
          {/*</Popover>*/}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        {links.map((link, index) => {
          return (
            <div className="sa-navbar__item" key={index}>
              <Button
                style={{ color: "black" }}
                onClick={() => history.push(link.to)}
                fullWidth
              >
                {link.name}
              </Button>
            </div>
          );
        })}
      </Drawer>
    </div>
  );
};
