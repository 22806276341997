import React, { useState } from "react";

import { Header } from "../../components/Header";
import { Navbar } from "../../components/Navbar";

import {
  navigationLinks,
  navigationSubLinks,
} from "../../components/Navbar/data";
import { navigationSubCategories } from "../../pages/CategoryPage/data";

import { Redirect } from "react-router-dom";
import "./styles.scss";

export const AuthLayout = ({ children, config }) => {
  const [isNavbarOpen, toggleNavbar] = useState(false);
  const {
    withHeader,
    withNavbarCategories,
    withNavbarSubCategories,
    withContent,
  } = config;

  const handleToggleNavbar = () => {
    toggleNavbar(!isNavbarOpen);
  };
  const isLoggedIn =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const isVendor =
    localStorage.getItem("role") === "vendor" || sessionStorage.getItem("role") === "vendor" ;

  const vendorEmail = localStorage.getItem("email")|| sessionStorage.getItem("email");
  return isLoggedIn && isVendor ? (
    <div className="auth-layout">
      {withHeader && (
        <Header
          isOpen={isNavbarOpen}
          handleClick={handleToggleNavbar}
          withNavbarCategories={withNavbarCategories}
          withNavbarSubCategories={withNavbarSubCategories}
          withContent={withContent}
          vendorEmail={vendorEmail}
        />
      )}
      {withNavbarCategories && (
        <Navbar
          title={"Products"}
          links={navigationLinks}
          subLinks={navigationSubLinks}
          isOpen={isNavbarOpen}
        />
      )}
      {withNavbarSubCategories && (
        <Navbar links={navigationSubCategories} isOpen={isNavbarOpen} />
      )}
      <div className="auth-layout__content">{children}</div>
    </div>
  ) : (
    <Redirect to="login" />
  );
};
