import {
  CREATE_NEW_SELLER,
  CREATE_NEW_SELLER_ERROR,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  SET_SUCCESS_REGISTRATION,
  IS_REJECTED_ADMIN,
  RESET_PASSWORD,
} from "./authTypes";
import { IS_CREATED_ADMIN } from "../users/usersTypes";

const initialState = {
  userData: {},
  error: null,
  successRegistration: false,
  token: localStorage.getItem("token") || sessionStorage.getItem("token") || "",
  isCreatedAdmin: false,
  isRejectedAdmin: false,
  resetPassword: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_SELLER:
      return { ...state, userData: action.data };
    case CREATE_NEW_SELLER_ERROR:
      return { ...state, error: action.message };
    case LOGIN:
      return { ...state, token: action.data.token, userData: action.data.user };
    case LOGOUT:
      return { ...state, token: "" };
    case SET_SUCCESS_REGISTRATION:
      return { ...state, successRegistration: action.data };
    case LOGIN_ERROR:
      return { ...state, error: action.data };
    case IS_CREATED_ADMIN:
      return { ...state, isCreatedAdmin: action.data };
    case IS_REJECTED_ADMIN:
      return { ...state, isRejectedAdmin: action.data };
    case RESET_PASSWORD:
      return { ...state, resetPassword: action.data };
    default:
      return state;
  }
};

export default authReducer;
