import React from "react";
import { Routes } from "./Routes";
import { Route, Switch } from "react-router-dom";
import './App.scss'

function App() {
  return (
    <div className="App">
      <Switch>
        {Routes.map((route) => {
          return (
            <Route
              exact={route.exact}
              key={route.name}
              path={route.path}
              render={(props) => (
                <route.layout {...props} config={route.config}>
                  <route.page {...props} />
                </route.layout>
              )}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default App;
