import React, { useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import "./styles.scss";
import { ImagesConfig } from "../CreateTemplateClothing/ImagesConfig";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/categories/categoriesActions";
import {TemplateGroup} from "../../utils/templateGroup";

const uuid = () => "_" + Math.random().toString(36).substr(2, 9);

export const TYPES = {
  FRONT: "front",
  BACK: "back",
  ASIDE: "aside",
  BSIDE: "bside",
  CSIDE: "cside",
  DSIDE: "dside",
};

const CHECKBOX_OPTIONS = [
  {
    label: "front side",
    value: TYPES.FRONT,
  },
  {
    label: "back side",
    value: TYPES.BACK,
  },
  {
    label: "A side",
    value: TYPES.ASIDE,
  },
  {
    label: "B side",
    value: TYPES.BSIDE,
  },
  {
    label: "C side",
    value: TYPES.CSIDE,
  },
  {
    label: "D side",
    value: TYPES.DSIDE,
  },
];

export const CreateTemplateVinyl = () => {
  const [previews, setPreviews] = useState([]);
  const [chosenTypes, setChosenTypes] = useState({});
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    styleNumber: "",
    category: "",
    subCategory: "",
    productName: "",
    productDescription: "",
    rpm: {
      33: false,
      45: false,
      78: false,
    },
    price: "",
    images: [],
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    const values = { ...formValues, [name]: value };

    if (errors[name]) resetFieldError(name);

    setFormValues(values);
  };

  const handleChangeRpmCheckbox = (e) => {
    const { name, checked } = e.target;
    if (errors?.rpm) resetFieldError("rpm");
    setFormValues({
      ...formValues,
      rpm: { ...formValues.rpm, [name]: checked },
    });
  };

  //сброс ошибки при вводе данных
  const resetFieldError = (name) => {
    setErrors({ ...errors, [name]: false });
  };

  const handleFormSubmit = () => {
    if (!isFormValid()) {
      console.log("form is not valid");
      return;
    }
    submitNewTemplate();
  };

  const validateField = (field) => {
    switch (field) {
      case "rpm":
        return !Object.values(formValues[field]).some((rpm) => rpm);
      case "images":
        return !formValues[field].length;
      default:
        // дефолт - любой текст (значение) -  есть ли данные ?
        return !Boolean(formValues[field]);
    }
  };

  const isFormValid = () => {
    const errors = {};
    for (const field in formValues) {
      // записываем в errors поле name
      errors[field] = validateField(field);
    }

    setErrors(errors);
    return Object.values(errors).every((e) => !e);
  };

  const submitNewTemplate = () => {
    const formData = new FormData();
    const { styleNumber } = formValues;
    formData.append("style_number", styleNumber);
    formData.append(
      "category",
      subCategory
        ? getCategoryByName(subCategory)?._id
        : getCategoryByName(category)?._id
    );
    formData.append("name", productName);
    formData.append("description", productDescription);

    formData.append("template_group", TemplateGroup.AUDIO);
    formData.append("manufacturing_cost", price);
  };

  const openImagesUploadModal = () => {
    setOpenUploadModal(true);
  };

  const closeModal = () => {
    setOpenUploadModal(false);
  };

  const handleSave = (images) => {
    images.forEach((image) => {
      previewFile(image);
    });
    setFormValues({ ...formValues, images });
    if (images.length) resetFieldError("images");
    closeModal();
  };
  function previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setPreviews((previews) => {
          return [
            ...previews,
            {
              imgSrc: reader.result,
              name: file.name,
              type: null,
              key: uuid(),
            },
          ];
        });
      },
      false
    );
    reader.readAsDataURL(file);
  }
  const onUpdatePreview = (preview) => {
    const idx = previews.findIndex(({ key }) => key === preview.key);

    const np = [...previews];
    np[idx] = preview;

    setPreviews(np);
  };

  const handleDeletePreview = (preview) => {
    setPreviews(previews.filter((p) => p.key !== preview.key));
  };

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const rootCategories = categories.filter((c) => !Boolean(c.parent));
  const getCategoryByName = (name) => categories.find((c) => c.name === name);
  const hasSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.some((c) => c.parent && c.parent === foundCategory._id);
    }
    return false;
  };

  const getSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.filter(
        (c) => c.parent && c.parent === foundCategory._id
      );
    }
    return [];
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const {
    styleNumber,
    category,
    subCategory,
    productName,
    productDescription,
    rpm,
    price,
  } = formValues;

  return (
    <div className="create-template-vinyl">
      <h2>Create Vinyl template</h2>
      <form autoComplete="off">
        <p>Style Number</p>
        <TextField
          required
          name={"styleNumber"}
          value={styleNumber}
          onChange={handleChangeInput}
          label="Style Number"
          variant="outlined"
          type="number"
          error={errors?.styleNumber}
          helperText={errors?.styleNumber && "Field is required"}
        />
        <p>Categories</p>
        <TextField
          style={{ width: 300 }}
          required
          select
          label="Choose category"
          variant="filled"
          type="text"
          name={"category"}
          value={category}
          onChange={handleChangeInput}
          error={errors?.category}
          helperText={errors?.category && "Field is required"}
        >
          {rootCategories.map((category, index) => (
            <MenuItem key={index} value={category.name}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        {hasSubCategories(category) ? (
          <TextField
            style={{ width: 300 }}
            required={hasSubCategories(category)}
            select
            id="outlined-basic"
            label="Choose subcategory"
            variant="filled"
            type="text"
            name={"subCategory"}
            value={subCategory}
            onChange={handleChangeInput}
            error={errors?.subCategory}
            helperText={errors?.subCategory && "Field is required"}
          >
            {getSubCategories(category).map((category, index) => (
              <MenuItem key={index} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          category && (
            <div style={{ margin: "0 30px 0 30px" }}>
              <Alert severity="error">
                This category doesn't have subcategories!
              </Alert>
            </div>
          )
        )}
        <p>Product Name</p>
        <TextField
          style={{ width: 300 }}
          required
          fullWidth
          name={"productName"}
          value={productName}
          onChange={handleChangeInput}
          id="outlined-basic"
          label="Product Name"
          variant="outlined"
          type="text"
          error={errors?.productName}
          helperText={errors?.productName && "Field is required"}
        />
        <p>Product description</p>
        <TextField
          rowsMax={6}
          style={{ width: 300 }}
          fullWidth
          name={"productDescription"}
          value={productDescription}
          onChange={handleChangeInput}
          required
          id="outlined-basic"
          label="Product description"
          variant="outlined"
          type="text"
          multiline
          rows={4}
          error={errors?.productDescription}
          helperText={errors?.productDescription && "Field is required"}
        />
        <p>Record speed</p>
        <FormGroup row style={{ marginBottom: 20 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={rpm["33"]}
                onChange={handleChangeRpmCheckbox}
                name="33"
              />
            }
            label="33 RPM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rpm["45"]}
                onChange={handleChangeRpmCheckbox}
                name="45"
              />
            }
            label="45 RPM"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rpm["78"]}
                onChange={handleChangeRpmCheckbox}
                name="78"
              />
            }
            label="78 RPM"
          />
          {errors?.rpm && (
            <Alert severity="error">Please set record speed</Alert>
          )}
        </FormGroup>
        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "black", width: 500 }}
          startIcon={<CloudUploadIcon />}
          onClick={openImagesUploadModal}
        >
          Upload Images
        </Button>
        <div className="create-template-vinyl__images">
          {previews.map((preview, index) => (
            <div key={`${index}-previewsItem`} className="mapped-images">
              <ImagesConfig
                vinyl
                preview={preview}
                onUpdatePreview={onUpdatePreview}
                handleDelete={handleDeletePreview}
                checkboxOptions={CHECKBOX_OPTIONS}
                chosenTypes={chosenTypes}
                setChosenTypes={setChosenTypes}
              />
              {!preview?.coords && (
                <Alert severity="error">
                  Please select the region for print!
                </Alert>
              )}
            </div>
          ))}
        </div>
        {errors?.images && (
          <Alert severity="error">Please select at least one picture!</Alert>
        )}
        <div>
          <p>Manufacturing cost:</p>
          <TextField
            required
            name={"price"}
            value={price}
            onChange={handleChangeInput}
            id="outlined-basic"
            label="BASIC PRICE"
            variant="outlined"
            type="number"
            error={errors?.price}
            helperText={errors?.price && "Field is required"}
          />
        </div>
        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "#26c4ff", marginBottom: 30 }}
          onClick={handleFormSubmit}
        >
          Create Vinyl Template
        </Button>
      </form>
      <DropzoneDialog
        filesLimit={6}
        open={openUploadModal}
        onSave={handleSave}
        onDrop={() => {
          setPreviews([]);
        }}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={closeModal}
      />
    </div>
  );
};
