import React, { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./styles.scss";
import { useHistory } from "react-router-dom";

export const BackArrow = ({ arrowText, to, tab }) => {
  const history = useHistory();

  useEffect(() => {
    if (to === "/sa-moderate-new-product") {
      localStorage.setItem("sa-product-tab", tab);
    }
  }, [to, tab]);
  return (
    <div className="back-btn">
      <IconButton onClick={() => history.push(to)}>
        <ArrowBackIcon fontSize="large" style={{ color: "black" }} />{" "}
        {arrowText}
      </IconButton>
    </div>
  );
};
