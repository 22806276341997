import axios from "../../API/axios";
import { toggleIsFetching } from "../common/commonMutations";
import {
  addCategory,
  deleteCategory,
  getCategories,
  getReorderedCategories,
  renameCategory,
} from "./categoriesMutations";

export function getAllCategories() {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    axios
      .get(`/categories`)
      .then((response) => {
        dispatch(toggleIsFetching(false));
        dispatch(getCategories(response.data));
      })
      .catch((e) => {
        console.log("ERR:", e);
      });
  };
}

export function addNewCategory(name, parent = null) {
  return (dispatch) => {
    axios
      .post(`/categories`, {
        name,
        parent,
      })
      .then((response) => {
        dispatch(addCategory(response.data));
      })
      .catch((e) => {
        console.log("ERR:", e);
      });
  };
}

export function deleteCategoryById(categoryId) {
  return (dispatch) => {
    axios
      .delete(`/categories/${categoryId}`)
      .then(() => {
        dispatch(deleteCategory(categoryId));
      })
      .catch((e) => {
        console.log("error when delete", e);
      });
  };
}
export function renameCategoryById(id, newName) {
  return (dispatch) => {
    axios
      .put(`/categories/${id}`, { name: newName })
      .then((r) => {
        console.log("r", r);
        dispatch(renameCategory(r.data._id, r.data.name));
      })
      .catch((e) => {
        console.log("error when delete", e);
      });
  };
}

export function reorderCategories(data) {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    axios
      .put(`/categories`, data)
      .then((r) => {
        console.log("r", r);
        dispatch(toggleIsFetching(false));
        dispatch(getReorderedCategories(r.data));
      })
      .catch((e) => {
        console.log("error when delete", e);
      });
  };
}
