import {
  ADD_NEW_CATEGORY,
  DELETE_CATEGORY_BY_ID,
  GET_ALL_CATEGORIES,
  GET_REORDERED_CATEGORIES,
  RENAME_CATEGORY_BY_ID,
} from "./categoriesTypes";

export function addCategory(data) {
  return {
    type: ADD_NEW_CATEGORY,
    data,
  };
}

export function getCategories(data) {
  return {
    type: GET_ALL_CATEGORIES,
    data,
  };
}

export function deleteCategory(id) {
  return {
    type: DELETE_CATEGORY_BY_ID,
    id,
  };
}

export function renameCategory(id, name) {
  return {
    type: RENAME_CATEGORY_BY_ID,
    id,
    name,
  };
}

export function getReorderedCategories(data) {
  return {
    type: GET_REORDERED_CATEGORIES,
    data,
  };
}
