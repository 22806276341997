import React, { useState } from "react";
import { Button, Popover, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import "./styles.scss";

export const ButtonDelete = ({
  deleteEvent,
  hasConfirmation,
  isIconButton = true,
  buttonText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const clickPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };
  const isPopoverOpened = Boolean(anchorEl);

  return (
    <div>
      {isIconButton && (
      <IconButton
        onClick={hasConfirmation ? clickPopover : deleteEvent}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      )}
      {!isIconButton && (
      <Button
        variant="contained"
        onClick={hasConfirmation ? clickPopover : deleteEvent}
        aria-label="delete"
      >
        {buttonText}
      </Button>
      )}
      {hasConfirmation && (
        <Popover
          open={isPopoverOpened}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          }}
          transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          }}
        >
        <div className="button-delete">
          <p>Are you sure you want to delete?</p>
          <div className="button-delete__actions">
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteEvent();
                  closePopover();
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  closePopover();
                }}
              >
                No
              </Button>
          </div>
        </div>
      </Popover>
    )}
    </div>
  );
};
