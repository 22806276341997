import React from "react";
import { Header } from "../../components/Header";
import "./styles.scss";

export const notAuthorized = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="not-authorized">{children}</div>
    </div>
  );
};
