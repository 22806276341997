import React, { useEffect, useState } from "react";
import {
  getAllTemplates,
  deleteTemplateById,
} from "../../redux/templates/templatesActions";
import { getAllCategories } from "../../redux/categories/categoriesActions";
import { useDispatch, useSelector } from "react-redux";
import { ButtonDelete } from "../../components/ButtonDelete";
import { ButtonCreate } from "../../components/ButtonCreate";
import { ManageTitle } from "../../components/ManageTitle";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { IconButton, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  isEditCancel,
  isEditSuccess,
  isTemplateCreated,
  isTemplateRejected,
} from "../../redux/templates/templatesMutations";
import { Edit } from "@material-ui/icons";
import {
  setIsEditError,
  setIsEditSuccess,
} from "../../redux/products/productsMutations";

export const AllTemplates = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templatesReducer.templates);
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const isSuccess = useSelector(
    (state) => state.templatesReducer.isEditSuccess
  );
  const isCancel = useSelector((state) => state.templatesReducer.isEditCancel);

  const ifTemplateCreated = useSelector(
    (state) => state.templatesReducer.isTemplateCreated
  );
  const ifTemplateRejected = useSelector(
    (state) => state.templatesReducer.isTemplateRejected
  );
  console.log("templates", templates);
  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const getCategoryNameById = (id) => {
    const foundCategory = categories.find((c) => c._id === id);
    return foundCategory ? foundCategory.name : "Unknown";
  };

  const [isSnackOpen, setIsSnackOpen] = useState(false);

  useEffect(() => {
    if (ifTemplateCreated || ifTemplateRejected) setIsSnackOpen(true);
  }, [ifTemplateCreated, ifTemplateRejected, setIsSnackOpen]);

  useEffect(() => {
    setTimeout(() => {
      setIsSnackOpen(false);
      dispatch(isTemplateCreated(false));
      dispatch(isTemplateRejected(false));
    }, 10000);
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(isEditCancel(false));
      dispatch(isEditSuccess(false));
      return;
    }
    dispatch(isEditCancel(false));
    dispatch(isEditSuccess(false));
  };

  const getWay = (templateType) => {
    switch (templateType) {
      case "accessories":
        return "sa-edit-template-accessories";
      case "apparel":
        return "sa-edit-template-clothing";
    }
  };

  return (
    <div className="all-temp">
      {ifTemplateCreated && (
        <Snackbar
          open={isSnackOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">Template was created!</Alert>
        </Snackbar>
      )}
      {ifTemplateRejected && (
        <Snackbar
          open={isSnackOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">Template wasn't created :(</Alert>
        </Snackbar>
      )}
      {/*alert after edit template*/}
      {isSuccess && (
        <Snackbar
          open={isSuccess}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">The changes were successfully saved</Alert>
        </Snackbar>
      )}
      {isCancel && (
        <Snackbar
          open={isCancel}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="warning">The changes were cancelled</Alert>
        </Snackbar>
      )}

      <ManageTitle title="All Templates">
        {/*<ButtonCreate*/}
        {/*  handleClick={() =>*/}
        {/*    history.push("/sa-create-new-template-accessories")*/}
        {/*  }*/}
        {/*  text="Accessories"*/}
        {/*/>*/}
        <ButtonCreate
          handleClick={() => history.push("/sa-create-new-template-clothing")}
          text="Apparel Template"
          width={250}
        />
      </ManageTitle>
      <div className="all-temp__header">
        <div className="custom-row-data">
          <div className="custom-row-data__id">ID</div>
          <div className="custom-row-data__name">NAME</div>
          <div className="custom-row-data__category">CATEGORY</div>
          <div className="custom-row-data__price">PRICE</div>
          <div className="custom-row-data__edit">EDIT</div>
        </div>
      </div>
      <div className="all-temp__body">
        {templates &&
          templates.map((row, index) => {
            return (
              <div className="custom-row-product" key={index + 1}>
                <div className="custom-row-data">
                  <div className="custom-row-data__id">{index + 1}</div>
                  <div className="custom-row-data__name">{row.name}</div>
                  <div className="custom-row-data__category">
                    {getCategoryNameById(row.category)}
                  </div>
                  <div className="custom-row-data__price">
                    {row.manufacturing_cost}
                  </div>
                  <div className="custom-row-data__edit">
                    <IconButton
                      onClick={() =>
                        history.push(
                          `/${getWay(row.template_group)}/${row._id}`
                        )
                      }
                    >
                      <Edit />
                    </IconButton>
                    <ButtonDelete
                      deleteEvent={() => dispatch(deleteTemplateById(row._id))}
                      hasConfirmation
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
