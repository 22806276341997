import {
    GET_ALL_COLLECTIONS
} from './collectionsTypes'

const initialState = {
    collections: [],
};

const collectionsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_ALL_COLLECTIONS: 
        return { ...state, collections: action.data };
        default:
            return state;
    }
}

export default collectionsReducer;
