// import { Home } from "./pages/Home";
import { CategoryPage } from "./pages/CategoryPage";
import { TemplateDetails } from "./pages/TemplateDetails";
import { CustomizeProductPage } from "./pages/CustomizeProductPage";
import { Dashboard } from "./pages/Dashboard";
import { ReviewProduct } from "./pages/ReViewProduct";
import { CassetteCreate } from "./pages/CassetteCreate";
/////////////////Auth
import { SignUp } from "./authPages/SignUp";
import { LogIn } from "./authPages/LogIn";
import { SignUpSuccess } from "./authPages/SignUpSuccess";
import { ResetPasswordPage } from "./authPages/ResetPassword";
///////////////////////////////////////////////////SUPER ADMIN
import { AllTemplates } from "./superAdminPages/AllTemplates";
import { AddNewCategory } from "./superAdminPages/AddNewCategory";
import { ModerateNewProduct } from "./superAdminPages/ModerateNewProduct";
import { ManageAdminUsers } from "./superAdminPages/ManageAdminUsers";
import { ManageCurrentAdmin } from "./superAdminPages/ManageCurrentAdmin";
import { ModerateProductPage } from "./superAdminPages/ModerateProductPage";
import { ManageAddNewAdmin } from "./superAdminPages/ManageAddNewAdmin";
import { CreateTemplateClothing } from "./superAdminPages/CreateTemplateClothing";
import { CreateTemplateVinyl } from "./superAdminPages/CreateTemplateVinyl";
/////////////////////////////////////////////LAYOUTS
import { AuthLayout } from "./layouts/AuthLayout";
import { notAuthorized } from "./layouts/notAuthorized";
import { SuperAdminLayout } from "./layouts/SuperAdminLayout";
//admin
import { CdCreate } from "./pages/CdCreate";
import { VendorsPage } from "./superAdminPages/Vendors";
import { VinylCreate } from "./pages/VinylCreate";
import { CreateTemplateAccessories } from "./superAdminPages/CreateTemplateAccessories";
import { EditTemplateClothing } from "./superAdminPages/EditTemplateClothing";
// import { EditTemplate } from "./pages/EditRejectedProduct";
import { EditRejectedCd } from "./pages/EditRejectedProduct/EditRejectedCd";
import { EditRejectedVinyl } from "./pages/EditRejectedProduct/EditRejectedVinyl";
import { EditRejectedCassette } from "./pages/EditRejectedProduct/EditRejectedCassette";
import { EditRejectedAccessories } from "./pages/EditRejectedProduct/EditRejectedAccessories";
import { EditRejectedApparel } from "./pages/EditRejectedProduct/EditRejectedApparel";
import { EditTemplateAccessories } from "./superAdminPages/EditTemplateAccessories";

export const Routes = [
  //////////////////Super Admin
  {
    name: "SA-ModerateNewProduct",
    exact: true,
    path: "/sa-moderate-new-product",
    page: ModerateNewProduct,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-ModerateProductPage",
    exact: true,
    path: "/sa-moderate-product-page/:id",
    page: ModerateProductPage,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-AddNewCategory",
    exact: true,
    path: "/sa-add-new-category",
    page: AddNewCategory,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-AllTemplates",
    exact: true,
    path: "/sa-all-templates",
    page: AllTemplates,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-CreateTemplate Clothing-template",
    exact: true,
    path: "/sa-create-new-template-clothing",
    page: CreateTemplateClothing,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-CreateTemplate Clothing-template",
    exact: true,
    path: "/sa-create-new-template-accessories",
    page: CreateTemplateAccessories,
    layout: SuperAdminLayout,
  },
  {
    name: "SA - Edit Clothing-template",
    exact: true,
    path: "/sa-edit-template-clothing/:id",
    page: EditTemplateClothing,
    layout: SuperAdminLayout,
  },
  {
    name: "SA - Edit Accessories-template",
    exact: true,
    path: "/sa-edit-template-accessories/:id",
    page: EditTemplateAccessories,
    layout: SuperAdminLayout,
  },

  {
    name: "SA-CreateTemplate Vinyl-template",
    exact: true,
    path: "/sa-create-new-template-vinyl",
    page: CreateTemplateVinyl,
    layout: SuperAdminLayout,
  },
  // {
  //   name: "SA-ManageAdminUsers",
  //   exact: true,
  //   path: "/sa-manage",
  //   page: ManageAdminUsers,
  //   layout: SuperAdminLayout,
  // },
  // {
  //   name: "SA-ManageCurrentAdmin",
  //   exact: true,
  //   path: "/sa-manage-current-admin-id/:id",
  //   page: ManageCurrentAdmin,
  //   layout: SuperAdminLayout,
  // },
  {
    name: "SA-AddNewAdmin",
    exact: true,
    path: "/sa-add-new-admin-id",
    page: ManageAddNewAdmin,
    layout: SuperAdminLayout,
  },
  {
    name: "SA-Vendors",
    exact: true,
    path: "/sa-vendors",
    page: VendorsPage,
    layout: SuperAdminLayout,
  },
  //////////////Not Authorized
  {
    name: "SignUp",
    exact: true,
    path: "/sign-up",
    page: SignUp,
    layout: notAuthorized,
    config: {
      withContent: false,
    },
  },
  {
    name: "LogIn",
    exact: true,
    path: "/login",
    page: LogIn,
    layout: notAuthorized,

    config: {
      withContent: false,
    },
  },
  {
    name: "RestorePassword",
    exact: true,
    path: "/restore-password",
    page: ResetPasswordPage,
    layout: notAuthorized,

    config: {
      withContent: false,
    },
  },
  {
    name: "sign up success",
    exact: true,
    path: "/sign-up-success",
    page: SignUpSuccess,
    layout: notAuthorized,
    config: {
      withHeader: false,
    },
  },

  {
    name: "Category Page",
    exact: true,
    path: "/category/:id",
    page: CategoryPage,
    layout: AuthLayout,
    // /api/v2/collections/count.json mb-ano
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: true,
    },
  },
  {
    name: "Template Details",
    exact: true,
    path: "/template/:id",
    page: TemplateDetails,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "Customize",
    exact: true,
    path: "/customize/:id",
    page: CustomizeProductPage,
    layout: AuthLayout,
    config: {
      withHeader: false,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
    // path: `/products/productID`,
    ///api/v2/products/{productId}
  },
  {
    name: "cd-details",
    exact: true,
    path: "/cd-details",
    page: CdCreate,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "Vinyl details",
    exact: true,
    path: "/vinyl-details",
    page: VinylCreate,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "cassette-details",
    exact: true,
    path: "/cassette-details",
    page: CassetteCreate,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "Dashboard",
    exact: true,
    path: "/",
    page: Dashboard,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: true,
      withNavbarSubCategories: false,
    },
  },

  {
    name: "EditRejectedCd",
    exact: true,
    path: "/edit-rejected-cd/:id",
    page: EditRejectedCd,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "EditRejectedVinyl",
    exact: true,
    path: "/edit-rejected-vinyl/:id",
    page: EditRejectedVinyl,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "EditRejectedCassette",
    exact: true,
    path: "/edit-rejected-cassette/:id",
    page: EditRejectedCassette,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "EditRejectedApparel",
    exact: true,
    path: "/edit-rejected-apparel/:id",
    page: EditRejectedApparel,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },
  {
    name: "EditRejectedAccessories",
    exact: true,
    path: "/edit-rejected-accessories/:id",
    page: EditRejectedAccessories,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: false,
      withNavbarSubCategories: false,
    },
  },

  {
    name: "ReView",
    exact: true,
    path: "/review",
    page: ReviewProduct,
    layout: AuthLayout,
    config: {
      withHeader: true,
      withContent: true,
      withNavbarCategories: true,
      withNavbarSubCategories: false,
    },
  },
];
