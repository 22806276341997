export const navigationSubCategories = [
  {
    name: "T-shirt's",
  },
  {
    name: "Hoodies",
  },
  {
    name: "Crop-Tops",
  },
];

export const subCategoriesItems = [
  {
    name: "Tee for your wife",
    price: 1488,
    image:
      "https://cdn.shopify.com/s/files/1/2334/0805/products/96828-19VW_1024x1024.jpg?v=1573872565",
  },
  {
    name: "Hoodie with extra long name because vendor user is abuser",
    price: 228,
    image:
      "https://cdn.shopify.com/s/files/1/2334/0805/products/96828-19VW_1024x1024.jpg?v=1573872565",
  },
  {
    name: "Tee for your wife",
    price: 322,
    image:
      "https://cdn.shopify.com/s/files/1/2334/0805/products/96828-19VW_1024x1024.jpg?v=1573872565",
  },
];
