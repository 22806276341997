import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonCreate } from "../../components/ButtonCreate";
import { ImagePreview } from "../../components/ImagePreview";
import { useHistory } from "react-router-dom";
import { InfoBlock } from "./InfoBlock";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  getProduct,
  changeProductStatus,
} from "../../redux/products/productsActions";

import "./styles.scss";
import { getZipFile } from "../../utils";
import { BackArrow } from "../../components/BackArrow";
import DialogTitle from "@material-ui/core/DialogTitle";
import { webkulIntegration } from "../../redux/auth/authActions";
import {
  setApproved,
  setIsApprovedError,
} from "../../redux/products/productsMutations";
import { isCreatedAdmin } from "../../redux/users/usersMutations";

const requiredFields = ["apiKey", "secretKey", "userId"];

export const ModerateProductPage = (props) => {
  const { match } = props;
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.commonReducer.isFetching);
  const product = useSelector((state) => state.productsReducer.product);
  const approveError = useSelector(
    (state) => state.productsReducer.isApprovedError
  );

  console.log("product", product);

  useEffect(() => {
    dispatch(getProduct(match.params.id));
    return () => dispatch(setIsApprovedError(""));
  }, [dispatch, match.params.id]);

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [declineReason, setDeclineReason] = useState("");
  const [openReject, setOpenReject] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  //==================
  // const [openWebkulWarning, setOpenWebkulWarning] = useState(false);
  // const [userName, setUserName] = useState("");
  const [values, setValues] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });
  const [errors, setErrors] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });
  // const [id, setId] = useState("");
  const history = useHistory();
  const validateFields = () => {
    let approved = [];
    requiredFields.forEach((field) => {
      if (values[field] === "") {
        setErrors((prevState) => ({
          ...prevState,
          [field]: "Field is required",
        }));
        approved.push(true);
        return;
      }
      if (field === "apiKey" || field === "secretKey") {
        if (values[field].match(/[^a-zA-Z0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only a-z A-Z 0-9",
          }));
          approved.push(true);
          return;
        }
      }
      if (field === "userId") {
        if (values[field].match(/[^0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only 0-9",
          }));
          approved.push(true);
          return;
        }
        if (values[field].toString().length !== 6) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Length must be 6 digits",
          }));
          approved.push(true);
          return;
        }
      }
      approved.push(false);
    });
    console.log("approved", approved);
    return approved;
  };
  const onSubmit = () => {
    const approved = validateFields();
    const checkedValues = approved.find((f) => f === true);
    // approved.every((element) => element);
    console.log("errors", errors);
    console.log("checkedValues", checkedValues);

    if (!checkedValues) {
      dispatch(
        webkulIntegration({
          ...values,
          history,
          fromApprove: true,
          approvePayload: {
            productId: match.params.id,
            status: {
              status: 1,
            },
            type: product.product_type,
          },
          id: product.user_id._id,
        })
      );
      dispatch(setIsApprovedError(""));
      // setOpenWebkulWarning(false);
      setValues({
        apiKey: "",
        secretKey: "",
        userId: "",
      });
      // history.push("/sa-moderate-new-product");
      return;
    }
    setTimeout(
      () =>
        setErrors({
          apiKey: "",
          secretKey: "",
          userId: "",
        }),
      5000
    );
  };
  const onChangeValue = (fieldName) => (e) => {
    const value = e.currentTarget.value;
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));
  };
  const onCloseWebkulWarning = () => {
    dispatch(setIsApprovedError(""));
    setIsDisableButton(false);
  };
  //===========================

  const openRejectModal = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const openImage = (src) => {
    setOpen(true);
    setImageSrc(src);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function downloadOriginal(url) {
    const assetUrl = url;
    fetch(assetUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            assetUrl.substring(assetUrl.lastIndexOf("/") + 1, assetUrl.length)
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function approveProduct() {
    setIsDisableButton(true);
    dispatch(
      changeProductStatus(
        match.params.id,
        { status: 1 },
        product.product_type,
        history
      )
    );
  }

  function declineProduct() {
    setIsDisableButton(true);
    dispatch(
      changeProductStatus(
        match.params.id,
        {
          status: 2,
          decline_reason: declineReason,
        },
        product.product_type,
        history
      )
    );
    handleCloseReject();
  }

  function generateVinylPrice() {
    if (product.vinylSize === "7") {
      return 12;
    }
    if (product.vinylSize === "10") {
      return 17;
    } else return 23;
  }

  const tracks = product?.tracks;
  const tracksParse = (tracks && JSON.parse(tracks)) || [{ data: "" }] || [
      { data: "" },
    ];

  return (
    <div className="moderate-product">
      <BackArrow
        to={"/sa-moderate-new-product"}
        arrowText={
          product.status === 0
            ? "Back to moderate new product"
            : "Back to active products"
        }
        tab={product.status === 0 ? 0 : 1}
      />
      <div className="moderate-product__header">
        <p>Product Info</p>
        {product.status === 0 && (
          <div className="buttons">
            <ButtonCreate
              text="Approve"
              handleClick={approveProduct}
              isDisabled={isDisableButton}
            />
            <ButtonCreate
              text="Reject"
              handleClick={openRejectModal}
              isDisabled={isDisableButton}
            />
          </div>
        )}
      </div>
      <Dialog
        open={!!approveError}
        onClose={onCloseWebkulWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "red", textAlign: "center" }}
        >
          {approveError}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">{`Please integrate the user ${product?.user_id?.name} with webkul`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={"webkul-page_container"}>
              <div className={"webkul-page_fields"}>
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"apiKey"}
                  value={values?.apiKey}
                  onChange={onChangeValue("apiKey")}
                  error={errors?.apiKey}
                  helperText={errors?.apiKey}
                  placeholder={"Access Token"}
                  autocomplete="off"
                />
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"secretKey"}
                  value={values.secretKey}
                  onChange={onChangeValue("secretKey")}
                  error={errors?.secretKey}
                  helperText={errors?.secretKey}
                  placeholder={"Refresh Token"}
                  autocomplete="off"
                />
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"userId"}
                  value={values.userId}
                  onChange={onChangeValue("userId")}
                  error={errors?.userId}
                  helperText={errors?.userId}
                  placeholder={"User id"}
                  autocomplete="off"
                />
              </div>
              <div className="webkul-page_actions">
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {isFetching === "undefined" ? (
        <div className="spinner-position">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div>
          <p>Basic Info</p>
          <InfoBlock
            title={`Vendor: ${product.user_id?.name}`}
            description={`Email: ${product.user_id?.email}`}
          />
          <InfoBlock title="Product name" description={`${product.name}`} />
          <InfoBlock
            title="Product description"
            description={`${product?.description}`}
          />
          <p>Product images with design</p>
          <div className="moderate-product__images">
            {product.images?.map((image, index) => (
              <ImagePreview
                key={index}
                withBorder
                src={image.path}
                handleClick={() => openImage(image.path)}
              />
            ))}
          </div>
          <p>Uploaded Files</p>
          <div className="moderate-product__images">
            {product?.originals_a_side?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
            {product?.originals_b_side?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
            {product.originals_front?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
            {product.originals_back?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
            {product?.originals_c_side?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
            {product?.originals_d_side?.map((image, index) => (
              <div key={index}>
                <ImagePreview
                  key={index}
                  withBorder
                  src={image}
                  handleClick={() => openImage(image)}
                />
                <div style={{ margin: "5px" }}>
                  <ButtonCreate
                    text="Download"
                    handleClick={() => downloadOriginal(image)}
                  />
                </div>
              </div>
            ))}
          </div>
          {product.audio && (
            <div className="moderate-product__tracks">
              <p style={{ color: "black" }}>Uploaded Audio Files:</p>
              {/*<ol*/}
              {/*  className="moderate-product__audio"*/}
              {/*  style={{ display: "flex", flexDirection: "column" }}*/}
              {/*>*/}
              {/*  {product.audio?.map((audio, index) => (*/}
              {/*    <li key={index}>*/}
              {/*      <a*/}
              {/*        key={index}*/}
              {/*        href={audio}*/}
              {/*        target="_blank"*/}
              {/*        className="moderate-product__audio_a"*/}
              {/*      >*/}
              {/*        {audio.split("/").pop()}*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*  ))}*/}
              {/*  <div style={{ marginTop: "30px" }}>*/}
              {/*    <ButtonCreate*/}
              {/*      text={*/}
              {/*        product.audio?.length === 1 ? "Download" : "Download All"*/}
              {/*      }*/}
              {/*      width={"200px"}*/}
              {/*      handleClick={() => downloadOriginal(product.audio?.[0])}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</ol>*/}
              <List component="nav" className="tracks-list">
                {product?.audio?.map((track, index) => (
                  <ListItem
                    // onClick={() => downloadOriginal(track)}
                    key={index}
                  >
                    {/*<ListItemText*/}
                    {/*  primary={`${index + 1}. ${track.substring(*/}
                    {/*    track.lastIndexOf("/") + 1,*/}
                    {/*    track.length*/}
                    {/*  )}`}*/}
                    {/*/>*/}
                    {/*<a*/}
                    {/*  key={index}*/}
                    {/*  href={track}*/}
                    {/*  target="_blank"*/}
                    {/*  className="moderate-product__audio_a"*/}
                    {/*>*/}
                    {/*  {track.split("/").pop()}*/}
                    {/*</a>*/}
                    <a
                      key={index}
                      href={track}
                      target="_blank"
                      className="moderate-product__audio_a"
                    >
                      <ListItemText
                        primary={`${index + 1}. ${track.substring(
                          track.lastIndexOf("/") + 1,
                          track.length
                        )}`}
                      />
                    </a>
                    {product.audio?.length === 1 && (
                      <ButtonCreate
                        text="Download"
                        height={"30px"}
                        handleClick={() => downloadOriginal(track)}
                        className="download-track"
                      />
                    )}
                  </ListItem>
                ))}
              </List>

              {product.audio?.length > 1 && (
                <div style={{ marginTop: "30px" }}>
                  <ButtonCreate
                    text={"Download All"}
                    width={"200px"}
                    handleClick={() => getZipFile(product.audio)}
                  />
                </div>
              )}
            </div>
          )}
          {product.audioA && (
            <>
              <p>Uploaded Audio Files:</p>
              {["audioA", "audioB"].map(
                (sideName, index) =>
                  product[sideName] && (
                    <div key={index} className="moderate-product__tracks">
                      <p>{sideName}</p>
                      {/*<ol*/}
                      {/*  className="moderate-product__audio"*/}
                      {/*  style={{ display: "flex", flexDirection: "column" }}*/}
                      {/*>*/}
                      {/*  {product[sideName]?.map((audio, index) => (*/}
                      {/*    <li key={index}>*/}
                      {/*      <a*/}
                      {/*        key={index}*/}
                      {/*        href={audio}*/}
                      {/*        target="_blank"*/}
                      {/*        className="moderate-product__audio_a"*/}
                      {/*      >*/}
                      {/*        {audio.split("/").pop()}*/}
                      {/*      </a>*/}
                      {/*    </li>*/}
                      {/*  ))}*/}
                      {/*  {product[sideName]?.length > 2 && (*/}
                      {/*    <div style={{ marginTop: "30px" }}>*/}
                      {/*      <ButtonCreate*/}
                      {/*        text={"Download All"}*/}
                      {/*        width={"200px"}*/}
                      {/*        handleClick={() =>*/}
                      {/*          downloadOriginal(product[sideName]?.[0])*/}
                      {/*        }*/}
                      {/*      />*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</ol>*/}
                      <List component="nav" className="tracks-list">
                        {product?.[sideName]?.map((track, index) => (
                          <ListItem
                            // onClick={() => downloadOriginal(track)}
                            key={index}
                          >
                            {/*<ListItemText*/}
                            {/*  primary={`${index + 1}. ${track.substring(*/}
                            {/*    track.lastIndexOf("/") + 1,*/}
                            {/*    track.length*/}
                            {/*  )}`}*/}
                            {/*/>*/}
                            <a
                              key={index}
                              href={track}
                              target="_blank"
                              className="moderate-product__audio_a"
                            >
                              <ListItemText
                                primary={`${index + 1}. ${track.substring(
                                  track.lastIndexOf("/") + 1,
                                  track.length
                                )}`}
                              />
                            </a>
                            {product[sideName]?.length === 1 && (
                              <ButtonCreate
                                text="Download"
                                height={"30px"}
                                handleClick={() => downloadOriginal(track)}
                                className="download-track"
                              />
                            )}
                          </ListItem>
                        ))}
                      </List>
                      {product[sideName]?.length > 1 && (
                        <div style={{ marginTop: "30px" }}>
                          <ButtonCreate
                            text={"Download All"}
                            width={"200px"}
                            handleClick={() => getZipFile(product[sideName])}
                          />
                        </div>
                      )}
                    </div>
                  )
              )}
            </>
          )}

          {product?.__t === "vinyl" && (
            <>
              {" "}
              <p>Playlist</p>
              <div className="moderate-product__tracks">
                {tracksParse?.map((side, index) => (
                  <div key={index}>
                    <span>{side.side.split("_").join(" ")}</span>
                    <List component="nav" className="tracks-list">
                      {side.data.split("\n").map((track, id) => (
                        <ListItem key={id}>
                          {track.trim() !== "" && (
                            <ListItemText
                              primary={
                                track.trim().length > 0 ? track : "No tracks"
                              }
                            />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ))}
              </div>
            </>
          )}
          {product?.__t === "cd" && (
            <>
              {" "}
              <p>Playlist</p>
              <div className="moderate-product__tracks">
                <div>
                  <List component="nav" className="tracks-list">
                    {tracksParse[0].data.split("\n").map((track, id) => {
                      return (
                        track.trim() !== "" && (
                          <ListItem key={id}>
                            <ListItemText
                              primary={track.trim() !== "" && track}
                            />
                          </ListItem>
                        )
                      );
                    })}
                  </List>
                </div>
              </div>
            </>
          )}

          {/*<p>Playlist</p>*/}

          {/*  <div className="moderate-product__tracks">*/}
          {/*    {tracksParse?.map((side, index) => (*/}
          {/*      <div key={index}>*/}
          {/*        {product.__t === "vinyl" && (*/}
          {/*          <span>{side.side.split("_").join(" ")}</span>*/}
          {/*        )}*/}

          {/*        <List component="nav" className="tracks-list">*/}
          {/*          {side.data.split("\n").map((track, id) => (*/}
          {/*            <ListItem key={id}>*/}
          {/*              <ListItemText*/}
          {/*                primary={*/}
          {/*                  track.trim().length > 0 ? track : "No tracks"*/}
          {/*                }*/}
          {/*              />*/}
          {/*            </ListItem>*/}
          {/*          ))}*/}
          {/*        </List>*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}

          {/*{product.__t === "vinyl" && (*/}
          {/*  <div className="moderate-product__tracks">*/}
          {/*{console.log("123213123", JSON.parse(product?.tracks))}*/}
          {/*{product?.tracks.map((data, index) => (*/}
          {/*  <div key={index}>*/}
          {/*    <span>{JSON.parse(data).side.split("_").join(" ")}</span>*/}
          {/*    <List component="nav" className="tracks-list">*/}
          {/*      {JSON.parse(data)*/}
          {/*        .data.split("\n")*/}
          {/*        .map((track) => (*/}
          {/*          <ListItem button>*/}
          {/*            <ListItemText*/}
          {/*              primary={*/}
          {/*                track.trim().length > 0 ? track : "No tracks"*/}
          {/*              }*/}
          {/*            />*/}
          {/*          </ListItem>*/}
          {/*        ))}*/}
          {/*    </List>*/}
          {/*  </div>*/}
          {/*))}*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{product.__t === "vinyl" && <p>Tracks</p>}*/}
          {/*{product.__t === "vinyl" && (*/}
          {/*  <div className="moderate-product__tracks">*/}
          {/*    <List component="nav" className="tracks-list">*/}
          {/*      {product?.audioA?.map((track, index) => (*/}
          {/*        <ListItem*/}
          {/*          button*/}
          {/*          onClick={() => downloadOriginal(track)}*/}
          {/*          key={index}*/}
          {/*        >*/}
          {/*          <ListItemText*/}
          {/*            primary={`${index + 1}. ${track.substring(*/}
          {/*              track.lastIndexOf("/") + 1,*/}
          {/*              track.length*/}
          {/*            )}`}*/}
          {/*          />*/}
          {/*          <ButtonCreate*/}
          {/*            text="Download"*/}
          {/*            handleClick={() => downloadOriginal(track)}*/}
          {/*            className="download-track"*/}
          {/*          />*/}
          {/*        </ListItem>*/}
          {/*      ))}*/}
          {/*    </List>*/}
          {/*  </div>*/}
          {/*)} */}

          {product?.__t === "vinyl" && (
            <>
              <p>Color: {product?.vinylColor}</p>
              <p>Size: {product?.vinylSize}</p>
            </>
          )}

          <p>Manufacturing cost:</p>
          <div className="moderate-product__pricing">
            <span>Manufacturing cost:</span>
            <span>
              $
              {product.vinylSize
                ? generateVinylPrice()
                : product.template?.manufacturing_cost}
            </span>
            <span>Price by Vendor:</span>
            <span>${product?.price}</span>
          </div>
          <p>Technique of printing:</p>
          <span>Direct to garment</span>
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <ImagePreview src={imageSrc} handleClick={openImage} width={600} />
          </Dialog>
          <Dialog
            open={openReject}
            onClose={handleCloseReject}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent style={{ padding: 20 }}>
              <DialogContentText>Vendor's E-mail</DialogContentText>
              <p>Reason of Rejection:</p>
              <TextField
                autoFocus
                margin="dense"
                label="Reason of Rejection"
                required
                id="outlined-basic"
                variant="outlined"
                type="text"
                value={declineReason}
                multiline
                style={{ width: 400 }}
                onChange={(e) => setDeclineReason(e.target.value)}
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReject} color="primary">
                Cancel
              </Button>
              <Button onClick={declineProduct} color="primary">
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};
