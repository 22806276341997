import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsTabs } from "./components/ProductsTabs";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./styles.scss";

import {
  getActiveProducts,
  getInactiveProducts,
  getRejectedProducts,
} from "../../redux/products/productsActions";
import { Snackbar } from "@material-ui/core";
import {
  setApproved,
  setIsEditError,
  setIsEditSuccess,
  setRejected,
} from "../../redux/products/productsMutations";
import { Alert } from "@material-ui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    flexGrow: 1,
    backgroundColor: "#f8f9fa",
  },
}));

export const Dashboard = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.commonReducer.isFetching);

  // const [isEditError, setIsEditError] = useState(true);

  const isEditSuccess = useSelector(
    (state) => state.productsReducer.isEditSuccess
  );

  const isEditError = useSelector((state) => state.productsReducer.isEditError);

  const activeProducts = useSelector(
    (state) => state.productsReducer.activeProducts
  );
  const inactiveProducts = useSelector(
    (state) => state.productsReducer.inactiveProducts
  );
  const rejectedProducts = useSelector(
    (state) => state.productsReducer.rejectedProducts
  );

  useEffect(() => {
    dispatch(getActiveProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInactiveProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRejectedProducts());
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(setIsEditError(false));
      dispatch(setIsEditSuccess(false));
      return;
    }
    dispatch(setIsEditError(false));
    dispatch(setIsEditSuccess(false));
  };

  return (
    <div className="dashboard">
      <div className={classes.root}>
        {isEditError && (
          <Snackbar
            open={isEditError}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert severity="error">Edit product was error</Alert>
          </Snackbar>
        )}
        {isEditSuccess && (
          <Snackbar
            open={isEditSuccess}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert severity="success">Edit product was successfully</Alert>
          </Snackbar>
        )}

        {isFetching ? (
          <CircularProgress color="secondary" />
        ) : (
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="product tabs"
              >
                <Tab label="In Review" {...a11yProps(0)} />
                <Tab label="In Store" {...a11yProps(1)} />
                <Tab label="Rejected" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <ProductsTabs products={inactiveProducts} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProductsTabs products={activeProducts} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ProductsTabs products={rejectedProducts} />
            </TabPanel>
          </div>
        )}
      </div>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
