import {
  ADD_ADMIN,
  ADD_VENDOR,
  GET_ADMIN_LIST,
  REMOVE_USER_BY_ID,
  GET_USER_INFO, IS_CREATED_ADMIN,
  GET_USERS
} from "./usersTypes";
import {IS_REJECTED_ADMIN} from "../auth/authTypes";

export function addAdmin(data) {
  return {
    type: ADD_ADMIN,
    data,
  };
}

export function isCreatedAdmin(data) {
  return {
    type: IS_CREATED_ADMIN,
    data,
  };
}

export function isRejectedAdmin(data) {
  return {
    type: IS_REJECTED_ADMIN,
    data,
  };
}

export function addVendor(data) {
  return {
    type: ADD_VENDOR,
    data,
  };
}

export function getAdmins(data) {
  return {
    type: GET_ADMIN_LIST,
    data,
  };
}

export function removeUser(id) {
  return {
    type: REMOVE_USER_BY_ID,
    id,
  };
};

export function getUserInfo(data) {
  return {
    type: GET_USER_INFO,
    data
  }
}

export function getUsers(data) {
  return {
    type: GET_USERS,
    data
  }
}