import React, { useEffect, useState } from "react";
import { resetPassword } from "../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonCreate } from "../../components/ButtonCreate";
import TextField from "@material-ui/core/TextField";
import "./style.scss";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { emailValidator } from "../../utils/valodators/emailValidator";

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const isResetPassword = useSelector(
    (state) => state.authReducer.resetPassword
  );
  const values = { email };
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isResetPassword !== null) {
      if (isResetPassword.success) {
        setErrorSend(false);
        setSuccess(true);
      } else {
        setErrorSend(true);
        setSuccess(false);
      }
    }
  }, [isResetPassword]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        setErrorSend(false);
        history.goBack();
      }, 2000);
    }
    if (errorSend) {
      setTimeout(() => {
        setErrorSend(false);
        setSuccess(false);
      }, 2000);
    }
  }, [errorSend, history, success]);

  const onChangeValue = (e) => {
    const value = e.target.value;
    if (!emailValidator(value)) {
      setError("Invalid email format");
      setEmail(value);
    } else {
      setError("");
      setEmail(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!error) {
      dispatch(resetPassword(values));
    }
  };
  return (
    <div className="login">
      {
        <Snackbar
          open={errorSend || success}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={success ? "success" : "error"}>
            {success
              ? "We have sent a letter to your mail"
              : "Email was not sent"}
          </Alert>
        </Snackbar>
      }
      <h2>Restore password</h2>
      <form className="login__form" onSubmit={onSubmit}>
        <p>Enter your email</p>
        <TextField
          label="email"
          variant="outlined"
          value={email}
          required
          onChange={onChangeValue}
        />
        {error && <div className="error">{error}</div>}
        <ButtonCreate submit text="Restore" />
      </form>
    </div>
  );
};
