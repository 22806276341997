// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  font-family: \"Quantico\", sans-serif;\n  background-color: #f8f9fa;\n}\n\na:not([class]) {\n  text-decoration: none;\n  text-decoration-skip-ink: auto;\n}", "",{"version":3,"sources":["webpack://src/commonStyles/common.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;EACA,UAAA;EACA,mCAAA;EACA,yBAAA;AAEF;;AACA;EACE,qBAAA;EACA,8BAAA;AAEF","sourcesContent":["@import './src/commonStyles/colors';\n@import './src/commonStyles/fonsts.scss';\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Quantico', sans-serif;\n  background-color:#f8f9fa;\n}\n\na:not([class]) {\n  text-decoration: none;\n  text-decoration-skip-ink: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
