import { combineReducers } from "redux";
//////////////////////////////////////////
import authReducer from "../auth/authReducer";
import productsReducer from "../products/productsReducer";
import commonReducer from "../common/commonReducer";
import categoriesReducer from "../categories/categoriesReducer";
import collectionsReducer from "../collections/collectionsReducer";
import templatesReducer from "../templates/templatesReducer";
import usersReducer from "../users/usersReducer";

export const rootReducer = combineReducers({
  authReducer,
  productsReducer,
  commonReducer,
  categoriesReducer,
  collectionsReducer,
  templatesReducer,
  usersReducer,
});
