import {
  ADD_ADMIN,
  ADD_VENDOR,
  GET_ADMIN_LIST,
  REMOVE_USER_BY_ID,
  GET_USER_INFO,
  GET_USERS,
} from "./usersTypes";

const initialState = {
  admins: [],
  vendors: [],
  info: {},
  users: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_LIST:
      return { ...state, admins: action.data };
    case ADD_VENDOR:
      return { ...state, vendors: action.data };
    case ADD_ADMIN:
      return { ...state, admins: action.data };
    case REMOVE_USER_BY_ID:
      return {
        ...state,
        admins: state.admins.filter((admin) => admin._id !== action.id),
        vendors: state.vendors.filter((vendor) => vendor._id !== action.id),
      };
    case GET_USER_INFO:
      return { ...state, info: action.data };
    case GET_USERS:
      return { ...state, users: action.data.reverse() };
    default:
      return state;
  }
};

export default usersReducer;
