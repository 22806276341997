import axios from '../../API/axios';
import { toggleIsFetching } from "../common/commonMutations";
import {
    getCollections
} from './collectionsMutations';

export function getAllCollections(templateGroupId) {
    return (dispatch) => {
        dispatch(toggleIsFetching(true));
        axios
        .get(templateGroupId ? `/collections-group/${templateGroupId}` : `/collections`)
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getCollections(response.data.collections));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    };
}
