import React, { useState } from "react";
import { login } from "../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { ButtonCreate } from "../../components/ButtonCreate";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import "./styles.scss";

export const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const error = useSelector((state) => state.authReducer.error);
  const values = { email, password, remember };
  const history = useHistory();
  const dispatch = useDispatch();

  const toForgotPassword = () => {
    history.push("restore-password");
  };
  return (
    <div className="login">
      <h2>SIGN IN</h2>
      <form
        className="login__form"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(login(values, history));
        }}
      >
        <p>Enter your email</p>
        <TextField
          label="email"
          variant="outlined"
          value={email}
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <p>Enter your password:</p>
        <TextField
          label="password"
          variant="outlined"
          value={password}
          required
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
        />
        <div className="remember-block">
          <div>
            <Checkbox
              checked={remember}
              onChange={() => setRemember(!remember)}
              inputProps={{ "aria-label": "primary checkbox" }}
              label="Remember me"
            />
            <span style={{ color: "red" }}>Remember me</span>
          </div>
          <div onClick={toForgotPassword} className={"forgot_password"}>
            Forgot password?
          </div>
        </div>
        <p>
          Dont have an account? <NavLink to="/sign-up">Sign UP</NavLink>
        </p>
        <div className="error">{error}</div>
        <ButtonCreate submit text="Sign IN" />
      </form>
    </div>
  );
};
