import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ManageTitle } from "../../components/ManageTitle";
import { ButtonCreate } from "../../components/ButtonCreate";
import TextField from "@material-ui/core/TextField";
import { addNewAdmin } from "../../redux/users/usersActions";
import "./styles.scss";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
export const ManageAddNewAdmin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emails, setEmails] = useState("");
  const [name, setName] = useState("");
  const [error, setIsError] = useState(false);
  const isCreatedAdmin = useSelector(
    (state) => state.authReducer.isCreatedAdmin
  );

  const isRejectedAdmin = useSelector(
    (state) => state.authReducer.isRejectedAdmin
  );
  const submitNewAdmins = () => {
    const emailsArray = emails.match(/[^\r\n]+/g);
    if (emailsArray?.length) {
      emailsArray.forEach((email) => {
        dispatch(
          addNewAdmin({
            email,
            name,
            password: Math.random().toString(36).slice(-8),
          })
        );
      });
    }
    setEmails("");
    setName("");
  };

  useEffect(() => {
    if (isCreatedAdmin) {
      setTimeout(() => {
        history.push("/sa-manage");
      }, 1000);
    }
  }, [dispatch, history, isCreatedAdmin]);

  useEffect(() => {
    setTimeout(() => {
      setIsError(false);
    }, 1000);
  });

  useEffect(() => {
    if (isRejectedAdmin || isCreatedAdmin) setIsError(true);
  }, [isCreatedAdmin, isRejectedAdmin]);

  return (
    <div className="add-admin">
      {
        <Snackbar
          open={error}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={!isRejectedAdmin ? "success" : "error"}>
            {!isRejectedAdmin
              ? "You have successfully added the admin user"
              : "The user is not added"}
          </Alert>
        </Snackbar>
      }
      <ManageTitle title="Add new admin" />
      <span>Please specify your admin e-mails that you want to invite:</span>
      <div className="input-wrap">
        <TextField
          id="filled-multiline-flexible"
          label="Specify emails"
          multiline
          rows={4}
          fullWidth
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          variant="filled"
        />
        <TextField
          id="filled-multiline-flexible"
          label="Name"
          multiline
          rows={4}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="filled"
        />
      </div>
      <div style={{ width: 200 }}>
        <ButtonCreate
          fullWidth
          text="submit"
          handleClick={() => submitNewAdmins()}
        />
      </div>
    </div>
  );
};
