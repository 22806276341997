import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { ButtonCreate } from "../../../components/ButtonCreate";
import RegionSelect from "react-region-select";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import "./styles.scss";

export const ImagesConfig = ({
  preview,
  onUpdatePreview,
  handleDelete,
  checkboxOptions,
  chosenTypes,
  setChosenTypes,
  vinyl,
  withoutSides,
}) => {
  const [darkBackground, setDarkBackground] = useState(false);

  const handleCheck = (e) => {
    const { value: type, checked } = e.currentTarget;

    onUpdatePreview({ ...preview, type: checked ? type : null });
    updateChosenTypes(type, checked);
  };

  const updateChosenTypes = (type, checked) => {
    const ct = { ...chosenTypes };
    ct[type] = checked;

    setChosenTypes(ct);
  };

  const handleRegionChange = (e) => {
    const [coords] = e;
    onUpdatePreview({ ...preview, coords });
  };

  const handleSelect = () => {
    setDarkBackground(!darkBackground);
  };

  useEffect(() => {
    regions.length && setDarkBackground(false);
  });

  const isCheckboxDisabled = (value) => {
    return (
      (preview.type && preview.type !== value) ||
      (chosenTypes[value] && preview.type !== value)
    );
  };

  const regions = preview?.coords ? [preview.coords] : [];

  return (
    <div className={vinyl ? "images-vinyl-block" : "images-block"}>
      <div className={darkBackground ? "dark-background" : ""}>
        <RegionSelect
          className="region-select-block"
          maxRegions={1}
          regions={regions}
          onChange={handleRegionChange}
          constraint
        >
          <img src={preview.imgSrc} alt="product" />
        </RegionSelect>
      </div>
      <div className="new-product">
        <div className="new-product__settings">
          {!withoutSides && <span>choose side:</span>}
          {!withoutSides &&
            checkboxOptions.map(({ label, value }) => (
              <div key={value}>
                <Checkbox
                  onChange={handleCheck}
                  value={value}
                  checked={preview.type === value}
                  disabled={isCheckboxDisabled(value)}
                />
                <span>{label}</span>
              </div>
            ))}

          <div className="">
            <ButtonCreate
              isDisabled={!!regions.length}
              zIndex={2}
              text={darkBackground ? "Set this area" : "Select area for print"}
              handleClick={handleSelect}
            />
            <IconButton text="delete" onClick={() => handleDelete(preview)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
