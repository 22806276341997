import React from "react";
import "./styles.scss";

export const ImagePreview = ({
  src,
  handleClick,
  withBorder,
  width,
  height,
}) => {
  return (
    <div className={withBorder ? "image-wrap" : ""} onClick={handleClick}>
      <img src={src} alt="preview" style={{ width: width, height: height }} />
    </div>
  );
};
