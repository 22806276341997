import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ManageTitle } from "../../components/ManageTitle";
import { ButtonDelete } from "../../components/ButtonDelete";
import { removeUserById } from "../../redux/users/usersActions";
import "./styles.scss";
import { ButtonCreate } from "../../components/ButtonCreate";

export const ManageCurrentAdmin = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  const userId = match.params.id;
  const user = useSelector((state) => state.usersReducer.admins).find(
    (u) => u._id === userId
  );
  return (
    <div className="manage-current">
      <ManageTitle title="Admin information">
        {/*<ButtonCreate*/}
        {/*  text="Add new Admin"*/}
        {/*  handleClick={() => history.push("/sa-add-new-admin-id")}*/}
        {/*/>*/}
      </ManageTitle>
      <div className="manage-current__user-info">
        <p>Name: {user?.name}</p>
        <p>E-mail: {user?.email}</p>
      </div>
      <div className="manage-current__actions">
        <ButtonDelete
          deleteEvent={() => {
            dispatch(removeUserById(userId));
            history.goBack();
          }}
          isIconButton={false}
          buttonText="Remove"
          hasConfirmation
          routerBack
        />
      </div>
    </div>
  );
};
