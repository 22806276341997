import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { ButtonCreate } from "../ButtonCreate";
import './styles.scss'
import {Button} from "@material-ui/core";

export const NewCategoryForm = ({
  label,
  text,
  categoryEvent,
  parent = null,
  fullWidth,
  size,
  className,
                                  onCancel,
                                    isCancelButton
}) => {
  const [newCategory, setNewCategory] = useState("");
  const createNewCategory = () => {
    parent ? categoryEvent(newCategory, parent) : categoryEvent(newCategory);
    setNewCategory("");
  }

  return (
    <div className="new-cat__item new_category_form">
      <TextField
        required
        id="outlined-basic"
        label={label}
        variant="filled"
        type="text"
        value={newCategory}
        onChange={e => setNewCategory(e.target.value)}
      />
      <div className="button-wrap">
        <ButtonCreate
          size={size}
          className={className}
          fullWidth={fullWidth}
          text={text}
          handleClick={() => createNewCategory()}
        />
          {isCancelButton && <div onClick={onCancel} className='new_category_form_cancel'>
              <Button variant="contained">
                  Cancel
              </Button>
          </div>}
      </div>
    </div>
  );
};
