import {
  ADD_NEW_PRODUCT,
  GET_ACTIVE_PRODUCTS,
  GET_INACTIVE_PRODUCTS,
  GET_PRODUCT,
  GET_REJECTED_PRODUCTS,
  IS_APPROVED_ERROR,
  SET_APPROVED,
  SET_IS_EDIT_ERROR,
  SET_IS_EDIT_SUCCESS,
  SET_REJECTED,
} from "./productsTypes";

export function getActiveProductsMutation(data) {
  return {
    type: GET_ACTIVE_PRODUCTS,
    data,
  };
}

export function getInactiveProductsMutation(data) {
  return {
    type: GET_INACTIVE_PRODUCTS,
    data,
  };
}

export function getRejectedProductsMutation(data) {
  return {
    type: GET_REJECTED_PRODUCTS,
    data,
  };
}

export function addNewProductMutation(data) {
  return {
    type: ADD_NEW_PRODUCT,
    data,
  };
}

export function getProductMutation(data) {
  return {
    type: GET_PRODUCT,
    data,
  };
}

export function setApproved(data) {
  return {
    type: SET_APPROVED,
    data,
  };
}

export function setRejected(data) {
  return {
    type: SET_REJECTED,
    data,
  };
}

export function setIsEditSuccess(data) {
  return {
    type: SET_IS_EDIT_SUCCESS,
    data,
  };
}

export function setIsEditError(data) {
  return {
    type: SET_IS_EDIT_ERROR,
    data,
  };
}
export function setIsApprovedError(data) {
  return {
    type: IS_APPROVED_ERROR,
    data,
  };
}
