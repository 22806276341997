import { toggleIsFetching } from "../common/commonMutations";
import {
  addNewProductMutation,
  getActiveProductsMutation,
  getInactiveProductsMutation,
  getProductMutation,
  getRejectedProductsMutation,
  setApproved,
  setIsApprovedError,
  setIsEditError,
  setIsEditSuccess,
  setRejected,
} from "./productsMutations";
import axios from "../../API/axios/index";
import { isCreatedAdmin } from "../users/usersMutations";

export function getActiveProducts() {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`/products`, {
          params: {
            status: 1,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getActiveProductsMutation(response.data));
        })
        .catch((e) => {
          console.log("ERR IN GET ALL POSTS OR GET POSTS:", e);
        });
    }
  };
}

export function getInactiveProducts() {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`/products/`, {
          params: {
            status: 0,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getInactiveProductsMutation(response.data));
        })
        .catch((e) => {
          console.log("ERR IN GET ALL POSTS OR GET POSTS:", e);
        });
    }
  };
}

export function getRejectedProducts() {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`/products/`, {
          params: {
            status: 2,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getRejectedProductsMutation(response.data));
        })
        .catch((e) => {
          console.log("ERR IN GET ALL POSTS OR GET POSTS:", e);
        });
    }
  };
}

/**
 * Enum for product types.
 * @readonly
 * @enum {string}
 */
export const ProductType = Object.freeze({
  CD: "cd",
  VINYL: "vinyl",
  CASSETTE: "cassette",
  APPAREL: "apparel",
  ACCESSORIES: "accessories",
});
const serviceByProductTypeAdd = new Map([
  [ProductType.CD, `/cd-products`],
  [ProductType.VINYL, `/vinyl-products`],
  [ProductType.CASSETTE, `/cassette-products`],
  [ProductType.APPAREL, `/apparel-products`],
  [ProductType.ACCESSORIES, `/accessories-products`],
]);

function getAddServiceByProductType(productType) {
  return serviceByProductTypeAdd.get(productType?.toLowerCase()) || `/products`;
}

const serviceByProductTypeUpdate = new Map([
  [ProductType.CD, `/cd-products/update-status/`],
  [ProductType.VINYL, `/vinyl-products/update-status/`],
  [ProductType.CASSETTE, `/cassette-products/update-status/`],
  [ProductType.APPAREL, `/apparel-products/update-status/`],
  [ProductType.ACCESSORIES, `/accessories-products/update-status/`],
]);

function getUpdateServiceByProductType(productType) {
  return (
    serviceByProductTypeUpdate.get(productType?.toLowerCase()) ||
    `/products/update-status/`
  );
}

/**
 * Performs server API call to create new product
 * @param formData - product data
 * @param productCategory - category of product, used to choose appropriate server endpoint for product creation
 * @returns {(function(*): void)}
 */
export function addNewProduct(formData, productCategory) {
  return (dispatch) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    dispatch(toggleIsFetching(true));
    if (token) {
      axios
        .post(getAddServiceByProductType(productCategory), formData)
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(addNewProductMutation(response.data));
        })
        .catch((error) => {
          const errorMessage = JSON.stringify(error?.response?.data || "");
          console.log(
            `ERR creating product: ${error}\nError message: ${errorMessage}`
          );
          if (errorMessage) {
            alert(`Can't add product, reason: ${errorMessage}`);
            dispatch(toggleIsFetching(false));
          }
        });
    }
  };
}
export function editRejected(formData, productId, productType) {
  return (dispatch) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    dispatch(toggleIsFetching(true));
    console.log(
      "formData, productId, productType",
      formData,
      productId,
      productType
    );
    if (token) {
      axios
        .patch(
          getAddServiceByProductType(productType) + "/" + productId,
          formData
        )
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(setIsEditSuccess(true));
          dispatch(addNewProductMutation(response.data));
        })
        .catch((error) => {
          dispatch(toggleIsFetching(false));
          dispatch(setIsEditError(true));
          const errorMessage = JSON.stringify(error?.response?.data || "");
          console.log(
            `ERR editing the product: ${error}\nError message: ${errorMessage}`
          );
          if (errorMessage) {
            alert(`Can't edit the product, reason: ${errorMessage}`);
          }
        });
    }
  };
}

export function getProduct(id) {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));

    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      axios
        .get(`/products/${id}`)
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getProductMutation(response.data));
        })
        .catch((error) => {
          const errorMessage = JSON.stringify(error?.response?.data || "");
          console.log(
            `ERR getting product: ${error}\nError message: ${errorMessage}`
          );
          if (errorMessage) {
            alert(`Can't get product, reason: ${errorMessage}`);
          }
        });
    }
  };
}

export function changeProductStatus(id, data, productType, history) {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      axios
        .post(getUpdateServiceByProductType(productType) + id, data)
        .then((response) => {
          console.log("data", data);

          if (data.status === 1) {
            dispatch(setApproved(true));
          } else {
            dispatch(setRejected(true));
          }
          dispatch(toggleIsFetching(false));
          history.push("/sa-moderate-new-product");
        })
        .catch((error) => {
          if (error.response.status === 421) {
            dispatch(isCreatedAdmin(false));
            dispatch(setIsApprovedError(error.response.data.message));
          }
        });
    }
  };
}

// export function getAllPostsOfServer() {
//   return (dispatch) => {
//     dispatch(toggleIsFetching(true));
//     setTimeout(() => {
//       dispatch(toggleIsFetching(false));
//       // console.log(response)
//       dispatch(getAllPosts(YOUR IMPORTED ARRAY));
//     }, 1000);
//   };
// }
