import { TOGGLE_IS_FETCHING } from "./commonTypes";

const initialState = {
  isFetching: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_IS_FETCHING:
      return { ...state, isFetching: action.isFetching };

    default:
      return state;
  }
};

export default commonReducer;
