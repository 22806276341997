import {CREATE_NEW_SELLER, LOGIN, LOGOUT, SET_SUCCESS_REGISTRATION, LOGIN_ERROR, RESET_PASSWORD} from "./authTypes";

export function createNewSellerMutation(data) {
  return {
    type: CREATE_NEW_SELLER,
    data,
  };
}

export function loginMutation(data) {
  return {
    type: LOGIN,
    data,
  };
}

export function resetPasswordMutation(data) {
  return {
    type: RESET_PASSWORD,
    data,
  };
}

export function loginError(data) {
  return {
    type: LOGIN_ERROR,
    data,
  };
}

export function logoutMutation() {
  return {
    type: LOGOUT,
  };
}

export function setSuccessRegistration(data) {
  return {
    type: SET_SUCCESS_REGISTRATION,
    data
  };
}

