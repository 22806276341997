import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createNewSeller } from "../../redux/auth/authActions";
import { ButtonCreate } from "../../components/ButtonCreate";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import "./styles.scss";
import { setSuccessRegistration } from "../../redux/auth/authMutations";

export const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [password, setPassword] = useState("");
  const successRegistration = useSelector(
    (state) => state.authReducer.successRegistration
  );
  const values = {
    name,
    email,
    password,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector((state) => state.authReducer.error);

  useEffect(() => {
    if (successRegistration) setShowAlert(true);
  }, [successRegistration]);

  useEffect(() => {
    if (successRegistration) {
      setTimeout(() => {
        setShowAlert(false);
        history.push(`/login`);
        dispatch(setSuccessRegistration(false));
      }, 1500);
    }
  }, [history, successRegistration]);

  console.log(showAlert);
  return (
    <div className="signup">
      {
        <Snackbar
          open={showAlert}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">Sign up is successful</Alert>
        </Snackbar>
      }
      <h2>Sign UP! Join US!</h2>
      <form
        className="signup__form"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(createNewSeller(values, history));
        }}
      >
        <p>Enter your name:</p>
        <TextField
          label="user name"
          variant="outlined"
          value={name}
          required
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <p>Enter your email:</p>
        <TextField
          type="email"
          label="email"
          variant="outlined"
          value={email}
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <p>create your password</p>
        <TextField
          type="password"
          label="set password"
          variant="outlined"
          value={password}
          required
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <p>
          Already have an account? <NavLink to="/login">Log In</NavLink>
        </p>
        <ButtonCreate submit text="Sign up" />
      </form>
      {error && (
        <div className="signup__alert">
          <Alert severity="error">
            <AlertTitle>Sign up error!</AlertTitle>
            {error}
          </Alert>
        </div>
      )}
    </div>
  );
};
// {/*<p>YOUR STATE:</p>*/}
// {/*<input*/}
// {/*  type="text"*/}
// {/*  placeholder="STATE"*/}
// {/*  className="inputSignup"*/}
// {/*  required={true}*/}
// {/*  value={state}*/}
// {/*  onChange={(e) => {*/}
// {/*    setState(e.target.value);*/}
// {/*  }}*/}
// {/*/>*/}
// {/*<label htmlFor="countries">Choose a country:</label>*/}
// {/*<select>*/}
// {/*  {countries.map((item, index) => (*/}
// {/*    <option key={index} value={country} onChange={setCountry}>*/}
// {/*      {item.label}*/}
// {/*    </option>*/}
// {/*  ))}*/}
// {/*</select>*/}
// {/*<p>Enter your store name:</p>*/}
// {/*<input*/}
// {/*  className="inputSignup"*/}
// {/*  type="username"*/}
// {/*  value={storeName}*/}
// {/*  onChange={(e) => {*/}
// {/*    setStoreName(e.target.value);*/}
// {/*  }}*/}
// {/*  placeholder="STORE NAME"*/}
// {/*  required={true}*/}
// {/*/>*/}
