import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
} from "@material-ui/core";
import { ButtonCreate } from "../../../components/ButtonCreate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../../CustomizeProductPage/components/FileUploader/FileUploader";
import DiskSide from "./images/cd-template.png";
import BoxSide from "./images/box-template.png";
import { Layer, Stage } from "react-konva";
import { Img } from "../../CustomizeProductPage/components/Img/Img";
import domtoimage from "dom-to-image";
import DialogContent from "@material-ui/core/DialogContent";
import { ModerationSlider } from "../../CustomizeProductPage/ModerationSlider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {
  addNewProduct,
  editRejected,
  getRejectedProducts,
  ProductType,
} from "../../../redux/products/productsActions";
import { srcToFile, dataURLtoFile, getRandomString } from "../../../utils";
import { encodeImageFileAsURL } from "../../../utils/converteImageToBase64";
import { getTemplateById } from "../../../redux/templates/templatesActions";
import { Preloader } from "../../../components/Preloader";
import CrossIcon from "../../../assets/images/close.svg";
import "./styles.scss";
import { ImagePreview } from "../../../components/ImagePreview";
import Alert from "@material-ui/lab/Alert";

const initialRectangles = [];

const stageWidth = 600;
const stageHeight = 600;
const imageDiskWidth = 418;
const imageDiskHeight = 415;
const imageBoxWidth = 399;
const imageBoxHeight = 399;

const sidesConfig = [
  {
    name: "side A-disk",
    id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: DiskSide,
    disk: true,
    side: "a_side",
  },
];

const boxesConfig = [
  {
    name: "side A-box",
    id: "3160ba8d-1c3b-4ced-ba85-f19948cb76d7",
    path: BoxSide,
    disk: false,
    side: "c_side",
  },
  {
    name: "side B-box",
    id: "5521c296-55a2-11eb-ae93-0242ac130002",
    path: BoxSide,
    disk: false,
    side: "d_side",
  },
];

const requiredFields = ["a_side", "price", "name", "audio"];

const productDescription =
  "Please enter all information below to create your custom CD. Once submitted our Admins review each product before setting it live to your store.  Max recording time for CDs is 80 mins. ";
let timer;
export const EditRejectedCd = () => {
  const rejectedProducts = useSelector(
    (state) => state.productsReducer.rejectedProducts
  );

  const params = useParams();
  const product = rejectedProducts?.find(
    (product) => product._id === params.id
  );
  console.log("template", product);
  const [activeImage, setActiveImage] = useState(null);
  const [activeSideName, setActiveImagesSide] = useState("a_side");
  const [selectedId, selectShape] = useState(null);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [idCount, setIdCount] = useState(0);
  const [screenshotSave, setScreenshotSave] = useState(false);
  const [isScreenshotFetching, setIsScreenshotFetching] = useState(false);
  const [values, setValues] = useState({});
  const getTracksASide = () =>
    product?.tracks ? JSON.parse(product.tracks)?.[0]?.data : "";

  useEffect(() => {
    setValues({
      a_side: getTracksASide(),
      name: product?.name,
      comment: product?.description,
      price: product?.price,
      audioA: [],
      audioB: [],
    });
  }, [setValues, product]);

  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const canvasRef = React.useRef();
  const dispatch = useDispatch();

  const styleProps = {
    position: "relative",
    backgroundColor: "transparent",
    top: activeImage?.disk ? "50%" : "48%",
    left: "50%",
    width: activeImage?.disk ? imageDiskWidth : imageBoxWidth,
    height: activeImage?.disk ? imageDiskHeight : imageBoxHeight,
    borderRadius: activeImage?.disk ? "50%" : 0,
    transform: "translate(-50%, -50%)",
  };

  const [files, setFiles] = useState([]);
  const [completeImages, setCompleteImages] = useState({});
  const [detailInfoFiles, setDetailInfoFiles] = useState({});
  const [isSendData, setIsSendData] = useState(false);
  // const product = useSelector((state) => state.templatesReducer.product);
  const isLoading = useSelector((state) => state.commonReducer.isFetching);
  const [totalCheckFields, setTotalCheckFields] = useState(false);

  const [uploadFirstImage, setUploadNewImage] = useState(0);
  const [secondRender, setSecondRender] = useState(0);
  const [thirdRender, setThirdRender] = useState(0);

  useEffect(() => {
    if (!totalCheckFields) {
      clearTimeout(timer);
    }
  }, [totalCheckFields]);

  const [isEditImages, setIsEditImages] = useState(false);
  const [isEditAudio, setIsEditAudio] = useState(false);

  useEffect(() => {
    dispatch(getRejectedProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemplateById("vinyl"));
  }, [dispatch]);

  useEffect(() => {
    setActiveImage(sidesConfig[0]);
  }, []);

  const handleImageLoaded = (imgUrl) => {
    const newImg = buildImgProperty(imgUrl);
    if (rectangles.find((r) => r.side === activeSideName)) {
      const index = rectangles.findIndex((r) => r.side === activeSideName);
      setRectangles((prevState) => {
        const newR = [...prevState];
        newR[index] = newImg;
        return [...newR];
      });
    } else {
      setRectangles([...rectangles, newImg]);
    }
    setIsScreenshotFetching(true);
    // setTimeout(() => {
    //   screenshot();
    // }, 100);
  };
  useEffect(() => {
    setUploadNewImage((prevState) => ++prevState);
  }, [rectangles]);

  useEffect(() => {
    setTimeout(() => {
      setSecondRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [uploadFirstImage]);

  useEffect(() => {
    setTimeout(() => {
      setThirdRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [secondRender]);

  useEffect(() => {
    setTimeout(() => {
      screenshot();
    }, 200);
  }, [thirdRender]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     screenshot();
  //   }, 200);
  // }, [rectangles]);
  const buildImgProperty = (imgUrl) => {
    const props = {
      side: activeSideName,
      x: 20,
      y: 20,
      width: imageDiskWidth,
      height: imageDiskHeight,
      id: `rect${idCount}`,
      imgUrl,
      parentId: activeImage.id,
    };
    setIdCount((prevCountValue) => prevCountValue + 1);
    return props;
  };

  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === activeImage.id
  );

  const changeActiveImage = (image, sideName) => {
    if (!isScreenshotFetching) {
      setTimeout(() => {
        setActiveImagesSide(sideName);
        setActiveImage(image);
      }, 200);
    }
  };
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const onChangeInputValues = (name) => (e) => {
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    if (value) setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const checkRequired = () => {
    let approved = [];
    requiredFields.forEach((fieldName, index) => {
      if (fieldName === "audio" && values.audio?.length === 0 && isEditAudio) {
        setErrors((prevState) => ({
          ...prevState,
          audio: "The field is required",
        }));
        return;
      }
      if (!values[fieldName]) {
        if (fieldName === "audio" && !isEditAudio) {
          setErrors((prevState) => ({ ...prevState, audio: null }));
          approved.push(true);
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [fieldName]: "The field is required",
          }));
          approved.push(false);
        }
      } else if (values[fieldName]) {
        setErrors((prevState) => ({ ...prevState, [fieldName]: null }));
        approved.push(true);
      }
    });

    return approved;
  };
  const generateTracks = (side, data) => {
    return { side, data };
  };

  const generateFormData = async () => {
    const formData = new FormData();
    let ACompleteImageDisk, ACompleteImageBox, BCompleteImageBox;

    if (isEditImages) {
      const AStringUrlImgDisk = completeImages["a_side"];
      const AStringUrlImgBox = completeImages["c_side"];
      const BStringUrlImgBox = completeImages["d_side"];
      if (AStringUrlImgDisk) {
        ACompleteImageDisk = await dataURLtoFile(
          AStringUrlImgDisk,
          `${getRandomString(20)}.png`
        );
        formData.append("a_side", ACompleteImageDisk);
      }
      if (AStringUrlImgBox) {
        ACompleteImageBox = await dataURLtoFile(
          AStringUrlImgBox,
          `${getRandomString(20)}.png`
        );
        formData.append("front", ACompleteImageBox);
      }
      if (BStringUrlImgBox) {
        BCompleteImageBox = await dataURLtoFile(
          BStringUrlImgBox,
          `${getRandomString(20)}.png`
        );
        formData.append("back", BCompleteImageBox);
      }
    }

    if (values["comment"] && values["comment"] !== product.description) {
      formData.append("description", values["comment"]);
    }
    if (values["a_side"] && values["a_side"] !== getTracksASide()) {
      formData.append(
        "tracks",
        JSON.stringify([generateTracks("a_side", values["a_side"])])
      );
    }
    if (values["name"] !== product.name) {
      formData.append("name", values["name"]);
    }

    // if (values["template"] !== template._id) {
    //   formData.append("template", template._id);
    // }
    if (values["price"] !== product.price) {
      formData.append("price", values["price"]);
    }

    if (isEditImages) {
      if (detailInfoFiles["a_side"]) {
        formData.append("originals_a_side", detailInfoFiles["a_side"]);
      }
      if (detailInfoFiles["c_side"]) {
        formData.append("originals_front", detailInfoFiles["c_side"]);
      }
      if (detailInfoFiles["d_side"]) {
        formData.append("originals_back", detailInfoFiles["d_side"]);
      }
    }

    if (isEditAudio) {
      values["audio"].forEach((file) => {
        formData.append("audio", file);
      });
    }

    // formData.append("collection_id", template._id);
    return formData;
  };

  const onSubmit = async () => {
    const data = await generateFormData();
    // for (let pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1].toString());
    // }
    dispatch(editRejected(data, product._id, ProductType.CD));
    setIsSendData(true);
  };

  const setOriginalImage = (side) => (image) => {
    setCompleteImages((prevState) => ({ ...prevState, [side]: image }));
  };
  const checkAvailableImages = () => {
    const sides = ["a_side", "c_side", "d_side"];
    sides.forEach((side) => {
      const isBox = side === "c_side" || side === "d_side";
      if (!completeImages[side]) {
        srcToFile(isBox ? BoxSide : DiskSide, getRandomString(20)).then(
          (file) => {
            const imgInBase64 = encodeImageFileAsURL(
              file,
              setOriginalImage(side)
            );
            setCompleteImages((prevState) => ({
              ...prevState,
              [side]: imgInBase64,
            }));
          }
        );
      }
    });
  };

  const onOpenModal = () => {
    const checkFiles =
      Object.values(detailInfoFiles).length >= 3 || !isEditImages;

    if (checkFiles) {
      const approved = checkRequired();
      const allApproved = approved.every((element) => element);
      checkAvailableImages();

      if (
        !Object.values(errors).filter((value) => value).length &&
        allApproved
      ) {
        selectShape(null);
        setOpenModal(true);
      } else {
        setTotalCheckFields(true);
        timer = setTimeout(setTotalCheckFields, 4000, false);
      }
      setErrors((prevState) => ({ ...prevState, files: "" }));
    } else {
      setTotalCheckFields(true);
      timer = setTimeout(setTotalCheckFields, 4000, false);
      isEditImages &&
        setErrors((prevState) => ({
          ...prevState,
          files: "Please upload image(s)",
        }));
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  function simulateClick(elem) {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    elem && elem.dispatchEvent(evt);
  }

  const screenshot = () => {
    if (
      rectangles?.length &&
      rectangles?.find((r) => r.side === activeSideName)
    ) {
      setScreenshotSave(false);
      setIsScreenshotFetching(true);

      const node = document.getElementById("stage-node");
      const content = document.querySelector("canvas");
      if (content) simulateClick(content);
      if (node) {
        domtoimage
          .toPng(node)
          .then(function (dataUrl) {
            setCompleteImages((prevState) => ({
              ...prevState,
              [activeImage?.side]: dataUrl,
            }));
            setScreenshotSave(true);
          })
          .catch(function (error) {
            setIsScreenshotFetching(false);
            console.error("oops, something went wrong!", error);
          });
      }
    }
    // setScreenshotSave(false);
    // setIsScreenshotFetching(true);
    // const node = document.getElementById("stage-node");
    // const content = document.querySelector("canvas");
    // simulateClick(content);
    // domtoimage
    //   .toPng(node)
    //   .then(function (dataUrl) {
    //     setCompleteImages((prevState) => ({
    //       ...prevState,
    //       [activeImage.side]: dataUrl,
    //     }));
    //     setScreenshotSave(true);
    //   })
    //   .catch(function (error) {
    //     console.error("oops, something went wrong!", error);
    //   });
  };
  useEffect(() => {
    if (screenshotSave) setIsScreenshotFetching(false);
  }, [screenshotSave]);

  const onSetFiles = (file) => {
    setFiles((prevState) => [...prevState, file]);
    if (file)
      setDetailInfoFiles((prevState) => ({
        ...prevState,
        [activeImage.side]: file,
      }));
  };

  const onSetAudioFiles = (files) => {
    const isInValues = Array.isArray(files)
      ? values.audio?.some((a) => files.some((file) => file.name === a.name))
      : values.audio?.some((a) => a.name === files.name);
    if (isInValues) {
      setErrors((prevState) => {
        const error = {
          ...prevState,
          audio: "You can`t upload same tracks",
        };
        return error;
      });
      return;
    }

    const checkFiles = files.some(
      (file) =>
        !(
          file.type.includes("mpeg") ||
          file.type.includes("mp4") ||
          file.type.includes("wav") ||
          file.type.includes("x-flac")
        )
    );

    if (!checkFiles) {
      setValues((prevState) => ({
        ...prevState,
        audio: [...(prevState.audio || []), ...files],
      }));
      setErrors((prevState) => ({ ...prevState, audio: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        audio: "All audio files must be mp3, mp4, wav or oog formats. Also ",
      }));
    }
  };

  const onDeleteAudio = (id) => {
    setValues((prevState) => ({
      ...prevState,
      audio: prevState.audio.filter((item) => item.lastModified !== id),
    }));
    if (values.audio?.length === 1) {
      setErrors((prevState) => ({
        ...prevState,
        audio: "The field is required",
      }));
    }
  };

  const isEditImagesChange = () => {
    setIsEditImages(!isEditImages);
    setErrors((prevState) => ({
      ...prevState,
      files: "",
    }));
  };

  const isEditAudioChange = () => {
    setIsEditAudio(!isEditAudio);
    setErrors((prevState) => ({
      ...prevState,
      audio: "",
    }));
  };

  if (isSendData && !isLoading) return <Redirect to={"/"} />;
  return activeImage && !isLoading ? (
    <div
      className={`product product__wrapper ${
        activeImage.disk ? "disk" : "box"
      }`}
    >
      <div className="back-btn" style={{ paddingTop: "20px" }}>
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" style={{ color: "black" }} /> Back to
          Dashboard
        </IconButton>
      </div>
      <div className="product__content">
        <div>
          <FormControlLabel
            style={{ paddingLeft: "20px" }}
            control={
              <Switch
                checked={isEditImages}
                onChange={isEditImagesChange}
                name="checkedImages"
                color="primary"
                // inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Need to upload new images?"
          />
          {!isEditImages && (
            <div
              className="moderate-product__images"
              style={{ flexDirection: "column" }}
            >
              <p>Product images with design</p>
              <div className="moderate-product__images-container">
                {product?.images.map((image, index) => (
                  <ImagePreview
                    key={index}
                    withBorder
                    src={image.path}
                    // handleClick={() => openImage(image.path)}
                  />
                ))}
              </div>
            </div>
          )}

          {isEditImages && (
            <div>
              <div className="product__images images">
                <div className="customize__stage stage">
                  <div
                    id="stage-node"
                    className="stage__stage-block"
                    style={{
                      backgroundImage: `url("${activeImage.path}")`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: stageWidth,
                      height: stageHeight,
                      position: "relative",
                    }}
                  >
                    <div className="print-area" style={styleProps}>
                      <Stage
                        width={styleProps.width}
                        height={styleProps.height}
                        onMouseDown={checkDeselect}
                        onTouchStart={checkDeselect}
                        ref={canvasRef}
                        style={{
                          borderRadius: "5px",
                          display: "flex",
                          margin: "0 auto",
                          cursor: "pointer",
                        }}
                        onMouseLeave={() => {
                          setTimeout(() => {
                            screenshot();
                          }, 600);
                        }}
                        // onDragEnd={screenshot}
                        // onTransformEnd={screenshot}
                        // onTouchEnd={screenshot}
                        // onMouseUp={() => {
                        //   screenshot();
                        // }}
                      >
                        {rectanglesByImage.map((rect, i) => {
                          return (
                            <Layer key={i + "layer"}>
                              <Img
                                imageWidth={imageDiskWidth}
                                imageHeight={imageDiskHeight}
                                canvasRef={canvasRef}
                                shapeProps={rect}
                                isSelected={rect.id === selectedId}
                                onSelect={() => {
                                  selectShape(rect.id);
                                }}
                                width={stageWidth}
                                height={stageHeight}
                                onChange={(newAttrs) => {
                                  const rects = rectangles.slice();
                                  const rectIndex = rects.findIndex(
                                    (e) => newAttrs.id === e.id
                                  );
                                  rects[rectIndex] = newAttrs;
                                  setRectangles(rects);
                                }}
                              />
                            </Layer>
                          );
                        })}
                      </Stage>
                      {activeImage.disk && (
                        <div className="circle-background" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="image-options">
                  <div className="sides-wrapper">
                    {sidesConfig.map((side) => (
                      <div className={`image-container`} key={side.id}>
                        <img
                          src={side.path}
                          alt=""
                          className={`image_container ${
                            activeSideName === side.side
                              ? "image_container_active"
                              : ""
                          }`}
                          data-index={side.id}
                          onClick={() => changeActiveImage(side, side.side)}
                        />
                        <p>{side.name}*</p>
                      </div>
                    ))}
                  </div>
                  <div className="sides-wrapper">
                    {boxesConfig.map((side) => (
                      <div className={`image-container`} key={side.id}>
                        <img
                          src={side.path}
                          alt=""
                          className={`image_container ${
                            activeSideName === side.side
                              ? "image_container_active"
                              : ""
                          }`}
                          data-index={side.id}
                          onClick={() => changeActiveImage(side, side.side)}
                        />
                        <p>{side.name}*</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="upload__files">
                {errors.files && <div className="error">{errors.files}</div>}
                <div style={{ height: "35px" }}>
                  {isScreenshotFetching && (
                    <div className="error">
                      Please wait, your product is processing
                    </div>
                  )}
                </div>
                <FileUploader
                  // files={files}
                  isDisabled={isScreenshotFetching}
                  onSetFiles={onSetFiles}
                  onImageLoaded={handleImageLoaded}
                />
                <div className="explanation__block">* - required</div>
              </div>
            </div>
          )}
        </div>
        <div className="product__description description">
          <div className="description__main-info">
            <p>Description:</p>
            <div className="description__info">{productDescription}</div>
          </div>
          <div className="items">
            <div className="price-block__price block_positions">
              <p>CD name</p>
              <TextField
                required
                type="text"
                id="outlined-basic"
                variant="outlined"
                name={"name"}
                value={values.name}
                onChange={onChangeInputValues("name")}
                error={errors?.name}
                helperText={errors?.name && "Field is required"}
                placeholder={"сd name*"}
              />
            </div>
            <div className="price-block__price block_positions">
              Track order
              <TextField
                multiline
                rows={4}
                required
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"price"}
                value={values.a_side}
                onChange={onChangeInputValues("a_side")}
                error={errors?.a_side}
                helperText={errors?.a_side && "Field is required"}
              />
            </div>
            <div className="linear__block block_positions">
              <p>Comment</p>
              <TextField
                multiline
                rows={4}
                type="text"
                id="outlined-basic"
                label="comment"
                variant="outlined"
                name={"comment"}
                value={values.comment}
                onChange={onChangeInputValues("comment")}
              />
            </div>
            <div className="price-block__price block_positions">
              Set Price Of product
              <TextField
                required
                type="number"
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name={"price"}
                value={values.price}
                onChange={onChangeInputValues("price")}
                InputProps={{
                  inputProps: {
                    min: product?.template?.manufacturing_cost || 10,
                  },
                }}
                error={errors?.price}
                helperText={errors?.price && "Field is required"}
              />
            </div>
            <div className="description__cost-text">
              Manufacturing Cost:{" "}
              <span style={{ fontWeight: "bold" }}>
                ${product?.template?.manufacturing_cost || "5"}
              </span>
            </div>
          </div>
          <div className="upload__files">
            <p>Product audios</p>

            <FormControlLabel
              control={
                <Switch
                  checked={isEditAudio}
                  onChange={isEditAudioChange}
                  name="checkedImages"
                  color="primary"
                  // inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Need to upload new audio?"
            />
            {!isEditAudio && (
              <List>
                {product?.audio.map((audio, index) => {
                  const name = audio.split("/");
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${name[name.length - 1]}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
            {isEditAudio && (
              <div style={{ paddingTop: "20px" }}>
                {values?.audio?.map((audioFile, i) => {
                  return (
                    <div className="upload__files-list" key={i}>
                      <div className={"audio_item"}>
                        {audioFile.name}
                        <img
                          src={CrossIcon}
                          alt=""
                          className={"remove_audio_icon"}
                          onClick={() => onDeleteAudio(audioFile.lastModified)}
                        />
                      </div>
                    </div>
                  );
                })}

                {errors?.audio && <div className="error">{errors.audio}</div>}
                <FileUploader
                  title={"Upload audio files"}
                  onSetFiles={onSetAudioFiles}
                  multiple={true}
                />
              </div>
            )}
          </div>
          <div className="description__button-block marginTop">
            <Dialog
              open={openModal}
              onClose={closeModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Complete editing and send to moderation?"}
              </DialogTitle>
              <DialogContent
                style={{
                  minHeight: isEditImages ? 500 : 70,
                  minWidth: 500,
                }}
              >
                {isEditImages && (
                  <ModerationSlider
                    completeImages={Object.values(completeImages)}
                  />
                )}

                {isScreenshotFetching && (
                  <div className="error">
                    Please wait, your product is processing
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <ButtonCreate
                  text={"Back to Designing"}
                  handleClick={closeModal}
                />

                <ButtonCreate
                  isDisabled={false}
                  text={"Send to Moderation"}
                  handleClick={onSubmit}
                />
              </DialogActions>
            </Dialog>
            <Collapse in={totalCheckFields}>
              <Alert color="error">Please, check required fields</Alert>
            </Collapse>
            <ButtonCreate
              className="create-design"
              text={"Save"}
              size={"large"}
              fullWidth={true}
              handleClick={onOpenModal}
            />
          </div>
        </div>
      </div>
    </div>
  ) : isLoading ? (
    <Preloader />
  ) : (
    <></>
  );
};
