import React from "react";
import "./styles.scss";

export const ManageTitle = ({ title, children }) => {
  return (
    <div className="manage-title">
      <div className="title">{title && <p>{title}</p>}</div>
      <div className="manage-title__btn">{children}</div>
    </div>
  );
};
