import {
  GET_ALL_TEMPLATES,
  ADD_NEW_TEMPLATE,
  DELETE_TEMPLATE_BY_ID,
  GET_TEMPLATE_BY_ID,
  IS_TEMPLATE_CREATED,
  IS_TEMPLATE_REJECTED,
  IS_EDIT_SUCCESS,
  IS_EDIT_CANCEL,
} from "./templatesTypes";
import { IS_CREATED_ADMIN } from "../users/usersTypes";

export function getTemplates(data) {
  return {
    type: GET_ALL_TEMPLATES,
    data,
  };
}

export function addTemplate(data) {
  return {
    type: ADD_NEW_TEMPLATE,
    data,
  };
}

export function getTemplateByIdMutation(data) {
  return {
    type: GET_TEMPLATE_BY_ID,
    data,
  };
}

export function deleteTemplate(id) {
  return {
    type: DELETE_TEMPLATE_BY_ID,
    id,
  };
}

export function isTemplateCreated(data) {
  return {
    type: IS_TEMPLATE_CREATED,
    data,
  };
}
export function isTemplateRejected(data) {
  return {
    type: IS_TEMPLATE_REJECTED,
    data,
  };
}
export function isEditSuccess(data) {
  return {
    type: IS_EDIT_SUCCESS,
    data,
  };
}
export function isEditCancel(data) {
  return {
    type: IS_EDIT_CANCEL,
    data,
  };
}
