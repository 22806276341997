import React, { useCallback, useEffect, useState } from "react";
import { ButtonCreate } from "../../components/ButtonCreate";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, CircularProgress } from "@material-ui/core";
import { getTemplateById } from "../../redux/templates/templatesActions";

///////////////////////////////////////////////////////////////////////
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.scss";

export const TemplateDetails = () => {
  const [mainImage, setMainImage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplateById(params.id));
  }, [dispatch, params.id]);

  const template = useSelector((state) => state.templatesReducer.template);
  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    setMainImage(template?.images && template?.images[0]?.path);
  }, [template]);

  const onToCustomize = () => {
    history.push(`/customize/${template._id}`);
  };
  console.log("template", template);

  return (
    <div className="product">
      <div className="breadcrumbs">
        <NavLink to="/" className="template_link">
          Templates
        </NavLink>{" "}
        > {template.name}
      </div>
      <div className="product__content">
        <div className="product__images images">
          <div className="images__main-image">
            <img
              src={mainImage}
              onLoad={onLoad}
              alt="main"
              style={{ display: loaded ? "block" : "none" }}
            />
            {!loaded && <CircularProgress />}
          </div>
          <div className="images__secondary-images">
            {template.images?.length &&
              template.images.map((image, index) => {
                console.log(image.path, "image");
                return (
                  <div className="images__secondary-image" key={index}>
                    <img
                      src={image.path}
                      alt="secondary"
                      // className="images__secondary-image"
                      onClick={() => setMainImage(image.path)}
                      style={{ display: loaded ? "block" : "none" }}
                    />
                    {!loaded && <CircularProgress />}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="product__description description">
          <div className="description__name">{template.name}</div>
          <div className="description__main-info">
            <p>Description:</p>
            <div className="description__info">{template.description}</div>
          </div>
          <div>
            <div className="description__select">Available sizes:</div>
            <div className="description__sizes">
              {template.sizes?.map((size, index) => {
                return (
                  <Avatar
                    key={index}
                    style={{ backgroundColor: "#26c4ff", marginBottom: 20 }}
                  >
                    {size}
                  </Avatar>
                );
              })}
            </div>

            <div className="description__cost-text">
              Manufacturing Cost:{" "}
              <span style={{ fontWeight: "bold" }}>
                {template.manufacturing_cost}$
              </span>
            </div>
          </div>
          <div className="description__button-block">
            <ButtonCreate
              className="create-design"
              text={"Create Your Design"}
              size={"large"}
              fullWidth={true}
              handleClick={onToCustomize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
