import axios from "axios";
import { toggleIsFetching } from "../common/commonMutations";
import {
  addVendor,
  getAdmins,
  removeUser,
  getUserInfo,
  isCreatedAdmin,
  isRejectedAdmin,
  getUsers,
} from "./usersMutations";

const server = process.env.REACT_APP_SERVER_URL || "https://api.cloudkicker.io";

export function getAdminList() {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`${server}/admin-list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getAdmins(response.data.reverse()));
          dispatch(isCreatedAdmin(false));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    }
  };
}

//manage add new admin page
export function addNewAdmin(admin) {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .post(`${server}/add-admin`, admin, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(isCreatedAdmin(true));
        })
        .catch((e) => {
          dispatch(isRejectedAdmin(true));
          console.log("ERR:", e);
        });
    }
  };
}

export function addNewVendor(vendor) {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .post(
          `${server}/add-vendor`,
          { vendor },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(addVendor(response.data));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    }
  };
}

export function removeUserById(userId) {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      axios
        .delete(`${server}/remove-user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(removeUser(userId));
        })
        .catch((e) => {
          console.log("error when delete", e);
        });
    }
  };
}

export function getUserProfileInfo() {
  return (dispatch, getState) => {
    dispatch(toggleIsFetching(true));
    const token = getState().authReducer.token;
    if (token) {
      axios
        .get(`${server}/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getUserInfo(response.data));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    }
  };
}

export function getUsersInfo() {
  return (dispatch, getState) => {
    dispatch(toggleIsFetching(true));
    const token = getState().authReducer.token;
    if (token) {
      axios
        .get(`${server}/vendor_list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getUsers(response.data));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    }
  };
}
