import React from "react";
import { ButtonCreate } from "../../components/ButtonCreate";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/PC_logo_500x.png";
import "./styles.scss";

export const SignUpSuccess = () => {
  const history = useHistory();
  return (
    <div className="signup-success">
      <h2>registration success! now log in</h2>
      <img src={logo} alt="success" />
      <ButtonCreate
        text="Log In"
        handleClick={() => history.push("/login")}
        width={200}
      />
    </div>
  );
};
