import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { ButtonCreate } from "../../../components/ButtonCreate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../../CustomizeProductPage/components/FileUploader/FileUploader";
import VinylBlack from "./images/black_vinyl.jpg";
import VinylClear from "./images/ClearVinyl.png";
import BoxSide from "./images/box-template.png";
import { Layer, Stage } from "react-konva";
import { Img } from "../../CustomizeProductPage/components/Img/Img";
import domtoimage from "dom-to-image";
import DialogContent from "@material-ui/core/DialogContent";
import { ModerationSlider } from "../../CustomizeProductPage/ModerationSlider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {
  addNewProduct,
  editRejected,
  getRejectedProducts,
  ProductType,
} from "../../../redux/products/productsActions";
import { srcToFile, dataURLtoFile, getRandomString } from "../../../utils";
import { encodeImageFileAsURL } from "../../../utils/converteImageToBase64";
import { getTemplateById } from "../../../redux/templates/templatesActions";
import { Preloader } from "../../../components/Preloader";
import CrossIcon from "../../../assets/images/close.svg";
import "./styles.scss";
import { ImagePreview } from "../../../components/ImagePreview";
import Alert from "@material-ui/lab/Alert";

const initialRectangles = [];

const stageWidth = 600;
const stageHeight = 600;
const imageDiskWidth = 200;
const imageDiskHeight = 200;
const imageBoxWidth = 399;
const imageBoxHeight = 399;

//sidesConfig[color].path

const sidesConfig2 = {
  clear: [
    {
      name: "side A-disk",
      id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
      path: VinylClear,
      disk: true,
      side: "a_side",
      color: "clear",
    },
    {
      name: "side B-disk",
      id: "dffhc26f-497e-47ad-aa1c-21dc7fbd025a",
      path: VinylClear,
      disk: true,
      side: "b_side",
      color: "clear",
    },
  ],
  black: [
    {
      name: "side A-disk",
      id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
      path: VinylBlack,
      disk: true,
      side: "a_side",
      color: "black",
    },
    {
      name: "side B-disk",
      id: "dffhc26f-497e-47ad-aa1c-21dc7fbd025a",
      path: VinylBlack,
      disk: true,
      side: "b_side",
      color: "black",
    },
  ],
};

const sidesConfig = [
  {
    name: "side A-disk",
    id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: VinylBlack,
    disk: true,
    side: "a_side",
    color: "black",
  },
  {
    name: "side B-disk",
    id: "dffhc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: VinylBlack,
    disk: true,
    side: "b_side",
    color: "black",
  },
  {
    name: "side A-disk",
    id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: VinylClear,
    disk: true,
    side: "a_side",
    color: "clear",
  },
  {
    name: "side B-disk",
    id: "dffhc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: VinylClear,
    disk: true,
    side: "b_side",
    color: "clear",
  },
];

const boxesConfig = [
  {
    name: "side A-box",
    id: "3160ba8d-1c3b-4ced-ba85-f19948cb76d7",
    path: BoxSide,
    disk: false,
    side: "c_side",
  },
  {
    name: "side B-box",
    id: "5521c296-55a2-11eb-ae93-0242ac130002",
    path: BoxSide,
    disk: false,
    side: "d_side",
  },
];

const colorsConfig = [
  {
    id: 0,
    name: "black",
    value: "black",
  },
  {
    id: 1,
    name: "clear",
    value: "clear",
  },
];

const sizesConfig = [
  {
    id: 0,
    size: "7",
    price: "12",
  },
  {
    id: 1,
    size: "10",
    price: "17",
  },
  {
    id: 2,
    size: "12",
    price: "23",
  },
];

const requiredFields = ["a_side", "b_side", "price", "name", "audioA"];
let timer;
const productDescription =
  "Vinyl: Please enter all information below to create your custom Record and Jacket. Once submitted our Admins review each product before setting it live to your store. Max recording time for 12 vinyl is 18 mins per side Max recording time for 10 vinyl is 12 mins per side Max recording time for 7 vinyl is 7 mins per side.";

export const EditRejectedVinyl = () => {
  const rejectedProducts = useSelector(
    (state) => state.productsReducer.rejectedProducts
  );
  console.log("rejectedProducts", rejectedProducts);
  const params = useParams();
  const product = rejectedProducts?.find(
    (product) => product._id === params.id
  );
  console.log("template", product);

  const [isEditImages, setIsEditImages] = useState(false);
  const [isEditAudio, setIsEditAudio] = useState(false);

  const [activeImage, setActiveImage] = useState(null);
  const [activeSideName, setActiveImagesSide] = useState("a_side");
  const [selectedId, selectShape] = useState(null);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [idCount, setIdCount] = useState(0);
  const [values, setValues] = useState({
    vinylColor: "clear",
    vinylSize: "7",
    audioA: [],
    audioB: [],
  });

  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const canvasRef = React.useRef();

  const styleProps = {
    position: "relative",
    backgroundColor: "transparent",
    top: activeImage?.disk ? "50%" : "48%",
    left: "50%",
    width: activeImage?.disk ? imageDiskWidth : imageBoxWidth,
    height: activeImage?.disk ? imageDiskHeight : imageBoxHeight,
    borderRadius: activeImage?.disk ? "50%" : 0,
    transform: "translate(-50%, -50%)",
  };

  const [files, setFiles] = useState([]);
  const [completeImages, setCompleteImages] = useState({});
  const [detailInfoFiles, setDetailInfoFiles] = useState({});
  const [isSendData, setIsSendData] = useState(false);
  const [screenshotSave, setScreenshotSave] = useState(false);
  const [isScreenshotFetching, setIsScreenshotFetching] = useState(false);
  // const template = useSelector((state) => state.templatesReducer.template);
  const [totalCheckFields, setTotalCheckFields] = useState(false);

  const [uploadFirstImage, setUploadNewImage] = useState(0);
  const [secondRender, setSecondRender] = useState(0);
  const [thirdRender, setThirdRender] = useState(0);

  useEffect(() => {
    if (!totalCheckFields) {
      clearTimeout(timer);
    }
  }, [totalCheckFields]);

  const isLoading = useSelector((state) => state.commonReducer.isFetching);
  const dispatch = useDispatch();

  console.log("completeImages", completeImages);
  const getTracksSide = (tracks, side) => {
    if (tracks) {
      const trackParse = JSON.parse(tracks);
      return trackParse.find((t) => t.side === side)?.data || "";
    }
    return "";
  };

  useEffect(() => {
    setActiveImage(product && sidesConfig2[product?.vinylColor][0]);
    setActiveImagesSide("a_side");

    setValues({
      a_side: getTracksSide(product?.tracks, "a_side"),
      b_side: getTracksSide(product?.tracks, "b_side"),
      vinylSize: product?.vinylSize || 7,
      vinylColor: product?.vinylColor || "clear",
      name: product?.name,
      comment: product?.description,
      price: product?.price,
      audioA: [],
      audioB: [],
    });
    setFiles([]);
    setRectangles(initialRectangles);
  }, [product]);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(getRejectedProducts());
  }, [dispatch]);
  const generateVinylPrice = () => {
    if (values.vinylSize === "7") {
      return "12";
    }
    if (values.vinylSize === "10") {
      return "17";
    } else return "23";
  };

  useEffect(() => {
    dispatch(getTemplateById("vinyl"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemplateById("vinyl"));
  }, [dispatch]);

  useEffect(() => {
    setUploadNewImage((prevState) => ++prevState);
  }, [rectangles]);

  useEffect(() => {
    setTimeout(() => {
      setSecondRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [uploadFirstImage]);

  useEffect(() => {
    setTimeout(() => {
      setThirdRender((prevState) => ++prevState);
      // screenshot();
    }, 200);
  }, [secondRender]);

  useEffect(() => {
    setTimeout(() => {
      screenshot();
    }, 200);
  }, [thirdRender]);

  const handleImageLoaded = (imgUrl) => {
    const checkFiles = Object.values(completeImages).length >= 3;
    if (checkFiles) {
      setErrors((prevState) => ({ ...prevState, files: "" }));
    }
    const newImg = buildImgProperty(imgUrl);
    if (rectangles.find((r) => r.side === activeSideName)) {
      const index = rectangles.findIndex((r) => r.side === activeSideName);
      setRectangles((prevState) => {
        const newR = [...prevState];
        newR[index] = newImg;
        return [...newR];
      });
    } else {
      setRectangles([...rectangles, newImg]);
    }
    setIsScreenshotFetching(true);
    // setTimeout(() => {
    //   screenshot();
    // }, 100);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     screenshot();
  //   }, 200);
  // }, [rectangles]);
  const buildImgProperty = (imgUrl) => {
    const props = {
      side: activeSideName,
      x: 10,
      y: 10,
      width: imageDiskWidth,
      height: imageDiskHeight,
      id: `rect${idCount}`,
      imgUrl,
      parentId: activeImage.id,
    };
    setIdCount((prevCountValue) => prevCountValue + 1);
    return props;
  };

  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === activeImage.id
  );

  const changeActiveImage = (image, sideName) => {
    if (!isScreenshotFetching) {
      setTimeout(() => {
        setActiveImagesSide(sideName);
        setActiveImage(image);
      }, 200);
    }
  };
  useEffect(() => {
    setActiveImage(sidesConfig2[values.vinylColor][0]);
    setActiveImagesSide("a_side");
    setCompleteImages({});
    setRectangles([]);
    setDetailInfoFiles({});
    setFiles([]);
  }, [values.vinylColor]);

  useEffect(() => {
    setTimeout(() => {
      screenshot();
    }, 500);
  }, [activeImage?.path]);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const onChangeInputValues = (name) => (e) => {
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    if (value) setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const checkRequired = () => {
    let approved = [];

    requiredFields.forEach((fieldName, index) => {
      if (fieldName === "audioA" && !isEditAudio) {
        approved.push(true);
        return;
      }
      if (fieldName === "b_side" && !values?.b_side) {
        if (values.audioB.length > 0) {
          setErrors((prevState) => ({
            ...prevState,
            b_side: "The field is required",
          }));
          approved.push(false);
        } else {
          setErrors((prevState) => ({
            ...prevState,
            b_side: null,
          }));
        }
      } else {
        if (!values[fieldName] || values[fieldName].length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            [fieldName]: "The field is required",
          }));
          approved.push(false);
        } else if (values[fieldName] || values[fieldName].length === 0) {
          setErrors((prevState) => ({ ...prevState, [fieldName]: null }));
          approved.push(true);
        }
      }
    });

    return approved;
  };

  const generateTracks = (side, data) => {
    return { side, data };
  };

  const generateFormData = async () => {
    const formData = new FormData();
    if (isEditImages) {
      let ACompleteImageDisk,
        BCompleteImageDisk,
        ACompleteImageBox,
        BCompleteImageBox;
      const AStringUrlImgDisk = completeImages["a_side"];
      const BStringUrlImgDisk = completeImages["b_side"];
      const AStringUrlImgBox = completeImages["c_side"];
      const BStringUrlImgBox = completeImages["d_side"];
      if (AStringUrlImgDisk) {
        ACompleteImageDisk = await dataURLtoFile(
          AStringUrlImgDisk,
          `${getRandomString(20)}.png`
        );
        formData.append("a_side", ACompleteImageDisk);
      }

      if (BStringUrlImgDisk) {
        BCompleteImageDisk = await dataURLtoFile(
          BStringUrlImgDisk,
          `${getRandomString(20)}.png`
        );
        formData.append("b_side", BCompleteImageDisk);
      }
      if (AStringUrlImgBox) {
        ACompleteImageBox = await dataURLtoFile(
          AStringUrlImgBox,
          `${getRandomString(20)}.png`
        );
        formData.append("front", ACompleteImageBox);
      }
      if (BStringUrlImgBox) {
        BCompleteImageBox = await dataURLtoFile(
          BStringUrlImgBox,
          `${getRandomString(20)}.png`
        );
        formData.append("back", BCompleteImageBox);
      }
      if (detailInfoFiles["a_side"]) {
        formData.append("originals_a_side", detailInfoFiles["a_side"]);
      }
      if (detailInfoFiles["b_side"]) {
        formData.append("originals_b_side", detailInfoFiles["b_side"]);
      }
      if (detailInfoFiles["c_side"]) {
        formData.append("originals_front", detailInfoFiles["c_side"]);
      }
      if (detailInfoFiles["d_side"]) {
        formData.append("originals_back", detailInfoFiles["d_side"]);
      }
    }

    // if (values["comment"] && values["comment"] !== product) formData.append("comment", values["comment"]);

    if (values["comment"] && values["comment"] !== product.description) {
      formData.append("description", values["comment"]);
    }
    if (isEditAudio) {
      values["audioA"].forEach((file) => {
        formData.append("audioA", file);
      });
      if (values["audioB"].length > 0) {
        values["audioB"].forEach((file) => {
          formData.append("audioB", file);
        });
      }
    }

    const tracks = [generateTracks("a_side", values["a_side"])];
    if (values["b_side"]) {
      tracks.push(generateTracks("b_side", values["b_side"]));
    }
    if (JSON.stringify(tracks) !== product.tracks) {
      formData.append("tracks", JSON.stringify(tracks));
    }
    if (values["name"] !== product.name) {
      formData.append("name", values["name"]);
    }
    if (values["price"] !== product.price) {
      formData.append("price", values["price"]);
    }
    if (values.vinylColor !== product.vinylColor) {
      formData.append("vinylColor", values.vinylColor);
    }
    if (values.vinylSize !== product.vinylSize) {
      formData.append("vinylSize", values.vinylSize);
    }
    return formData;
  };

  const onSubmit = async () => {
    const data = await generateFormData();
    for (let pair of data.entries()) {
      console.log(pair[0] + ", " + pair[1].toString());
    }
    dispatch(editRejected(data, product._id, ProductType.VINYL));
    setIsSendData(true);
  };

  const setOriginalImage = (side) => (image) => {
    setCompleteImages((prevState) => ({ ...prevState, [side]: image }));
  };

  const checkAvailableImages = () => {
    const sides = ["a_side", "c_side", "d_side"];
    sides.forEach((side) => {
      const isBox = side === "c_side" || side === "d_side";
      if (!completeImages[side]) {
        srcToFile(isBox ? BoxSide : VinylBlack, getRandomString(20)).then(
          (file) => {
            const imgInBase64 = encodeImageFileAsURL(
              file,
              setOriginalImage(side)
            );
            setCompleteImages((prevState) => ({
              ...prevState,
              [side]: imgInBase64,
            }));
          }
        );
      }
    });
  };

  const onOpenModal = () => {
    const checkFiles =
      Object.values(completeImages).length === 4 || !isEditImages;

    if (checkFiles) {
      const approved = checkRequired();
      const allApproved = approved.every((element) => element);
      console.log("approved", approved);
      checkAvailableImages();
      if (
        !Object.values(errors).filter((value) => value).length &&
        allApproved
      ) {
        selectShape(null);
        setOpenModal(true);
      } else {
        setTotalCheckFields(true);
        timer = setTimeout(setTotalCheckFields, 4000, false);
      }
      setErrors((prevState) => ({ ...prevState, files: "" }));
    } else {
      setTotalCheckFields(true);
      timer = setTimeout(setTotalCheckFields, 4000, false);
      setErrors((prevState) => ({
        ...prevState,
        files: "Please check image(s)",
      }));
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  function simulateClick(elem) {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    elem && elem.dispatchEvent(evt);
  }

  const screenshot = () => {
    if (
      rectangles?.length &&
      rectangles?.find((r) => r.side === activeSideName)
    ) {
      setScreenshotSave(false);
      setIsScreenshotFetching(true);

      const node = document.getElementById("stage-node");
      const content = document.querySelector("canvas");
      if (content) simulateClick(content);
      if (node) {
        domtoimage
          .toPng(node)
          .then(function (dataUrl) {
            setCompleteImages((prevState) => ({
              ...prevState,
              [activeImage?.side]: dataUrl,
            }));
            setScreenshotSave(true);
          })
          .catch(function (error) {
            setIsScreenshotFetching(false);
            console.error("oops, something went wrong!", error);
          });
      }
    }
  };

  useEffect(() => {
    if (screenshotSave) setIsScreenshotFetching(false);
  }, [screenshotSave]);

  const onSetFiles = (file) => {
    setFiles((prevState) => [...prevState, file]);
    if (file)
      setDetailInfoFiles((prevState) => ({
        ...prevState,
        [activeImage.side]: file,
      }));
  };

  const onSetAudioFiles = (audioSide, files) => {
    const allAudioSidesFiles = [...values.audioA, ...values.audioB];
    const isInValues = Array.isArray(files)
      ? allAudioSidesFiles?.some((a) =>
          files.some((file) => file.name === a.name)
        )
      : allAudioSidesFiles?.some((a) => a.name === files.name);
    if (isInValues) {
      setErrors((prevState) => {
        const error = {
          ...prevState,
          [audioSide]: "You can`t upload same tracks",
        };
        return error;
      });
      return;
    }

    const checkFiles = files.some(
      (file) =>
        !(
          file.type.includes("mpeg") ||
          file.type.includes("mp4") ||
          file.type.includes("wav") ||
          file.type.includes("x-flac")
        )
    );

    if (!checkFiles) {
      setValues((prevState) => ({
        ...prevState,
        [audioSide]: [...(prevState[audioSide] || null), ...files],
      }));
      setErrors((prevState) => ({ ...prevState, [audioSide]: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [audioSide]: "All audio files must be mp3, mp4, wav or oog formats",
      }));
    }
  };
  const onSetAudioFilesForASide = (files) => onSetAudioFiles("audioA", files);
  const onSetAudioFilesForBSide = (files) => onSetAudioFiles("audioB", files);

  // const onDeleteAudio = (id) => {
  //   setValues((prevState) => ({
  //     ...prevState,
  //     audio: prevState.audio.filter((item) => item.lastModified !== id),
  //   }));
  // };
  const onDeleteAudio = (id, side) => {
    setValues((prevState) => ({
      ...prevState,
      [side]: prevState[side].filter((item) => item.lastModified !== id),
    }));
  };

  const isEditImagesChange = () => {
    setIsEditImages(!isEditImages);
    setErrors((prevState) => ({
      ...prevState,
      files: "",
    }));
  };

  const isEditAudioChange = () => {
    setIsEditAudio(!isEditAudio);
    setErrors((prevState) => ({
      ...prevState,
      audioA: "",
    }));
  };

  if (isSendData && !isLoading) return <Redirect to={"/"} />;
  return activeImage && !isLoading ? (
    <div
      className={`product product__wrapper ${
        activeImage.disk ? "disk" : "box"
      }`}
    >
      <div className="back-btn" style={{ paddingTop: "20px" }}>
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" style={{ color: "black" }} /> Back to
          Dashboard
        </IconButton>
      </div>
      <div className="product__content">
        <div>
          <FormControlLabel
            style={{ paddingLeft: "20px" }}
            control={
              <Switch
                checked={isEditImages}
                onChange={isEditImagesChange}
                name="checkedImages"
                color="primary"
              />
            }
            label="Need to upload new images?"
          />
          {!isEditImages && (
            <div
              className="moderate-product__images"
              style={{ flexDirection: "column" }}
            >
              <p>Product images with design</p>
              <div
                className="moderate-product__images-container"
                style={{ flexWrap: "wrap" }}
              >
                {product?.images.map((image, index) => (
                  <ImagePreview
                    key={index}
                    withBorder
                    src={image.path}
                    // handleClick={() => openImage(image.path)}
                  />
                ))}
              </div>
            </div>
          )}

          {isEditImages && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="product__images images">
                <div className="customize__stage stage">
                  <div
                    id="stage-node"
                    className="stage__stage-block"
                    style={{
                      backgroundImage: `url("${activeImage.path}")`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: stageWidth,
                      height: stageHeight,
                      position: "relative",
                    }}
                  >
                    <div className="print-area" style={styleProps}>
                      <Stage
                        width={styleProps.width}
                        height={styleProps.height}
                        onMouseDown={checkDeselect}
                        onTouchStart={checkDeselect}
                        ref={canvasRef}
                        style={{
                          borderRadius: "5px",
                          display: "flex",
                          margin: "0 auto",
                          cursor: "pointer",
                        }}
                        onMouseLeave={() => {
                          setTimeout(() => {
                            screenshot();
                          }, 600);
                        }}
                        // onDragEnd={screenshot}
                        // onTransformEnd={screenshot}
                        // onTouchEnd={screenshot}
                        // onMouseUp={() => {
                        //   screenshot();
                        // }}
                      >
                        {rectanglesByImage.map((rect, i) => {
                          return (
                            <Layer key={i + "layer"}>
                              <Img
                                imageWidth={imageDiskWidth}
                                imageHeight={imageDiskHeight}
                                canvasRef={canvasRef}
                                shapeProps={rect}
                                isSelected={rect.id === selectedId}
                                onSelect={() => {
                                  selectShape(rect.id);
                                }}
                                width={stageWidth}
                                height={stageHeight}
                                onChange={(newAttrs) => {
                                  const rects = rectangles.slice();
                                  const rectIndex = rects.findIndex(
                                    (e) => newAttrs.id === e.id
                                  );
                                  rects[rectIndex] = newAttrs;
                                  setRectangles(rects);
                                }}
                              />
                            </Layer>
                          );
                        })}
                      </Stage>
                      {activeImage.disk && (
                        <div className="circle-background" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="image-options">
                  <div className="sides-wrapper">
                    {sidesConfig2[values.vinylColor].map(
                      (side) =>
                        side.color === values.vinylColor && (
                          <div className={`image-container`} key={side.id}>
                            <img
                              src={side.path}
                              alt=""
                              className={`image_container ${
                                activeSideName === side.side
                                  ? "image_container_active"
                                  : ""
                              }`}
                              data-index={side.id}
                              onClick={() => changeActiveImage(side, side.side)}
                            />
                            <p>{side.name}*</p>
                          </div>
                        )
                    )}
                  </div>
                  <div className="sides-wrapper">
                    {boxesConfig.map((side) => (
                      <div className={`image-container`} key={side.id}>
                        <img
                          src={side.path}
                          alt=""
                          className={`image_container ${
                            activeSideName === side.side
                              ? "image_container_active"
                              : ""
                          }`}
                          data-index={side.id}
                          onClick={() => changeActiveImage(side, side.side)}
                        />
                        <p>{side.name}*</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="upload__files">
                {errors.files && <div className="error">{errors.files}</div>}
                <div style={{ height: "35px" }}>
                  {isScreenshotFetching && (
                    <div className="error">
                      Please wait, your product is processing
                    </div>
                  )}
                </div>

                <FileUploader
                  // files={files}
                  isDisabled={isScreenshotFetching}
                  onSetFiles={onSetFiles}
                  onImageLoaded={handleImageLoaded}
                />
                <div className="explanation__block">* - required</div>
              </div>
            </div>
          )}
        </div>

        <div className="product__description description">
          <div className="description__main-info">
            <p>Description:</p>
            <div className="description__info">{productDescription}</div>
          </div>
          <div className="items">
            <div className="linear__block block_positions">
              {isEditImages && (
                <>
                  <p>Vinyl Color:</p>
                  <TextField
                    select
                    type="text"
                    id="outlined-basic"
                    label="vinyl color"
                    variant="outlined"
                    name={"vinylColor"}
                    value={values.vinylColor}
                    onChange={onChangeInputValues("vinylColor")}
                  >
                    {colorsConfig.map((type) => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </div>
            <div className="linear__block block_positions">
              <p>Vinyl Size:</p>
              <TextField
                select
                type="text"
                id="outlined-basic"
                label="vinyl size"
                variant="outlined"
                name={"vinylSize"}
                value={values.vinylSize}
                onChange={onChangeInputValues("vinylSize")}
              >
                {sizesConfig.map((size) => (
                  <MenuItem key={size.id} value={size.size}>
                    {size.size}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="price-block__price block_positions">
              <p>Vinyl name</p>
              <TextField
                required
                type="text"
                id="outlined-basic"
                variant="outlined"
                name={"name"}
                value={values.name}
                onChange={onChangeInputValues("name")}
                error={errors?.name}
                helperText={errors?.name && "Field is required"}
                placeholder={"vinyl name*"}
              />
            </div>
            <div className="price-block__price block_positions">
              A side track order
              <TextField
                multiline
                rows={4}
                required
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"price"}
                value={values.a_side}
                onChange={onChangeInputValues("a_side")}
                error={errors?.a_side}
                helperText={errors?.a_side && "Field is required"}
              />
            </div>
            <div className="price-block__price block_positions">
              B side track order
              <TextField
                multiline
                required={values.audioB.length > 0}
                rows={4}
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"price"}
                value={values.b_side}
                onChange={onChangeInputValues("b_side")}
                error={errors?.b_side}
                helperText={errors?.b_side && "Field is required"}
              />
            </div>
            <div className="linear__block block_positions">
              <p>Comment</p>
              <TextField
                multiline
                rows={4}
                type="text"
                id="outlined-basic"
                label="comment"
                variant="outlined"
                name={"comment"}
                value={values.comment}
                onChange={onChangeInputValues("comment")}
              />
            </div>
            <div className="price-block__price block_positions">
              Set Price Of product
              <TextField
                required
                type="number"
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name={"price"}
                value={values.price}
                onChange={onChangeInputValues("price")}
                InputProps={{
                  inputProps: { min: product?.manufacturing_cost || 10 },
                }}
                error={errors?.price}
                helperText={errors?.price && "Field is required"}
              />
            </div>
            <div className="description__cost-text">
              Manufacturing Cost: {generateVinylPrice()}$
            </div>
          </div>

          <p>Product audios:</p>
          <FormControlLabel
            control={
              <Switch
                checked={isEditAudio}
                onChange={isEditAudioChange}
                name="checkedImages"
                color="primary"
                // inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Need to upload new audio?"
          />
          {!isEditAudio && (
            <>
              <p>Audio A side</p>
              <List>
                {product?.audioA.length === 0 && (
                  <ListItem>
                    <ListItemText primary={"A side is empty"} />
                  </ListItem>
                )}
                {product?.audioA.map((audio, index) => {
                  const name = audio.split("/");
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${name[name.length - 1]}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <p>Audio B side</p>
              <List>
                {product?.audioB.length === 0 && (
                  <ListItem>
                    <ListItemText primary={"B side is empty"} />
                  </ListItem>
                )}
                {product?.audioB.map((audio, index) => {
                  const name = audio.split("/");
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`${index + 1}. ${name[name.length - 1]}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}

          {isEditAudio && (
            <div className="upload__files">
              {values?.audioA?.length > 0 && (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    Audio files on the "A" side
                  </p>
                  <div className="upload__files-list">
                    {values?.audioA?.map((audioFile, i) => {
                      return (
                        <div className={"audio_item"} key={i}>
                          {audioFile.name}
                          <img
                            src={CrossIcon}
                            alt=""
                            className={"remove_audio_icon"}
                            onClick={() =>
                              onDeleteAudio(audioFile.lastModified, "audioA")
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {errors?.audioA && <div className="error">{errors.audioA}</div>}
              <FileUploader
                title={'Upload audio files for "A" side'}
                onSetFiles={onSetAudioFilesForASide}
                multiple={true}
              />
              {values?.audioB?.length > 0 && (
                <>
                  <p style={{ fontWeight: "bold" }}>
                    Audio files on the "B" side
                  </p>
                  <div className="upload__files-list">
                    {values?.audioB?.map((audioFile, i) => {
                      return (
                        <div className={"audio_item"} key={i}>
                          {audioFile.name}
                          <img
                            src={CrossIcon}
                            alt=""
                            className={"remove_audio_icon"}
                            onClick={() =>
                              onDeleteAudio(audioFile.lastModified, "audioB")
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {errors?.audioB && <div className="error">{errors.audioB}</div>}
              <FileUploader
                title={'Upload audio files for "B" side'}
                onSetFiles={onSetAudioFilesForBSide}
                multiple={true}
              />
            </div>
          )}

          <div className="description__button-block marginTop">
            <Dialog
              open={openModal}
              onClose={closeModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Complete editing and send to moderation?"}
              </DialogTitle>
              <DialogContent
                style={{ minHeight: isEditImages ? 500 : 70, minWidth: 500 }}
              >
                {isEditImages && (
                  <ModerationSlider
                    completeImages={Object.values(completeImages)}
                  />
                )}
                {isScreenshotFetching && (
                  <div className="error">
                    Please wait, your product is processing
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <ButtonCreate
                  text={"Back to Designing"}
                  handleClick={closeModal}
                />

                <ButtonCreate
                  isDisabled={false}
                  text={"Send to Moderation"}
                  handleClick={onSubmit}
                />
              </DialogActions>
            </Dialog>
            <Collapse in={totalCheckFields}>
              <Alert color="error">Please, check required fields</Alert>
            </Collapse>
            <ButtonCreate
              className="create-design"
              text={"Save"}
              size={"large"}
              fullWidth={true}
              handleClick={onOpenModal}
            />
          </div>
        </div>
      </div>
    </div>
  ) : isLoading ? (
    <Preloader />
  ) : (
    <></>
  );
};
