import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import {
  Button,
  DialogContentText,
  Snackbar,
  TextField,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { webkulIntegration } from "../../../../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import {
  isCreatedAdmin,
  isRejectedAdmin,
} from "../../../../redux/users/usersMutations";

const requiredFields = ["apiKey", "secretKey", "userId"];

export const VendorTab = ({ users, showIntegratedButton }) => {
  const [openWebkulWarning, setOpenWebkulWarning] = useState(false);
  const [userName, setUserName] = useState("");
  const [values, setValues] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });
  const [errors, setErrors] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });
  const [id, setId] = useState("");
  const isSuccess = useSelector((state) => state.authReducer.isCreatedAdmin);
  const isError = useSelector((state) => state.authReducer.isRejectedAdmin);
  const dispatch = useDispatch();
  const history = useHistory();

  const validateFields = () => {
    let approved = [];
    requiredFields.forEach((field) => {
      if (values[field] === "") {
        setErrors((prevState) => ({
          ...prevState,
          [field]: "Field is required",
        }));
        approved.push(true);
        return;
      }
      if (field === "apiKey" || field === "secretKey") {
        if (values[field].match(/[^a-zA-Z0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only a-z A-Z 0-9",
          }));
          approved.push(true);
          return;
        }
        // if (values[field].length !== 86) {
        //   setErrors((prevState) => ({
        //     ...prevState,
        //     [field]: "Token length must be 86",
        //   }));
        //   approved.push(true);
        // }
      }
      if (field === "userId") {
        if (values[field].match(/[^0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only 0-9",
          }));
          approved.push(true);
          return;
        }
        if (values[field].toString().length !== 6) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Length must be 6 digits",
          }));
          approved.push(true);
          return;
        }
      }
      approved.push(false);
    });
    console.log("approved", approved);
    return approved;
  };
  const onSubmit = () => {
    const approved = validateFields();
    const checkedValues = approved.find((f) => f === true);
    // approved.every((element) => element);
    console.log("errors", errors);
    console.log("checkedValues", checkedValues);

    if (!checkedValues) {
      dispatch(
        webkulIntegration({
          ...values,
          history,
          fromProduct: false,
          id,
        })
      );
      setOpenWebkulWarning(false);
      setValues({
        apiKey: "",
        secretKey: "",
        userId: "",
      });
    }
    // else {
    //   const fields = Object.keys(values);
    //   fields.forEach((field) => {
    //     if (!values[field]) {
    //       setErrors((prevState) => ({
    //         ...prevState,
    //         [field]: "This field is required",
    //       }));
    //     }
    //   });
    // }
    setTimeout(
      () =>
        setErrors({
          apiKey: "",
          secretKey: "",
          userId: "",
        }),
      5000
    );
  };
  const onChangeValue = (fieldName) => (e) => {
    const value = e.currentTarget.value;
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));
  };
  const onCloseWebkulWarning = () => {
    setOpenWebkulWarning(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(isCreatedAdmin(false));
      dispatch(isRejectedAdmin(false));
      return;
    }
    dispatch(isCreatedAdmin(false));
    dispatch(isRejectedAdmin(false));
  };
  return (
    <div className={"vendor_tabs"}>
      {isError && (
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity="error">Integrated with webcul was rejected</Alert>
        </Snackbar>
      )}
      {isSuccess && (
        <Snackbar
          open={isSuccess}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert severity="success">Integrated with webcul was success</Alert>
        </Snackbar>
      )}
      <div className="tabs">
        <div className="tabs__row-container">
          <div className="tabs__content content-header">
            <div className="tabs-vendor__name">Name</div>
            <div className="tabs-vendor__email">Email</div>
            <div className="tabs-vendor__action">Actions</div>
          </div>
          {users.map((row, index) => {
            return (
              <div key={row._id} className="tabs-vendor">
                <div className="tabs-vendor__name">{row.name}</div>
                <div className="tabs-vendor__email">{row.email}</div>
                <div className="tabs-vendor__action">
                  {
                    showIntegratedButton && (
                      <Button
                        color={"primary"}
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(row._id);
                          setOpenWebkulWarning(true);
                        }}
                      >
                        Integrate with Webkul
                      </Button>
                    )
                    //   : (
                    //   <Button
                    //     size="small"
                    //     color={"secondary"}
                    //     variant={"contained"}
                    //   >
                    //     Delete
                    //   </Button>
                    // )
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Dialog
        open={openWebkulWarning}
        onClose={onCloseWebkulWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Please integrate the user ${userName} with webkul`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={"webkul-page_container"}>
              <div className={"webkul-page_fields"}>
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"apiKey"}
                  value={values?.apiKey}
                  onChange={onChangeValue("apiKey")}
                  error={errors?.apiKey}
                  helperText={errors?.apiKey}
                  placeholder={"Access Token"}
                  autocomplete="off"
                />
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"secretKey"}
                  value={values.secretKey}
                  onChange={onChangeValue("secretKey")}
                  error={errors?.secretKey}
                  helperText={errors?.secretKey}
                  placeholder={"Refresh Token"}
                  autocomplete="off"
                />
                <TextField
                  required
                  type="text"
                  // id="outlined-basic"
                  // variant="outlined"
                  name={"userId"}
                  value={values.userId}
                  onChange={onChangeValue("userId")}
                  error={errors?.userId}
                  helperText={errors?.userId}
                  placeholder={"User id"}
                  autocomplete="off"
                />
              </div>
              <div className="webkul-page_actions">
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
