import React from 'react'
import {CircularProgress} from "@material-ui/core";
import './style.sass'

export const Preloader = () => {
    return (
        <div className={'preloader'}>
            <div className='preloader-wrapper'>
                <CircularProgress />
            </div>
        </div>
    )
}