import React, { useState } from "react";
import { AdminNavbar } from "../../components/adminNavbar";
import { superAdminLinks } from "../../components/adminNavbar/data";
import { Redirect } from "react-router-dom";
import "./styles.scss";

export const SuperAdminLayout = ({ children }) => {
  const [isNavbarOpen, setNavbarOpen] = useState(true);

  const isLoggedIn =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const isAdmin =
    localStorage.getItem("role") === "admin" ||
    sessionStorage.getItem("role") === "admin";

  return isLoggedIn && isAdmin ? (
    <div className="super-admin">
      <AdminNavbar
        links={superAdminLinks}
        setNavbarOpen={setNavbarOpen}
        isNavbarOpen={isNavbarOpen}
        title="SUPER-ADMIN PANEL"
      />
      <div className={isNavbarOpen ? "content--small" : "content"}>
        {children}
      </div>
    </div>
  ) : (
    <Redirect to="login" />
  );
};
