import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, MenuItem } from "@material-ui/core";
import { ButtonCreate } from "../../components/ButtonCreate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../CustomizeProductPage/components/FileUploader/FileUploader";
import CasseteFront from "./images/cassette-a.png";
import CasseteBack from "./images/cassette-b.png";
import cardO from "./images/O-card.png";
import cardJ from "./images/J-card.png";
import { Layer, Stage } from "react-konva";
import { Img } from "../CustomizeProductPage/components/Img/Img";
import domtoimage from "dom-to-image";
import DialogContent from "@material-ui/core/DialogContent";
import { ModerationSlider } from "../CustomizeProductPage/ModerationSlider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// import { addNewProduct } from '../../redux/products/productsActions'
import { srcToFile, dataURLtoFile, getRandomString } from "../../utils";
import { encodeImageFileAsURL } from "../../utils/converteImageToBase64";
import { getTemplateById } from "../../redux/templates/templatesActions";
import { Preloader } from "../../components/Preloader";
import CrossIcon from "../../assets/images/close.svg";
import "./styles.scss";
import {addNewProduct, ProductType} from "../../redux/products/productsActions";

const initialRectangles = [];

const stageWidth = 600;
const stageHeight = 600;
const imageDiskWidth = 560;
const imageDiskHeight = 272;
const imageBoxWidth = 600;
const imageBoxHeight = 399;

const sidesConfig = [
  {
    name: "side A",
    id: "b8ffc26f-497e-47ad-aa1c-21dc7fbd025a",
    path: CasseteFront,
    disk: true,
    side: "a_side",
  },
  {
    name: "side B",
    id: "b8ffcsdeE-497e-47ad-aa1c-21dc7fbd025a",
    path: CasseteBack,
    disk: true,
    side: "b_side",
  },
];

const boxesConfig = [
  {
    name: "side O (box)",
    id: "31488a8d-1c3b-4ced-ba85-f19948cb76d7",
    path: cardO,
    disk: false,
    side: "o_side",
  },
  {
    name: "side J (box)",
    id: "51488c296-55a2-11eb-ae93-0242ac130002",
    path: cardJ,
    disk: false,
    side: "j_side",
  },
];

const boxColors = [
  {
    id: 0,
    value: "clean",
  },
  {
    id: 1,
    value: "black",
  },
  {
    id: 2,
    value: "blue",
  },
  {
    id: 3,
    value: "green",
  },
  {
    id: 4,
    value: "orange",
  },
  {
    id: 5,
    value: "pink",
  },
  {
    id: 6,
    value: "red",
  },
  {
    id: 7,
    value: "white",
  },
  {
    id: 8,
    value: "yellow",
  },
];

const boxTypes = [
  {
    id: 0,
    value: "o_side",
    title: "Side O",
  },
  {
    id: 1,
    value: "j_side",
    title: "Side J",
  },
];

const requiredFields = ["a_side", "b_side", "price", "cd_name", "audioA"];

const productDescription =
  "Please enter all information below to create your custom Cassette. Once submitted our Admins review each product before setting it live to your store.  Max recording time for CDs is 80 mins.";

export const CassetteCreate = () => {
  const [activeImage, setActiveImage] = useState(null);
  const [activeSideName, setActiveImagesSide] = useState("a_side");
  const [selectedId, selectShape] = useState(null);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [idCount, setIdCount] = useState(0);
  const [values, setValues] = useState({
    boxColor: "clean",
    boxType: "o_side",
    audioA: [],
    audioB: [],
  });

  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const canvasRef = React.useRef();
  // disk = casette
  const styleProps = {
    position: "relative",
    backgroundColor: "transparent",
    top: activeImage?.disk ? "50%" : "48%",
    left: "50%",
    width: activeImage?.disk ? imageDiskWidth : imageBoxWidth,
    height: activeImage?.disk ? imageDiskHeight : imageBoxHeight,
    borderRadius: activeImage?.disk ? "50%" : 0,
    transform: "translate(-50%, -50%)",
  };

  const [files, setFiles] = useState([]);
  const [completeImages, setCompleteImages] = useState({});
  const [detailInfoFiles, setDetailInfoFiles] = useState({});
  const [isSendData, setIsSendData] = useState(false);
  const template = useSelector((state) => state.templatesReducer.template);
  const isLoading = useSelector((state) => state.commonReducer.isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplateById("vinyl"));
  }, [dispatch]);

  useEffect(() => {
    setActiveImage(sidesConfig[0]);
    setActiveImagesSide("a_side");
    setValues({
      a_side: "",
      b_side: "",
      boxColor: "clean",
      boxType: values.boxType,
      cd_name: "",
      comment: "",
      price: "",
      audioA: [],
      audioB: [],
    });
    setFiles([]);
    setRectangles(initialRectangles);
  }, [values.boxType]);

  useEffect(() => {
    setActiveImage(sidesConfig[0]);
  }, []);

  const handleImageLoaded = (imgUrl) => {
    const newImg = buildImgProperty(imgUrl);
    setRectangles([...rectangles, newImg]);
    setTimeout(() => {
      screenshot();
    }, 100);
  };

  const buildImgProperty = (imgUrl) => {
    const props = {
      x: 10,
      y: 10,
      width: imageDiskWidth,
      height: imageDiskHeight,
      id: `rect${idCount}`,
      imgUrl,
      parentId: activeImage.id,
    };
    setIdCount((prevCountValue) => prevCountValue + 1);
    return props;
  };

  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === activeImage.id
  );

  const changeActiveImage = (image, sideName) => {
    setTimeout(() => {
      setActiveImagesSide(sideName);
      setActiveImage(image);
    }, 200);
  };
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const onChangeInputValues = (name) => (e) => {
    const value = e.target.value;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    if (value) setErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const checkRequired = () => {
    let approved = [];

    requiredFields.forEach((fieldName) => {
      if (fieldName === "b_side" && !values?.b_side) {
        if (values.audioB.length > 0) {
          setErrors((prevState) => ({
            ...prevState,
            b_side: "The field is required",
          }));
          //
          approved.push(false);
        } else {
          setErrors((prevState) => ({
            ...prevState,
            b_side: null,
          }));
        }
      } else {
        if (!values[fieldName] || values[fieldName].length === 0) {
          // async
          setErrors((prevState) => ({
            ...prevState,
            [fieldName]: "The field is required",
          }));

          // sync
          // -- ???
          approved.push(false);
        } else if (values[fieldName] || values[fieldName].length === 0) {
          setErrors((prevState) => ({ ...prevState, [fieldName]: null }));
          approved.push(true);
        }
      }
    });

    return approved;
  };

  console.log("errors", errors);

  const generateTracks = (side, data) => {
    return { side, data };
  };

  const generateFormData = async () => {
    const formData = new FormData();

    let completeImageSideA,
      completeImageSideB,
      completeImageSideO,
      completeImageSideJ;

    const { a_side, b_side, o_side, j_side } = completeImages;
    const audioA = values.audioA;
    const audioB = values.audioB;

    if (a_side) {
      completeImageSideA = await dataURLtoFile(
        a_side,
        `${getRandomString(20)}.png`
      );
      formData.append("a_side", completeImageSideA);
    }

    if (b_side) {
      completeImageSideB = await dataURLtoFile(
        b_side,
        `${getRandomString(20)}.png`
      );
      formData.append("b_side", completeImageSideB);
    }

    if (o_side) {
      completeImageSideO = await dataURLtoFile(
        o_side,
        `${getRandomString(20)}.png`
      );
      formData.append("o_side", completeImageSideO);
    }

    if (j_side) {
      completeImageSideJ = await dataURLtoFile(
        j_side,
        `${getRandomString(20)}.png`
      );
      formData.append("j_side", completeImageSideJ);
    }

    if (audioA.length > 0) {
      audioA.forEach((file) => {
        formData.append("audio-a", file);
      });
    }

    if (audioB.length > 0) {
      audioB.forEach((file) => {
        formData.append("audio-b", file);
      });
    }

    formData.append("trackOrderA", values.a_side);
    formData.append("trackOrderB", values.b_side);
    formData.append("name", values.cd_name);
    formData.append("template", template._id);
    formData.append("description", values.comment);
    formData.append("price", values.price);
    formData.append("boxType", values.boxType);
    formData.append("boxColor", values.boxColor);

    /*
     * a_side: imageFile (required)
     * b_side: imageFile (required)
     * o_side: imageFile (required)
     * j_side: imageFile (required if field boxType = j_side)
     * audio-a: array of audioFiles (required)
     * audio-b: array of audioFiles
     * trackOrderA: string (required)
     * trackOrderB: string (required if length field audio-b more 0)
     * name: string (required)
     * comment: string
     * price: number (required)
     * boxType: o_side / j_side (required, default = o_side)
     * boxColor:  black / blue / clear / green / orange / pink / red / white / yellow (required, default = clear)
     */

    for (var pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
  };

  const onSubmit = async () => {
    const data = await generateFormData();
    // for (let pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    console.log("valuesSend", values);
    dispatch(addNewProduct(data, ProductType.CASSETTE));
    setIsSendData(true);
  };

  const setOriginalImage = (side) => (image) => {
    setCompleteImages((prevState) => ({ ...prevState, [side]: image }));
  };

  const checkAvailableImages = () => {
    let sides = [];

    if (values.boxType === "o_side") {
      sides = ["a_side", "b_side", "o_side"];
    } else {
      sides = ["a_side", "b_side", "j_side"];
    }

    sides.forEach((side) => {
      const isBox = side === "o_side" || side === "j_side";
      if (!completeImages[side]) {
        srcToFile(
          isBox ? cardO || cardJ : CasseteFront || CasseteBack,
          getRandomString(20)
        ).then((file) => {
          const imgInBase64 = encodeImageFileAsURL(
            file,
            setOriginalImage(side)
          );
          setCompleteImages((prevState) => ({
            ...prevState,
            [side]: imgInBase64,
          }));
        });
      }
    });
  };

  useEffect(() => {}, []);

  const onOpenModal = () => {
    const checkFiles = Object.values(detailInfoFiles).length >= 3;
    if (checkFiles) {
      const approved = checkRequired();
      const allApproved = approved.every((element) => element);
      checkAvailableImages();

      if (
        !Object.values(errors).filter((value) => value).length &&
        allApproved
      ) {
        selectShape(null);
        setOpenModal(true);
      }
      setErrors((prevState) => ({ ...prevState, files: "" }));
    } else
      setErrors((prevState) => ({
        ...prevState,
        files: "Please upload image(s)",
      }));
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  function simulateClick(elem) {
    const evt = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    elem && elem.dispatchEvent(evt);
  }

  const screenshot = () => {
    const node = document.getElementById("stage-node");
    const content = document.querySelector("canvas");
    simulateClick(content);
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        setCompleteImages((prevState) => ({
          ...prevState,
          [activeImage.side]: dataUrl,
        }));
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const onSetFiles = (file) => {
    setFiles((prevState) => [...prevState, file]);
    if (file)
      setDetailInfoFiles((prevState) => ({
        ...prevState,
        [activeImage.side]: file,
      }));
  };

  const onSetAudioFilesForASide = (files) => {
    const checkFiles = files
      .map((file) => {
        return !!(
          file.type.includes("mpeg") ||
          file.type.includes("mp4") ||
          file.type.includes("wav") ||
          file.type.includes("x-flac")
        );
      })
      .some((definition) => !definition);

    const isInValues = !!values.audioA?.find((a) => {
      return a.name !== files.name;
    });
    if (isInValues) {
      setErrors((prevState) => ({
        ...prevState,
        audioA: "You can`t upload same tracks",
      }));
      return;
    }

    if (!checkFiles) {
      setValues((prevState) => ({
        ...prevState,
        audioA: [...(prevState.audioA || null), ...files],
      }));
      setErrors((prevState) => ({ ...prevState, audioA: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        audioA: "All audio files must be mp3, mp4, wav or oog formats",
      }));
    }
  };

  const onSetAudioFilesForBSide = (files) => {
    const checkFiles = files
      .map((file) => {
        return !!(
          file.type.includes("mpeg") ||
          file.type.includes("mp4") ||
          file.type.includes("wav") ||
          file.type.includes("x-flac")
        );
      })
      .some((definition) => !definition);

    const isInValues = !!values.audioB?.find((a) => {
      return a.name !== files.name;
    });
    if (isInValues) {
      setErrors((prevState) => ({
        ...prevState,
        audioB: "You can`t upload same tracks",
      }));
      return;
    }

    if (!checkFiles) {
      setValues((prevState) => ({
        ...prevState,
        audioB: [...(prevState.audioB || null), ...files],
      }));
      setErrors((prevState) => ({ ...prevState, audioB: "" }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        audioB: "All audio files must be mp3, mp4, wav or oog formats",
      }));
    }
  };

  const onDeleteAudio = (id, side) => {
    setValues((prevState) => ({
      ...prevState,
      [side]: prevState[side].filter((item) => item.lastModified !== id),
    }));
  };

  if (isSendData && !isLoading) return <Redirect to={"/"} />;
  return activeImage && !isLoading ? (
    <div
      className={`product product-cassette__wrapper ${
        activeImage.disk ? "disk" : "box"
      }`}
    >
      <div className="back-btn">
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon fontSize="large" style={{ color: "black" }} /> Back to
          Dashboard
        </IconButton>
      </div>
      <div className="product__content">
        <div>
          <div className="product__images images">
            <div className="customize__stage stage">
              <div
                id="stage-node"
                className="stage__stage-block"
                style={{
                  backgroundImage: `url("${activeImage.path}")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: stageWidth,
                  height: stageHeight,
                  position: "relative",
                }}
              >
                <div className="print-area" style={styleProps}>
                  <Stage
                    width={styleProps.width}
                    height={styleProps.height}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    ref={canvasRef}
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                    onDragEnd={screenshot}
                    onTransformEnd={screenshot}
                    onTouchEnd={screenshot}
                    onMouseUp={() => {
                      screenshot();
                    }}
                  >
                    {rectanglesByImage.map((rect, i) => {
                      return (
                        <Layer key={i + "layer"}>
                          <Img
                            imageWidth={imageDiskWidth}
                            imageHeight={imageDiskHeight}
                            canvasRef={canvasRef}
                            shapeProps={rect}
                            isSelected={rect.id === selectedId}
                            onSelect={() => {
                              selectShape(rect.id);
                            }}
                            width={stageWidth}
                            height={stageHeight}
                            onChange={(newAttrs) => {
                              const rects = rectangles.slice();
                              const rectIndex = rects.findIndex(
                                (e) => newAttrs.id === e.id
                              );
                              rects[rectIndex] = newAttrs;
                              setRectangles(rects);
                            }}
                          />
                        </Layer>
                      );
                    })}
                  </Stage>
                  {activeImage.disk ? (
                    <div className="cassette-circle-background" />
                  ) : (
                    <div className="cassette-J-card-box-border" />
                  )}
                </div>
              </div>
            </div>
            <div className="image-options">
              <div className="sides-wrapper">
                {sidesConfig.map((side) => (
                  <div className={`image-container`} key={side.id}>
                    <img
                      src={side.path}
                      alt=""
                      className={`image_container ${
                        activeSideName === side.side
                          ? "image_container_active"
                          : ""
                      }`}
                      data-index={side.id}
                      onClick={() => changeActiveImage(side, side.side)}
                    />
                    <p>{side.name}*</p>
                  </div>
                ))}
              </div>
              <div className="sides-wrapper">
                {boxesConfig.map((side) => {
                  return (
                    side.side === values.boxType && (
                      <div className={`image-container`} key={side.id}>
                        <img
                          src={side.path}
                          alt=""
                          className={`image_container ${
                            activeSideName === side.side
                              ? "image_container_active"
                              : ""
                          }`}
                          data-index={side.id}
                          onClick={() => changeActiveImage(side, side.side)}
                        />
                        <p>{side.name}*</p>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
          <div className="upload__files">
            {errors.files && <div className="error">{errors.files}</div>}
            <FileUploader
              files={files}
              onSetFiles={onSetFiles}
              onImageLoaded={handleImageLoaded}
            />
            <div className="explanation__block">* - required</div>
          </div>
        </div>
        <div className="product__description description">
          <div className="description__main-info">
            <p>Description:</p>
            <div className="description__info">{productDescription}</div>
          </div>
          <div className="items">
            <div className="linear__block block_positions">
              <p>Box type</p>
              <TextField
                select
                type="text"
                id="outlined-basic"
                label="box type"
                variant="outlined"
                name={"box-type"}
                value={values.boxType}
                onChange={onChangeInputValues("boxType")}
              >
                {boxTypes.map((type) => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="price-block__price block_positions">
              <p>Cassette name</p>
              <TextField
                required
                type="text"
                id="cassette_name"
                variant="outlined"
                name={"cassette_name"}
                label="cassette name"
                value={values.cd_name}
                onChange={onChangeInputValues("cd_name")}
                error={errors?.cd_name}
                helperText={errors?.cd_name && "Field is required"}
              />
            </div>
            <div className="price-block__price block_positions">
              A side track order
              <TextField
                multiline
                rows={4}
                required
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"a_side"}
                value={values.a_side}
                onChange={onChangeInputValues("a_side")}
                error={errors?.a_side}
                helperText={errors?.a_side && "Field is required"}
              />
            </div>
            <div className="price-block__price block_positions">
              B side track order
              <TextField
                multiline
                required={values.audioB.length > 0}
                rows={4}
                type="text"
                id="outlined-basic"
                label="track's order"
                variant="outlined"
                name={"b_side"}
                value={values.b_side}
                onChange={onChangeInputValues("b_side")}
                error={errors?.b_side}
                helperText={errors?.b_side && "Field is required"}
              />
            </div>
            <div className="linear__block block_positions">
              <p>Comment</p>
              <TextField
                multiline
                rows={4}
                type="text"
                id="outlined-basic"
                label="comment"
                variant="outlined"
                name={"comment"}
                value={values.comment}
                onChange={onChangeInputValues("comment")}
              />
            </div>
            <div className="linear__block block_positions">
              <p>Box color</p>
              <TextField
                select
                type="text"
                id="outlined-basic"
                label="box color"
                variant="outlined"
                name={"box-color"}
                value={values.boxColor}
                onChange={onChangeInputValues("boxColor")}
              >
                {boxColors.map((color) => (
                  <MenuItem key={color.id} value={color.value}>
                    {color.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="price-block__price block_positions">
              Set Price Of product
              <TextField
                required
                type="number"
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name={"price"}
                value={values.price}
                onChange={onChangeInputValues("price")}
                InputProps={{
                  inputProps: { min: template.manufacturing_cost || 10 },
                }}
                error={errors?.price}
                helperText={errors?.price && "Field is required"}
              />
            </div>
            <div className="description__cost-text">
              Manufacturing Cost:{" "}
              <span style={{ fontWeight: "bold" }}>
                ${template.manufacturing_cost}
              </span>
            </div>
          </div>
          <div className="upload__files">
            {values?.audioA?.length > 0 && (
              <>
                <p style={{ fontWeight: "bold" }}>
                  Audio files on the "A" side
                </p>
                <div className="upload__files-list">
                  {values?.audioA?.map((audioFile, i) => {
                    return (
                      <div className={"audio_item"} key={i}>
                        {audioFile.name}
                        <img
                          src={CrossIcon}
                          alt=""
                          className={"remove_audio_icon"}
                          onClick={() =>
                            onDeleteAudio(audioFile.lastModified, "audioA")
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {errors?.audioA && <div className="error">{errors.audioA}</div>}
            <FileUploader
              title={'Upload audio files for "A" side'}
              onSetFiles={onSetAudioFilesForASide}
              multiple={true}
            />
            {values?.audioB?.length > 0 && (
              <>
                <p style={{ fontWeight: "bold" }}>
                  Audio files on the "B" side
                </p>
                <div className="upload__files-list">
                  {values?.audioB?.map((audioFile, i) => {
                    return (
                      <div className={"audio_item"} key={i}>
                        {audioFile.name}
                        <img
                          src={CrossIcon}
                          alt=""
                          className={"remove_audio_icon"}
                          onClick={() =>
                            onDeleteAudio(audioFile.lastModified, "audioB")
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {errors?.audioB && <div className="error">{errors.audioB}</div>}
            <FileUploader
              title={'Upload audio files for "B" side'}
              onSetFiles={onSetAudioFilesForBSide}
              multiple={true}
            />
          </div>
          <div className="description__button-block marginTop">
            <Dialog
              open={openModal}
              onClose={closeModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Check your design before sending to moderation"}
              </DialogTitle>
              <DialogContent style={{ minHeight: 500, minWidth: 500 }}>
                <ModerationSlider
                  completeImages={Object.values(completeImages)}
                />
              </DialogContent>
              <DialogActions>
                <ButtonCreate
                  text={"Back to Designing"}
                  handleClick={closeModal}
                />

                <ButtonCreate
                  isDisabled={false}
                  text={"Send to Moderation"}
                  handleClick={onSubmit}
                />
              </DialogActions>
            </Dialog>
            <ButtonCreate
              className="create-design"
              text={"Save"}
              size={"large"}
              fullWidth={true}
              handleClick={onOpenModal}
            />
          </div>
        </div>
      </div>
    </div>
  ) : isLoading ? (
    <Preloader />
  ) : (
    <></>
  );
};
