import React, { useState } from "react";
import { ButtonCreate } from "../../components/ButtonCreate";
import Switch from "@material-ui/core/Switch";
import "./styles.scss";

export const ReviewProduct = () => {
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className="review">
      <div className="review__content">
        <div className="review__header">
          <h2>Product Details</h2>
          <ButtonCreate text="Save update" />
        </div>
        <h2>Basic Info</h2>
        <div className="detail-block">
          <p>Vendor: name</p>

          <p>Email: email@email.com</p>
        </div>
        <div className="detail-block">
          <p>Product Name</p>

          <p>testtesttest</p>
        </div>
        <div className="detail-block">
          <p>Categories</p>

          <p>Women clothing. T-shirts</p>
        </div>
        <h2>Product Images</h2>
        <div className="review-images">
          <div className="review-images__image">
            <img
              src="https://target.scene7.com/is/image/Target/GUEST_a9dd09db-3f50-47a4-b6c0-df3d795b51ce?wid=488&hei=488&fmt=pjpeg"
              alt="product"
            />
          </div>
          <div className="review-images__image">
            <img
              src="https://target.scene7.com/is/image/Target/GUEST_a9dd09db-3f50-47a4-b6c0-df3d795b51ce?wid=488&hei=488&fmt=pjpeg"
              alt="product"
            />
          </div>
          <div className="review-images__image">
            <img
              src="https://target.scene7.com/is/image/Target/GUEST_a9dd09db-3f50-47a4-b6c0-df3d795b51ce?wid=488&hei=488&fmt=pjpeg"
              alt="product"
            />
          </div>
        </div>
        <ButtonCreate text="edit design" />
        <h2>Manufacturing cost:</h2>
        <div className="pricing-block">
          <p>Basic Price : 8$</p>
          <p>Price by Vendor: 8$</p>
        </div>
        <h2>Moderation:</h2>
        <p>ON MODERATION</p>
        <p>PASSED MODERATION</p>
        <p>FAILED MODERATION</p>
        <h2>Status in store</h2>
        <Switch
          checked={state.checkedA}
          onChange={handleChange}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>
    </div>
  );
};
