import React from "react";

import "./styles.scss";

export const HamburgerButton = ({ handleClick, isOpen }) => {
  return (
    <>
      <button
        className={`hamburger-btn hamburger-btn${isOpen ? "--active" : ""}`}
        onClick={handleClick}
      >
        <p />
        <p />
        <p />
      </button>
    </>
  );
};
