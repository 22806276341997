import {
  ADD_NEW_PRODUCT,
  GET_ACTIVE_PRODUCTS,
  GET_INACTIVE_PRODUCTS,
  GET_PRODUCT,
  SET_APPROVED,
  SET_REJECTED,
  GET_REJECTED_PRODUCTS,
  SET_IS_EDIT_SUCCESS,
  SET_IS_EDIT_ERROR,
  IS_APPROVED_ERROR,
} from "./productsTypes";

const initialState = {
  activeProducts: [],
  inactiveProducts: [],
  rejectedProducts: [],
  products: [],
  product: {},
  isApproved: false,
  isRejected: false,
  isEditSuccess: false,
  isEditError: false,
  isApprovedError: "",
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_PRODUCTS:
      return { ...state, activeProducts: action.data.reverse() };
    case GET_INACTIVE_PRODUCTS:
      return { ...state, inactiveProducts: action.data.reverse() };
    case GET_REJECTED_PRODUCTS:
      return { ...state, rejectedProducts: action.data.reverse() };
    case ADD_NEW_PRODUCT:
      return { ...state, products: [...state.products, action.data] };
    case GET_PRODUCT:
      return { ...state, product: action.data };
    case SET_APPROVED:
      return { ...state, isApproved: action.data };
    case SET_REJECTED:
      return { ...state, isRejected: action.data };
    case SET_IS_EDIT_SUCCESS:
      return { ...state, isEditSuccess: action.data };
    case SET_IS_EDIT_ERROR:
      return { ...state, isEditError: action.data };
    case IS_APPROVED_ERROR:
      return { ...state, isApprovedError: action.data };
    default:
      return state;
  }
};

export default productsReducer;
