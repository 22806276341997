import React, { useState } from "react";
import { Button, IconButton, Popover, TextField } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { renameCategoryById } from "../../redux/categories/categoriesActions";

export const ButtonRename = ({
  dispatch,
  category,
  renameEvent,
  isIconButton = true,
  withPopover = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const clickPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const isPopoverOpened = Boolean(anchorEl);

  const [newName, setNewName] = useState("");

  return (
    <div>
      {isIconButton && (
        <IconButton onClick={clickPopover}>
          <Edit />
        </IconButton>
      )}
      {withPopover && (
        <Popover
          open={isPopoverOpened}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="button-delete">
            <p>Rename category name:</p>
            <TextField
              value={newName || category.name}
              onChange={(e) => setNewName(e.target.value)}
            />
            <div className="button-delete__actions">
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(renameCategoryById(category._id, newName));
                  closePopover();
                }}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  closePopover();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};
