import axios from "../../API/axios/index";
import { toggleIsFetching } from "../common/commonMutations";
import {
  getTemplates,
  addTemplate,
  deleteTemplate,
  getTemplateByIdMutation,
  isTemplateCreated,
  isTemplateRejected,
  isEditSuccess,
} from "./templatesMutations";
// import { isRejectedAdmin } from "../users/usersMutations";

export function getAllTemplates() {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`/templates`)
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getTemplates(response.data.reverse()));
        })
        .catch((e) => {
          console.log("ERR:", e);
        });
    }
  };
}

export function getTemplatesByCategory(categoryId) {
  return (dispatch) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      dispatch(toggleIsFetching(true));
      axios
        .get(`/templates/category/${categoryId}`)
        .then((response) => {
          dispatch(toggleIsFetching(false));
          dispatch(getTemplates(response.data));
        })
        .catch((e) => {
          dispatch(toggleIsFetching(false));
          dispatch(getTemplates([]));
          console.log("ERR:", e);
        });
    }
  };
}

export function addNewTemplate(formData, history) {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      axios
        .post(`/templates`, formData)
        .then((response) => {
          dispatch(addTemplate(response.data));
          dispatch(isTemplateCreated(true));
          history.push("/sa-all-templates");
        })
        .catch((e) => {
          dispatch(isTemplateRejected(true));
          console.log("ERR:", e);
        });
    }
  };
}

export function editApparelTemplate(id, formData, history) {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    axios
      .put(`/templates/${id}`, formData)
      .then((response) => {
        dispatch(isEditSuccess(true));
        dispatch(toggleIsFetching(false));
        history.push("/sa-all-templates");
      })
      .catch((e) => {
        dispatch(toggleIsFetching(false));
        // dispatch(isTemplateRejected(true));
        console.log("ERR:", e);
      });
  };
}

export function getTemplateById(templateId) {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    axios
      .get(`/templates/${templateId}`)
      .then((res) => {
        dispatch(toggleIsFetching(false));
        dispatch(getTemplateByIdMutation(res.data));
      })
      .catch((e) => {
        console.log("error when get templateId", e);
      });
  };
}

export function deleteTemplateById(templateId) {
  return (dispatch, getState) => {
    const token = getState().authReducer.token;
    if (token) {
      axios
        .delete(`/templates/${templateId}`)
        .then(() => {
          dispatch(deleteTemplate(templateId));
        })
        .catch((e) => {
          console.log("error when delete", e);
        });
    }
  };
}
