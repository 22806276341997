import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollections } from "../../redux/collections/collectionsActions";
import { getAllCategories } from "../../redux/categories/categoriesActions";
import {
  editApparelTemplate,
  getTemplateById,
} from "../../redux/templates/templatesActions";
import { DropzoneDialog } from "material-ui-dropzone";

import { ButtonCreate } from "../../components/ButtonCreate";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Alert from "@material-ui/lab/Alert";
import { ImagesConfig } from "../CreateTemplateClothing/ImagesConfig";
import { TemplateGroup } from "../../utils/templateGroup";
import { ImagePreview } from "../../components/ImagePreview";
import "./style.scss";
import {
  setIsEditError,
  setIsEditSuccess,
} from "../../redux/products/productsMutations";
import { isEditCancel } from "../../redux/templates/templatesMutations";
import { Layer, Stage } from "react-konva";
import {
  buildImgProperty,
  dataURLtoFile,
  getRandomString,
  getTemplateImages,
  screenshot,
  srcToFile,
} from "../../utils";
import { Img } from "../../pages/CustomizeProductPage/components/Img/Img";
import { encodeImageFileAsURL } from "../../utils/converteImageToBase64";
import { addNewProduct } from "../../redux/products/productsActions";
const uuid = () => "_" + Math.random().toString(36).substr(2, 9);
const initialRectangles = [];
const coefficientHeight = 3;
const coefficientWidth = 3;
const stageWidth = 300;
const stageHeight = 300;
const imageWidth = 150;
const imageHeight = 150;
export const TYPES = {
  FRONT: "front",
  BACK: "back",
};

const CHECKBOX_OPTIONS = [
  {
    label: "front side",
    value: TYPES.FRONT,
  },
  {
    label: "back side",
    value: TYPES.BACK,
  },
];

export const EditTemplateClothing = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    webkul_collection_id: "",
    productName: "",
    productDescription: "",
    category: "",
    subCategory: "",
    price: "",
    sizes: {
      XS: false,
      S: false,
      M: false,
      L: false,
      XL: false,
      XXL: false,
    },
    images: [],
  });

  useEffect(() => {
    dispatch(getTemplateById(params.id));
  }, [dispatch, params.id]);

  const template = useSelector((state) => state.templatesReducer.template);
  const isFetching = useSelector((state) => state.commonReducer.isFetching);

  // const template = templates.filter((t) => t._id === templateId);

  console.log("template", template);
  // console.log("template name", template.name);

  const formData = new FormData();
  ////modal
  const [open, setOpen] = useState(false);
  ////// Previews
  const [previews, setPreviews] = useState([]);
  //checkboxes
  const [chosenTypes, setChosenTypes] = useState({});
  const [globalError, setGlobalError] = useState("");
  const [isEditImages, setIsEditImages] = useState(false);
  const [totalCheckFields, setTotalCheckFields] = useState(false);

  useEffect(() => {
    setTimeout(setTotalCheckFields, 3000, false);
  }, [totalCheckFields]);

  const transformSizes = (sizes = []) => {
    let tmpSizes = {
      XS: false,
      S: false,
      M: false,
      L: false,
      XL: false,
      XXL: false,
    };
    sizes.forEach((s) => {
      tmpSizes[s] = true;
    });
    return tmpSizes;
  };
  const [sidesError, setSidesError] = useState("");
  const openModal = () => {
    setPreviews([]);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const handleSave = (images) => {
    const allImages = [...formValues.images, ...images];
    if (allImages.length <= 2) {
      allImages.forEach((image) => {
        previewFile(image);
      });
      setFormValues((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...images],
      }));
      if (images.length) resetFieldError("images");
      closeModal();
      setGlobalError("");
      setChosenTypes((prevState) => ({}));
      if (allImages.length === 2) {
        setGlobalError("");
      } else setGlobalError("You can upload one more image");
    } else {
      formValues.images.forEach((image) => {
        previewFile(image);
      });
      setFormValues((prevState) => ({
        ...prevState,
        images: [...prevState.images],
      }));
      if (images.length) resetFieldError("images");
      closeModal();
      setGlobalError("");
      setChosenTypes((prevState) => ({}));
    }
  };

  function previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setPreviews((previews) => {
          return [
            ...previews,
            {
              imgSrc: reader.result,
              name: file.name,
              type: null,
              key: uuid(),
            },
          ];
        });
      },
      false
    );
    reader.readAsDataURL(file);
  }

  ////// CATEGORIES

  const categories = useSelector((state) => state.categoriesReducer.categories);
  const rootCategories = categories.filter((c) => !Boolean(c.parent));
  console.log("categories", categories);
  console.log("rootCategories", rootCategories);
  const getCategoryByName = (name) => categories.find((c) => c.name === name);
  const hasSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.some((c) => c.parent && c.parent === foundCategory._id);
    }
    return false;
  };

  const getSubCategories = (name) => {
    const foundCategory = getCategoryByName(name);
    if (foundCategory) {
      return categories.filter(
        (c) => c.parent && c.parent === foundCategory._id
      );
    }
    return [];
  };

  const getCategoryById = (id) => {
    const category = categories?.find(
      (cat) => cat.parent === null && cat._id === id
    );
    if (category) {
      return category.name;
    } else {
      const subCat = categories.find(
        (cat) => cat._id === id && cat.parent !== null
      );
      if (subCat) {
        const cat = categories.find((cat) => cat._id === subCat.parent);
        return cat?.name;
      }
    }
  };

  const getSubCategoryById = (id) => {
    if (categories) {
      const subCat = categories.find(
        (cat) => cat._id === id && cat.parent !== null
      );
      if (subCat) return subCat.name;
    }
    return "";
  };
  const collections = useSelector(
    (state) => state.collectionsReducer.collections
  );

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    const templateGroupId =
      Object.values(TemplateGroup).includes(template?.template_group) &&
      template?.template_group;
    dispatch(getAllCollections(templateGroupId));
  }, [dispatch, template]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log("template", template);
    setFormValues({
      webkul_collection_id: template?.webkul_collection_id,
      productName: template?.name,
      productDescription: template?.description,
      category: template ? getCategoryById(template.category) : "",
      // category: "T-shirts",
      subCategory: getSubCategoryById(template.category),
      price: template?.manufacturing_cost,
      sizes: transformSizes(template?.sizes),
      images: [],
    });
  }, [template, categories]);
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    console.log("name, value", name, value);
    const values = { ...formValues, [name]: value };
    if (name === "category") {
      // if (hasSubCategories(value)) {
      resetFieldError("subCategory");
      values.subCategory = "";
      // }
    }
    if (errors[name]) resetFieldError(name);

    setFormValues(values);
  };

  const handleChangeSizeCheckbox = (e) => {
    const { name, checked } = e.target;
    if (errors?.sizes) resetFieldError("sizes");
    setFormValues({
      ...formValues,
      sizes: { ...formValues.sizes, [name]: checked },
    });
  };

  const handleDeletePreview = (preview) => {
    setPreviews((prevState) => {
      if (prevState.length === 2) {
        setGlobalError("You can upload one more image");
      } else setGlobalError("");
      return prevState.filter((p) => p.key !== preview.key);
    });
  };

  const onUpdatePreview = (preview) => {
    const idx = previews.findIndex(({ key }) => key === preview.key);

    const np = [...previews];
    np[idx] = preview;

    setPreviews(np);
  };
  console.log("formValues", formValues);
  const validateField = (field) => {
    switch (field) {
      case "price":
        return formValues[field] < 0;
      // case "price":
      // if (formValues[field].length) return formValues[field] < 0;
      // return !formValues[field].length;
      case "sizes":
        return !Object.values(formValues[field]).some((size) => size);
      case "images":
        if (!isEditImages) return false;
        return !formValues[field].length;
      case "subCategory":
        if (!hasSubCategories(formValues["category"])) {
          return !!formValues["subCategory"].length;
        }
        return !formValues[field].length;
      default:
        return !Boolean(formValues[field]);
    }
  };

  const resetFieldError = (field) => {
    setErrors({ ...errors, [field]: false });
  };

  const isFormValid = () => {
    const errors = {};
    for (const field in formValues) {
      errors[field] = validateField(field);
    }
    console.log("errors", errors);
    setErrors(errors);
    return Object.values(errors).every((e) => !e);
  };

  const submitNewTemplate = () => {
    const {
      webkul_collection_id,
      productName,
      productDescription,
      category,
      subCategory,
      price,
      sizes,
    } = formValues;

    formData.append("webkul_collection_id", webkul_collection_id);
    formData.append("name", productName);
    formData.append("description", productDescription);
    formData.append(
      "category",
      subCategory
        ? getCategoryByName(subCategory)?._id
        : getCategoryByName(category)?._id
    );
    formData.append(
      "template_group",
      template?.template_group || TemplateGroup.APPAREL
    );
    formData.append("manufacturing_cost", price);

    if (isEditImages) {
      for (const { type, coords, name } of previews) {
        formData.append(`${type}_data`, JSON.stringify(coords));

        formData.append(
          type,
          formValues.images.find((img) => img.name === name)
        );
      }
    }

    Object.entries(sizes).forEach(
      ([s, isChecked]) => isChecked && formData.append("sizes[]", s)
    );
    dispatch(editApparelTemplate(params.id, formData, history));
    // for (var value of formData.values()) {
    //   console.log(value);
    // }
  };

  const handleFormSubmit = () => {
    const checkTypesOfPreviews = previews.filter((preview) => preview.type)
      .length;
    const checkCoordsOfPreviews = previews.filter((preview) => preview.coords)
      .length;
    if (!isFormValid()) {
      setTotalCheckFields(true);
      console.log("errors", errors);
      return;
    }
    if (checkTypesOfPreviews !== previews.length) {
      setSidesError(
        "Each uploaded image should have either Front or Back side” selected."
      );
      return;
    } else if (checkCoordsOfPreviews !== previews.length) {
      setSidesError("Each uploaded image should have selected area.");
      return;
    }
    setSidesError("");
    submitNewTemplate();
  };

  const {
    webkul_collection_id,
    productName,
    productDescription,
    category,
    subCategory,
    price,
    sizes,
  } = formValues;

  const isDisabled = previews.length === 2;

  const isEditImagesChange = () => {
    setIsEditImages(!isEditImages);
  };
  //=======================================================================
  const canvasRef = React.useRef();
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [selectedId, selectShape] = useState(null);
  const [mainImage, setMainImage] = useState(null);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  useEffect(() => {
    dispatch(getTemplateById(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    setMainImage(template?.images && template?.images[0]);
  }, [template]);

  const { front, back } = getTemplateImages(template);
  const rectanglesByImage = rectangles.filter(
    (e) => e.parentId === mainImage._id
  );

  const styleProps =
    mainImage && mainImage.side === "back"
      ? {
          position: "relative",
          backgroundColor: "rgba(0,0,0, 0.5)",
          top: (back.y || 0) * coefficientHeight,
          left: (back.x || 0) * coefficientWidth,
          width: (back.width || 0) * coefficientWidth,
          height: (back.height || 0) * coefficientHeight,
        }
      : {
          position: "relative",
          backgroundColor: "rgba(0,0,0, 0.5)",
          top: (front.y || 0) * coefficientHeight,
          left: (front.x || 0) * coefficientWidth,
          width: (front.width || 0) * coefficientWidth,
          height: (front.height || 0) * coefficientHeight,
        };

  return (
    <div className="create-template">
      <h2>Edit Apparel Template</h2>

      <form autoComplete="off">
        <p>Webkul Categories</p>
        <TextField
          required
          select
          name={"webkul_collection_id"}
          value={+webkul_collection_id}
          onChange={handleChangeInput}
          id="outlined-basic"
          label="Choose Category"
          variant="outlined"
          type="text"
          error={errors?.webkul_collection_id}
          helperText={errors?.webkul_collection_id && "Field is required"}
        >
          {collections.map((collection, index) => (
            <MenuItem key={index} value={collection.id}>
              {collection.category_name}
            </MenuItem>
          ))}
        </TextField>
        <p>Product Name</p>
        <div className="create-template__input-wrapper">
          <TextField
            required
            fullWidth
            name={"productName"}
            value={productName}
            onChange={handleChangeInput}
            id="outlined-basic"
            label="Product Name"
            variant="outlined"
            type="text"
            error={errors?.productName}
            helperText={errors?.productName && "Field is required"}
          />
        </div>
        <p>Product description</p>
        <div className="create-template__input-wrapper">
          <TextField
            fullWidth
            name={"productDescription"}
            value={productDescription}
            onChange={handleChangeInput}
            required
            id="outlined-basic"
            label="Product description"
            variant="outlined"
            type="text"
            multiline
            rows={4}
            error={errors?.productDescription}
            helperText={errors?.productDescription && "Field is required"}
          />
        </div>
        <p>Categories</p>
        <TextField
          required
          select
          id="outlined-basic"
          label="Choose category"
          variant="filled"
          type="text"
          name={"category"}
          value={category}
          onChange={handleChangeInput}
          className="select-category"
          error={errors?.category}
          helperText={errors?.category && "Field is required"}
        >
          {rootCategories.map((category, index) => (
            <MenuItem key={index} value={category.name}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        {hasSubCategories(category) ? (
          <TextField
            required={hasSubCategories(category)}
            select
            id="outlined-basic"
            label="Choose subcategory"
            variant="filled"
            type="text"
            name={"subCategory"}
            value={subCategory}
            onChange={handleChangeInput}
            className="select-category"
            error={errors?.subCategory}
            helperText={errors?.subCategory && "Field is required"}
          >
            {getSubCategories(category).map((category, index) => (
              <MenuItem key={index} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          category && (
            <div style={{ margin: "0 30px 0 30px" }}>
              <Alert severity="warning">
                This category doesn't have subcategories!
              </Alert>
            </div>
          )
        )}

        <p>Size</p>
        <div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.XS}
                  onChange={handleChangeSizeCheckbox}
                  name="XS"
                />
              }
              label="XS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.S}
                  onChange={handleChangeSizeCheckbox}
                  name="S"
                />
              }
              label="S"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.M}
                  onChange={handleChangeSizeCheckbox}
                  name="M"
                />
              }
              label="M"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.L}
                  onChange={handleChangeSizeCheckbox}
                  name="L"
                />
              }
              label="L"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.XL}
                  onChange={handleChangeSizeCheckbox}
                  name="XL"
                />
              }
              label="XL"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sizes.XXL}
                  onChange={handleChangeSizeCheckbox}
                  name="XXL"
                />
              }
              label="XXL"
            />
          </FormGroup>
          {errors?.sizes && <Alert severity="error">Please check size</Alert>}
        </div>
        <p>Product Images</p>
        <FormControlLabel
          style={{ paddingLeft: "20px" }}
          control={
            <Switch
              checked={isEditImages}
              onChange={isEditImagesChange}
              name="checkedImages"
              color="primary"
              // inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Need to upload new images?"
        />
        {!isEditImages && (
          <div className="moderate-product__image-container">
            {template?.images?.map((image, index) => (
              <div
                id="stage-node"
                className="image-wrap"
                style={{
                  backgroundImage: `url("${image.path}")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: stageWidth,
                  height: stageHeight,
                  position: "relative",
                }}
              >
                <div className="print-area" style={styleProps}>
                  <Stage
                    width={styleProps.width}
                    height={styleProps.height}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    ref={canvasRef}
                    style={{
                      borderRadius: "5px",
                      display: "flex",
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                  >
                    {rectanglesByImage.map((rect, i) => {
                      return (
                        <Layer key={i + "layer"}>
                          <Img
                            imageWidth={imageWidth}
                            imageHeight={imageHeight}
                            canvasRef={canvasRef}
                            shapeProps={rect}
                            isSelected={rect.id === selectedId}
                            onSelect={() => {
                              selectShape(rect.id);
                            }}
                            width={stageWidth}
                            height={stageHeight}
                            onChange={(newAttrs) => {
                              const rects = rectangles.slice();
                              const rectIndex = rects.findIndex(
                                (e) => newAttrs.id === e.id
                              );
                              rects[rectIndex] = newAttrs;
                              setRectangles(rects);
                            }}
                          />
                        </Layer>
                      );
                    })}
                  </Stage>
                </div>
              </div>
            ))}
          </div>
        )}

        {isEditImages && (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={isDisabled ? {} : { backgroundColor: "black" }}
              disabled={!!isDisabled}
              startIcon={<CloudUploadIcon />}
              onClick={openModal}
            >
              Upload Images
            </Button>
            {globalError && <div className={"error"}>{globalError}</div>}
            <div className="create-template__img-container">
              <div className="create-template__images">
                {previews.map((preview, index) => (
                  <div key={`${index}-previewsItem`} className="mapped-images">
                    <ImagesConfig
                      preview={preview}
                      onUpdatePreview={onUpdatePreview}
                      handleDelete={handleDeletePreview}
                      checkboxOptions={CHECKBOX_OPTIONS}
                      chosenTypes={chosenTypes}
                      setChosenTypes={setChosenTypes}
                    />
                    {!preview?.coords && (
                      <Alert severity="error">
                        Please select the region for print!
                      </Alert>
                    )}
                  </div>
                ))}
              </div>
              <DropzoneDialog
                filesLimit={2}
                open={open}
                onSave={handleSave}
                onDrop={() => {
                  setPreviews([]);
                }}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={closeModal}
              />
              {errors?.images && (
                <Alert severity="error">
                  Please select at least one picture!
                </Alert>
              )}
            </div>
            {sidesError && <div className={"error"}>{sidesError}</div>}
          </>
        )}

        <div className="create-template__price">
          <p>Manufacturing cost:</p>
          <TextField
            required
            name={"price"}
            value={+price}
            onChange={handleChangeInput}
            inputProps={{ min: 0 }}
            id="outlined-basic"
            label="MANUFACTURING COST"
            variant="outlined"
            type="number"
            error={errors?.price}
            helperText={
              errors?.price && "Field is required , price can't be negative"
            }
          />
        </div>
      </form>
      <Collapse in={totalCheckFields}>
        <Alert color="error">Please, check require fields</Alert>
      </Collapse>

      <div className="create-template__buttons">
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            <ButtonCreate
              text="save changes"
              handleClick={handleFormSubmit}
              width={300}
            />
            <ButtonCreate
              text="cancel"
              handleClick={() => {
                dispatch(isEditCancel(true));
                history.push("/sa-all-templates");
              }}
              width={300}
            />
          </>
        )}
      </div>
    </div>
  );
};
