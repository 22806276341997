export const GET_ACTIVE_PRODUCTS = "PRODUCTS/GET_ACTIVE_PRODUCTS";
export const GET_INACTIVE_PRODUCTS = "PRODUCTS/GET_INACTIVE_PRODUCTS";
export const ADD_NEW_PRODUCT = "PRODUCTS/ADD_NEW_PRODUCT";
export const GET_PRODUCT = "PRODUCTS/GET_PRODUCT";
export const SET_APPROVED = "PRODUCTS/SET_APPROVED";
export const SET_REJECTED = "PRODUCTS/SET_REJECTED";
export const SET_IS_EDIT_SUCCESS = "PRODUCTS/EDIT_SUCCESS";
export const SET_IS_EDIT_ERROR = "PRODUCTS/EDIT_ERROR";
export const GET_REJECTED_PRODUCTS = "PRODUCTS/GET_REJECTED_PRODUCTS";
export const IS_APPROVED_ERROR = "IS_APPROVED_ERROR";
