import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { NewCategoryForm } from "../../components/NewCategoryForm";
import { ButtonDelete } from "../../components/ButtonDelete";
import { ButtonRename } from "../../components/ButtonRename";
import {
  getAllCategories,
  addNewCategory,
  deleteCategoryById,
  reorderCategories,
  renameCategoryById,
} from "../../redux/categories/categoriesActions";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import Popover from "@material-ui/core/Popover";
import "./styles.scss";

export const AddNewCategory = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.commonReducer.isFetching);
  const categories = useSelector((state) => state.categoriesReducer.categories);
  const rootCategories = categories.filter((c) => !Boolean(c.parent));
  const getSubCategories = (id) =>
    categories.filter((c) => c.parent && c.parent === id);

  const [openSubCategory, isOpenSubCategory] = useState(false);

  // const [rootCategoriesTemp, setRootCategoriesTemp] = useState([]);

  useEffect(() => {
    renameCategoryById();
  });

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  // useEffect(() => {
  //   setRootCategoriesTemp(rootCategories);
  // }, [categories]);

  //// New category form Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const clickPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };
  const isPopoverOpened = Boolean(anchorEl);

  const onCancelAddCategory = () => {
    isOpenSubCategory(false);
  };

  //Reorder , DND
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, i) => {
      return {
        id: item._id,
        order: i,
      };
    });
  };

  function handleOnDragEnd(result) {
    console.log("resultonEnd", result);
    const { source, destination } = result;
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const res = reorder(rootCategories, source.index, destination.index);
    dispatch(reorderCategories(res));
  }

  return (
    <div className="new-cat">
      <div className="new-cat__header">
        <h2>Add new category</h2>
      </div>
      <div className="new-cat__subheader">
        <h3>All categories</h3>
        <div className="add__block" onClick={clickPopover}>
          <div className="add__btn">
            <AddIcon />
          </div>
          <p>Add category</p>
        </div>
        <Popover
          open={isPopoverOpened}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="new-cat__item">
            <NewCategoryForm
              label="Category name"
              text="Add category"
              fullWidth
              categoryEvent={(c) => {
                dispatch(addNewCategory(c));
                closePopover();
              }}
            />
          </div>
        </Popover>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="categoriesdnd">
          {(provided) => (
            <div
              className="new-cat__body"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {isFetching ? (
                <CircularProgress color="secondary" />
              ) : (
                <div className="categoriesdnd">
                  {rootCategories.length &&
                    rootCategories
                      .sort((a, b) => a.order - b.order)
                      .map((category, i) => {
                        return (
                          <Draggable
                            key={category._id}
                            draggableId={
                              "draggable-" + category._id + category.order
                            }
                            index={i}
                          >
                            {(provided) => (
                              <Accordion
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p>{category.name}</p>
                                    <div className="button-edit">
                                      <ButtonRename
                                        dispatch={dispatch}
                                        category={category && category}
                                      />
                                      <ButtonDelete
                                        deleteEvent={() =>
                                          dispatch(
                                            deleteCategoryById(category._id)
                                          )
                                        }
                                        hasConfirmation
                                      />
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="category-wrap">
                                    <p>Sub-Categories for {category.name}:</p>
                                    {getSubCategories(category._id).map(
                                      (subcategory) => {
                                        return (
                                          <div
                                            key={subcategory._id}
                                            className="category-wrap__category"
                                          >
                                            <div className="category-wrap__category__column">
                                              {subcategory.name}
                                            </div>
                                            <div className="category-wrap__category__column">
                                              <ButtonDelete
                                                deleteEvent={() =>
                                                  dispatch(
                                                    deleteCategoryById(
                                                      subcategory._id
                                                    )
                                                  )
                                                }
                                                hasConfirmation
                                              />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div>
                                      {!openSubCategory && (
                                        <div
                                          className="add__block"
                                          onClick={() =>
                                            isOpenSubCategory(true)
                                          }
                                        >
                                          <div className="add__btn">
                                            <AddIcon />
                                          </div>
                                          <p>Add subcategory</p>
                                        </div>
                                      )}
                                      {openSubCategory && (
                                        <div>
                                          <NewCategoryForm
                                            label="Subcategory name"
                                            text="Add subcategory"
                                            categoryEvent={(c, p) =>
                                              dispatch(addNewCategory(c, p))
                                            }
                                            parent={category._id}
                                            onCancel={onCancelAddCategory}
                                            isCancelButton
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Draggable>
                        );
                      })}
                  {provided.placeholder}
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
