import React from "react";
import "./styles.scss";

export const InfoBlock = ({ title, description }) => {
  return (
    <div className="info-block">
      <p>{title}</p>
      <p>{description}</p>
    </div>
  );
};
