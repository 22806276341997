import {
  ADD_NEW_CATEGORY,
  DELETE_CATEGORY_BY_ID,
  GET_ALL_CATEGORIES,
  GET_REORDERED_CATEGORIES,
  RENAME_CATEGORY_BY_ID,
} from "./categoriesTypes";

const initialState = {
  categories: [],
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return { ...state, categories: action.data };

    case GET_REORDERED_CATEGORIES:
      return {
        ...state,
        categories: [
          ...state.categories.map(
            (e) => action.data.find((j) => j._id === e._id) || e
          ),
        ],
      };

    case ADD_NEW_CATEGORY:
      return { ...state, categories: [...state.categories, action.data] };

    case RENAME_CATEGORY_BY_ID:
      return {
        ...state,
        categories: [
          ...state.categories.filter((c) => c._id !== action.id),
          {
            ...state.categories.find((c) => c._id === action.id),
            name: action.name,
          },
        ],
      };

    case DELETE_CATEGORY_BY_ID:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== action.id
        ),
      };
    default:
      return state;
  }
};

export default categoriesReducer;
