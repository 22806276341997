import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";
import { Edit } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export const ProductsTabs = ({ products }) => {
  console.log(products);
  const history = useHistory();

  const checkType = (type) => {
    switch (type) {
      case "cd":
        return "edit-rejected-cd";
      case "vinyl":
        return "edit-rejected-vinyl";
      case "cassette":
        return "edit-rejected-cassette";
      case "apparel":
        return "edit-rejected-apparel";
      case "accessories":
        return "edit-rejected-accessories";
    }
  };

  return (
    <div className="tabs">
      <div className="tabs__row-container">
        <div className="tabs__content content-header">
          <div>ID</div>
          <div>NAME</div>
          <div>Category</div>
          <div>Price</div>
        </div>
        {products.map((row, index) => {
          return (
            <div key={row._id}>
              <div className="tabs__content">
                <div className="tab">{index + 1}</div>
                <div className="tab">{row.name}</div>
                <div className="tab">
                  {row.product_type
                    ? row.product_type.toUpperCase()
                    : row.template.category.name}
                </div>

                <div className="tab">
                  <div className={"tab__price"}>
                    {row.price}
                    {row.status === 2 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          // console.log("row", row);
                          history.push(
                            `/${checkType(
                              row.product_type
                                ? row.product_type
                                : row.template.template_group
                            )}/${row._id}`
                          );
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
