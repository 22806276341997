import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminList } from "../../redux/users/usersActions";
import { Link, useHistory } from "react-router-dom";
import { ManageTitle } from "../../components/ManageTitle";
import "./styles.scss";
import { ButtonCreate } from "../../components/ButtonCreate";

export const ManageAdminUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const admins = useSelector((state) => state.usersReducer.admins);

  useEffect(() => {
    dispatch(getAdminList());
  }, [dispatch]);

  return typeof admins === "object" ? (
    <div className="manage">
      <ManageTitle title="Manage admin users">
        <ButtonCreate
          text="Add new Admin"
          handleClick={() => {
            history.push("/sa-add-new-admin-id");
          }}
        />
      </ManageTitle>
      <div className="admins admins__header">
        <div className="admins__id">ID</div>
        <div className="admins__name">NAME</div>
        <div className="admins__email">E-mail</div>
      </div>
      {admins?.map((user, index) => (
        <Link key={user._id} to={`/sa-manage-current-admin-id/${user._id}`}>
          <div className="admins admins__users" key={index + "admins"}>
            <div className="admins__id">{index + 1}</div>
            <div className="admins__name">{user.name}</div>
            <div className="admins__email">{user.email}</div>
          </div>
        </Link>
      ))}
    </div>
  ) : (
    <div></div>
  );
};
