import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  DialogContentText,
  Input,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveProducts,
  getInactiveProducts,
} from "../../redux/products/productsActions";
import { webkulIntegration } from "../../redux/auth/authActions";
import { useHistory } from "react-router-dom";
// import { TabPanel } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./styles.scss";
import { Alert } from "@material-ui/lab";
import {
  setApproved,
  setIsEditError,
  setIsEditSuccess,
  setRejected,
} from "../../redux/products/productsMutations";
import { isCreatedAdmin } from "../../redux/users/usersMutations";

const requiredFields = ["apiKey", "secretKey", "userId"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={5}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const ModerateNewProduct = () => {
  const dispatch = useDispatch();
  const inactiveProducts = useSelector(
    (state) => state.productsReducer.inactiveProducts
  );
  const activeProducts = useSelector(
    (state) => state.productsReducer.activeProducts
  );
  const isApproved = useSelector((state) => state.productsReducer.isApproved);
  const isRejected = useSelector((state) => state.productsReducer.isRejected);
  const isSuccess = useSelector((state) => state.authReducer.isCreatedAdmin);

  console.log("activeProducts", activeProducts);
  const [values, setValues] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });

  const [errors, setErrors] = useState({
    apiKey: "",
    secretKey: "",
    userId: "",
  });

  const [userName, setUserName] = useState("");
  const [openWebkulWarning, setOpenWebkulWarning] = useState(false);
  const [id, setId] = useState("");
  const history = useHistory();

  useEffect(() => {
    dispatch(getInactiveProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getActiveProducts());
  }, [dispatch]);
  useEffect(() => {
    return () => {
      dispatch(isCreatedAdmin(false));
      dispatch(setApproved(false));
      dispatch(setRejected(false));
      localStorage.removeItem("sa-product-tab");
    };
  }, []);
  // const onCloseWebkulWarning = useCallback(() => {
  //   setOpenWebkulWarning(false);
  // }, []);

  // const onChangeValue = (fieldName) => (e) => {
  //   const value = e.currentTarget.value;
  //   setValues((prevState) => ({ ...prevState, [fieldName]: value }));
  //   setErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
  // };

  // const onSubmit = () => {
  //   const checkedValues = Object.values(values).some((value) => !value);
  //   if (!checkedValues) {
  //     dispatch(
  //       webkulIntegration({ ...values, history, fromProduct: true, id })
  //     );
  //   } else {
  //     const fields = Object.keys(values);
  //     fields.forEach((field) => {
  //       if (!values[field]) {
  //         setErrors((prevState) => ({
  //           ...prevState,
  //           [field]: "This field is required",
  //         }));
  //       }
  //     });
  //   }
  // };
  const validateFields = () => {
    let approved = [];
    requiredFields.forEach((field) => {
      if (values[field] === "") {
        setErrors((prevState) => ({
          ...prevState,
          [field]: "Field is required",
        }));
        approved.push(true);
        return;
      }
      if (field === "apiKey" || field === "secretKey") {
        if (values[field].match(/[^a-zA-Z0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only a-z A-Z 0-9",
          }));
          approved.push(true);
          return;
        }
        // if (values[field].length !== 86) {
        //   setErrors((prevState) => ({
        //     ...prevState,
        //     [field]: "Token length must be 86",
        //   }));
        //   approved.push(true);
        // }
      }
      if (field === "userId") {
        if (values[field].match(/[^0-9 ]/)) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Use only 0-9",
          }));
          approved.push(true);
          return;
        }
        if (values[field].toString().length !== 6) {
          setErrors((prevState) => ({
            ...prevState,
            [field]: "Length must be 6 digits",
          }));
          approved.push(true);
          return;
        }
      }
      approved.push(false);
    });
    console.log("approved", approved);
    return approved;
  };
  const onSubmit = () => {
    const approved = validateFields();
    const checkedValues = approved.find((f) => f === true);
    // approved.every((element) => element);
    console.log("errors", errors);
    console.log("checkedValues", checkedValues);

    if (!checkedValues) {
      dispatch(
        webkulIntegration({
          ...values,
          history,
          fromProduct: false,
          id,
        })
      );
      setOpenWebkulWarning(false);
      setValues({
        apiKey: "",
        secretKey: "",
        userId: "",
      });
    }

    setTimeout(
      () =>
        setErrors({
          apiKey: "",
          secretKey: "",
          userId: "",
        }),
      5000
    );
  };
  const onChangeValue = (fieldName) => (e) => {
    const value = e.currentTarget.value;
    setValues((prevState) => ({ ...prevState, [fieldName]: value }));
  };
  const onCloseWebkulWarning = () => {
    setOpenWebkulWarning(false);
  };
  const onClickByProduct = (inactiveProduct) => {
    if (!inactiveProduct.user_id?.webkul_access_token) {
      setOpenWebkulWarning(true);
      setId(inactiveProduct.user_id?._id || "noID");
      setUserName(inactiveProduct.user_id?.name || "No Name");
    } else history.push(`/sa-moderate-product-page/${inactiveProduct._id}`);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(isCreatedAdmin(false));
      dispatch(setApproved(false));
      dispatch(setRejected(false));
      return;
    }
    dispatch(isCreatedAdmin(false));
    dispatch(setApproved(false));
    dispatch(setRejected(false));
  };
  //TABS
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    const tab = localStorage.getItem("sa-product-tab");

    if (tab) setActiveTab(Number(tab));
  }, [setActiveTab]);

  console.log("inactiveProducts", inactiveProducts);
  return (
    <div className="moderate">
      <div>
        {isSuccess && (
          <Snackbar
            open={isSuccess}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert severity="success">Integrated with webcul was success</Alert>
          </Snackbar>
        )}
        {isRejected && (
          <Snackbar
            open={isRejected}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">The product was rejected</Alert>
          </Snackbar>
        )}
        {isApproved && (
          <Snackbar
            open={isApproved}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">
              Your product was successfully sent approved
            </Alert>
          </Snackbar>
        )}
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Moderate new products" {...a11yProps(0)} />
          <Tab label="Active products" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <div className="moderate-header">
            <div className="moderate-header-data">
              <div className="moderate-header-data__id">ID</div>
              <div className="moderate-header-data__vendor">Vendor</div>
              <div className="moderate-header-data__image">Image</div>
              <div className="moderate-header-data__name">Name</div>
              <div className="moderate-header-data__category">Category</div>
              <div className="moderate-header-data__price">Price</div>
            </div>
          </div>
          {inactiveProducts.map((inactiveProduct, index) => {
            return (
              <div
                style={{ marginBottom: 8 }}
                key={inactiveProduct._id}
                onClick={() => onClickByProduct(inactiveProduct)}
              >
                <div className="moderate-row-data">
                  <div className="moderate-row-data__id">{index + 1}</div>
                  <div className="moderate-row-data__vendor">
                    {inactiveProduct.user_id?.name || "No Name"}
                  </div>
                  <div className="moderate-row-data__image">
                    <img
                      src={inactiveProduct.images[0]?.path}
                      alt={inactiveProduct.name}
                    />
                  </div>
                  <div className="moderate-row-data__name">
                    {inactiveProduct.name}
                  </div>
                  <div className="moderate-row-data__category">
                    {inactiveProduct?.product_type
                      ? inactiveProduct.product_type.toUpperCase()
                      : inactiveProduct.template?.category?.name}
                  </div>
                  <div className="moderate-row-data__price">
                    ${inactiveProduct.price}
                  </div>
                </div>
              </div>
            );
          })}
          <Dialog
            open={openWebkulWarning}
            onClose={onCloseWebkulWarning}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Please integrate the user ${userName} with webkul`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className={"webkul-page_container"}>
                  <div className={"webkul-page_fields"}>
                    <TextField
                      required
                      type="text"
                      // id="outlined-basic"
                      // variant="outlined"
                      name={"apiKey"}
                      value={values?.apiKey}
                      onChange={onChangeValue("apiKey")}
                      error={errors?.apiKey}
                      helperText={errors?.apiKey}
                      placeholder={"Access Token"}
                      autocomplete="off"
                    />
                    <TextField
                      required
                      type="text"
                      // id="outlined-basic"
                      // variant="outlined"
                      name={"secretKey"}
                      value={values.secretKey}
                      onChange={onChangeValue("secretKey")}
                      error={errors?.secretKey}
                      helperText={errors?.secretKey}
                      placeholder={"Refresh Token"}
                      autocomplete="off"
                    />
                    <TextField
                      required
                      type="text"
                      // id="outlined-basic"
                      // variant="outlined"
                      name={"userId"}
                      value={values.userId}
                      onChange={onChangeValue("userId")}
                      error={errors?.userId}
                      helperText={errors?.userId}
                      placeholder={"User id"}
                      autocomplete="off"
                    />
                  </div>
                  <div className="webkul-page_actions">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          {/*<Dialog*/}
          {/*  open={openWebkulWarning}*/}
          {/*  onClose={onCloseWebkulWarning}*/}
          {/*  aria-labelledby="alert-dialog-title"*/}
          {/*  aria-describedby="alert-dialog-description"*/}
          {/*>*/}
          {/*  <DialogTitle id="alert-dialog-title">{`Please integrate the user ${userName} with webkul`}</DialogTitle>*/}
          {/*  <DialogContent>*/}
          {/*    <DialogContentText id="alert-dialog-description">*/}
          {/*      <div className={"webkul-page_container"}>*/}
          {/*        <div className={"webkul-page_fields"}>*/}
          {/*          <Input*/}
          {/*            required*/}
          {/*            type="text"*/}
          {/*            id="outlined-basic"*/}
          {/*            variant="outlined"*/}
          {/*            name={"apiKey"}*/}
          {/*            value={values?.apiKey}*/}
          {/*            onChange={onChangeValue("apiKey")}*/}
          {/*            error={errors?.apiKey}*/}
          {/*            helperText={errors?.apiKey && "Field is required"}*/}
          {/*            placeholder={"Access Token"}*/}
          {/*            autocomplete="off"*/}
          {/*          />*/}
          {/*          <Input*/}
          {/*            required*/}
          {/*            type="password"*/}
          {/*            id="outlined-basic"*/}
          {/*            variant="outlined"*/}
          {/*            name={"secretKey"}*/}
          {/*            value={values.secretKey}*/}
          {/*            onChange={onChangeValue("secretKey")}*/}
          {/*            error={errors?.secretKey}*/}
          {/*            helperText={errors?.secretKey && "Field is required"}*/}
          {/*            placeholder={"Refresh Token"}*/}
          {/*            autocomplete="off"*/}
          {/*          />*/}
          {/*          <Input*/}
          {/*            required*/}
          {/*            type="text"*/}
          {/*            id="outlined-basic"*/}
          {/*            variant="outlined"*/}
          {/*            name={"userId"}*/}
          {/*            value={values.userId}*/}
          {/*            onChange={onChangeValue("userId")}*/}
          {/*            error={errors?.userId}*/}
          {/*            helperText={errors?.userId && "Field is required"}*/}
          {/*            placeholder={"User id"}*/}
          {/*            autocomplete="off"*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*        <div className="webkul-page_actions">*/}
          {/*          <Button*/}
          {/*            variant="contained"*/}
          {/*            color="primary"*/}
          {/*            onClick={onSubmit}*/}
          {/*          >*/}
          {/*            Submit*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </DialogContentText>*/}
          {/*  </DialogContent>*/}
          {/*</Dialog>*/}
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <div className="moderate-header">
            <div className="moderate-header-data">
              <div className="moderate-header-data__id">ID</div>
              <div className="moderate-header-data__vendor">Vendor</div>
              <div className="moderate-header-data__image">Image</div>
              <div className="moderate-header-data__name">Name</div>
              <div className="moderate-header-data__category">Category</div>
              <div className="moderate-header-data__price">Price</div>
            </div>
          </div>
          {activeProducts.map((activeProduct, index) => {
            return (
              <div
                style={{ marginBottom: 8 }}
                key={activeProduct._id}
                onClick={() => onClickByProduct(activeProduct)}
              >
                <div className="moderate-row-data">
                  <div className="moderate-row-data__id">{index + 1}</div>
                  <div className="moderate-row-data__vendor">
                    {activeProduct.user_id?.name}
                  </div>
                  <div className="moderate-row-data__image">
                    <img
                      src={activeProduct.images[0]?.path}
                      alt={activeProduct.name}
                    />
                  </div>
                  <div className="moderate-row-data__name">
                    {activeProduct.name}
                  </div>
                  <div className="moderate-row-data__category">
                    {activeProduct?.product_type === "vinyl"
                      ? "Vinyl"
                      : activeProduct.template?.category?.name}
                  </div>
                  <div className="moderate-row-data__price">
                    ${activeProduct.price}
                  </div>
                </div>
              </div>
            );
          })}
        </TabPanel>
      </div>

      {/*<h2>Moderate New Products</h2>*/}
    </div>
  );
};
