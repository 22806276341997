import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

export const ButtonCreate = ({
  isDisabled,
  className,
  text,
  size,
  fullWidth,
  handleClick,
  submit,
  width,
  height,
  zIndex,
}) => {
  return (
    <Button
      disabled={isDisabled}
      className={className}
      variant="contained"
      size={size}
      type={submit ? "submit" : "button"}
      fullWidth={fullWidth}
      style={{ width, height, zIndex }}
      onClick={() => handleClick && handleClick()}
    >
      {text}
    </Button>
  );
};
