import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const navigationLinks = [
  {
    name: "Women's Clothing",
    to: "/wc",
    icon: NavigateNextIcon,
  },
  {
    name: "Men's Clothing",
    to: "/mc",
    icon: NavigateNextIcon,
  },
  {
    name: "Accessories",
    to: "/acc",
    icon: NavigateNextIcon,
  },
  {
    name: "Home & Living",
    to: "/",
    icon: NavigateNextIcon,
  },
  {
    name: "Music",
    to: "/m",
    icon: NavigateNextIcon,
  },
];

export const navigationSubLinks = [
  {
    name: "All Shirts",
    to: "/t-shirts",
  },
  {
    name: "T-shirts",
    to: "/t-shirts",
  },
  {
    name: "Crop-Tops",
    to: "/t-shirts",
  },
  {
    name: "Sleeveless-shirts",
    to: "/t-shirts",
  },
  {
    name: "All Hoodies and sweatshirts",
    to: "/t-shirts",
  },
  {
    name: "Hoodies",
    to: "/t-shirts",
  },
  {
    name: "Sweatshirts",
    to: "/t-shirts",
  },
  {
    name: "Bottom's",
    to: "/t-shirts",
  },
  {
    name: "Trousers",
    to: "/all",
  },
];
