export const buildImgProperty = (
  imgUrl,
  mainImage,
  imageWidth,
  imageHeight,
  idCount,
  setIdCount
) => {
  const props = {
    side: mainImage?.side,
    x: 10,
    y: 10,
    width: imageWidth,
    height: imageHeight,
    id: `rect${idCount}`,
    imgUrl,
    parentId: mainImage?._id || mainImage?.id,
  };
  setIdCount((prevCountValue) => prevCountValue + 1);
  return props;
};
