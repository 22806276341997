import axios from "../../API/axios/index";
import {
  createNewSellerMutation,
  loginError,
  loginMutation,
  resetPasswordMutation,
  setSuccessRegistration,
} from "./authMutations";
import { CREATE_NEW_SELLER_ERROR } from "./authTypes";
import { getUsersInfo } from "../users/usersActions";
import { isCreatedAdmin, isRejectedAdmin } from "../users/usersMutations";
import {
  changeProductStatus,
  getActiveProducts,
  getInactiveProducts,
} from "../products/productsActions";

// const server = process.env.REACT_APP_SERVER_URL || "localhost"; ${server}

export function createNewSeller(values, history) {
  return (dispatch) => {
    axios
      .post(`/add-vendor`, {
        name: values.name,
        email: values.email,
        password: values.password,
      })

      .then((res) => {
        dispatch(setSuccessRegistration(true));
        dispatch(createNewSellerMutation());
      })

      .catch(({ response }) => {
        dispatch({
          type: CREATE_NEW_SELLER_ERROR,
          message: response?.data?.message,
        });
      });
  };
}

export const errorActionCreator = (errorType, error) => {
  return {
    type: errorType,
    error: true,
    payload: error,
  };
};

export function login(values, history) {
  return (dispatch) => {
    axios
      .post(`/login`, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        if (values.remember) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.user.role);
          localStorage.setItem("email", res.data.user.email);
        } else {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("role", res.data.user.role);
          sessionStorage.setItem("email", res.data.user.email);
        }

        dispatch(loginMutation(res.data));
        dispatch(loginError(""));
        const route =
          res.data.user.role === "admin" ? "/sa-moderate-new-product" : "/";
        history.push(route);
      })
      .catch((e) => {
        dispatch(loginError("Login or Password is incorrect"));
        console.log("Login error:", e);
      });
  };
}

export function resetPassword(values) {
  return (dispatch) => {
    axios
      .post(`/reset`, {
        email: values.email,
      })
      .then((res) => {
        dispatch(resetPasswordMutation({ success: true }));
      })
      .catch((e) => {
        dispatch(resetPasswordMutation({ success: false }));
        console.log("Login error:", e);
      });
  };
}

export function webkulIntegration(payload) {
  console.log("payload/", payload);
  return (dispatch) => {
    axios
      .put(`/profile/${payload.id}`, {
        webkul_access_token: payload.apiKey,
        webkul_refresh_token: payload.secretKey,
        webkul_seller_id: payload.userId,
      })
      .then((response) => {
        dispatch(getUsersInfo());
        dispatch(getInactiveProducts());
        dispatch(getActiveProducts());
        dispatch(isCreatedAdmin(true));
        if (payload.fromApprove) {
          console.log("payload.approvePayload", payload.approvePayload);
          dispatch(
            changeProductStatus(
              payload.approvePayload.productId,
              payload.approvePayload.status,
              payload.approvePayload.type,
              payload.history
            )
          );
        }
      })
      .catch((error) => {
        dispatch(isRejectedAdmin(true));
      });
  };
}
