import React from "react";
import { Logo } from "../Logo";
import { HamburgerButton } from "./components/HamburgerButton";
import { ButtonCreate } from "../ButtonCreate";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import "./styles.scss";
import { logoutMutation } from "../../redux/auth/authMutations";
import { Tooltip } from "@material-ui/core";

export const Header = ({
  isOpen,
  handleClick,
  withNavbarCategories,
  withNavbarSubCategories,
  withContent,
  vendorEmail,
}) => {
  const history = useHistory();
  const IsDashboardButtonHidden = history.location.pathname === "/";
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("email");
    history.push("/login");
    logoutMutation();
  };
  return (
    <div className="header">
      <div className="header__content content">
        <div className="content__left">
          <Logo />
        </div>
        <div className="header-divider">
          {withContent && (
            <>
              {/*<div style={{ paddingRight: "10px" }}>*/}
              <Tooltip
                title={
                  <div className="header_tooltip">
                    <p>{vendorEmail}</p>
                    <p
                      onClick={() => handleLogout()}
                      style={{ cursor: "pointer" }}
                    >
                      Log out
                    </p>
                  </div>
                }
                interactive
              >
                <div>
                  <Avatar alt="V" />
                </div>
              </Tooltip>
              {/*</div>*/}

              <div className="content__right">
                {!IsDashboardButtonHidden && (
                  <ButtonCreate
                    text={"Dashboard"}
                    handleClick={() => history.push("/")}
                  />
                )}
              </div>
            </>
          )}
          {(withNavbarCategories || withNavbarSubCategories) && (
            <HamburgerButton
              handleClick={handleClick}
              className="header__menu-btn-wrapper"
              isOpen={isOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};
