import React from "react";
import { defaultSettings } from "./data";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.scss";

export const ModerationSlider = ({ completeImages }) => {
  console.log("comp images", completeImages);
  return (
    <Slider {...defaultSettings}>
      {completeImages.map((img, index) => {
        return (
          <div key={index} className="images__main-image">
            <img src={img?.path || img} alt="imageForSlider" />
          </div>
        );
      })}
    </Slider>
  );
};
