import React from "react";
import { ReactSVG } from "react-svg";
import arrowNext from "../sliderArrows/ArrowNext.svg";
import arrowPrev from "../sliderArrows/ArrowPrev.svg";

const SlickButtonFixNext = ({
  currentSlide,
  slideCount,
  children,
  ...props
}) => (
  <span {...props} style={{ top: "50%" }}>
    <ReactSVG src={arrowNext} />
  </span>
);
const SlickButtonFixPrev = ({
  currentSlide,
  slideCount,
  children,
  ...props
}) => (
  <span {...props} style={{ top: "50%" }}>
    <ReactSVG src={arrowPrev} />
  </span>
);

export const defaultSettings = {
  infinite: true,
  fade: true,
  currentSlide: 0,
  edgeFriction: 0.15,
  centerPadding: "0",
  arrows: true,
  centerMode: true,
  nextArrow: <SlickButtonFixNext />,
  prevArrow: <SlickButtonFixPrev />,
};
